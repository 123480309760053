import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, Redirect, DatePicker,
    Accordion
} 
from '../../noser-hris-component';
import TrainingClient from '../Training/TrainingModal/TrainingClient';
import TrainingPosition from '../Training/TrainingModal/TrainingPosition';
import {CSVLink, CSVDownload} from 'react-csv';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit'
import { GetRequest, PostRequest } from '../../noser.dataaccess'
let ref = React.createRef();

class BatchClearance extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fade            :   true, 
            color           :   "",
            isshow          :   false,
            message         :   "",
            userinfo        :   [],
            isloading       :   false,
            alerttype       :   "",
            disablebtn      :   true,
            openClientModal :   false,
            openPositionModal:  false,
            disabled        :   true,
            clientId        :   "",
            trainingId      :   "",
            trainingDDL     :   [],
            trainingGrid    :   [],
            clientDDL       :   [],
            employeeDDL     :   [],
            requiredDDL     :   [{"value":"1","label":"MANDATORY"},{"value":"0","label":"OPTIONAL"}],
            gridDataExcel   :   [],
            isshowExpand    :   false,
            reasonDDL: [],
            reasonId: "",
            reason: "",
            reason: "",
            resignationDate: "",
            clearanceDate: "",
            submissionDate: "",
            workingDate: "",
            memberNotes:"",
            memberDDL:[],
            isCheckSSS:false,
            isCheckTIN:false,
            isCheckPHIC:false,
            isCheckHDMF:false,
            isCheckCashCard:false,
            isCheckValidId:false,
            isCheckResignation:false,
            isCheckTurnover:false,
            isCheckRequestForm:false,
            isRadioCashcard:false,
            radioCashCardDDL:[
                {'id' : '', 'name' : 'Select Type of Cash Card'},
                {'id' : '1', 'name' : 'BDO'},
                {'id' : '2', 'name' : 'UNION BANK'},
                {'id' : '3', 'name' : 'METROBANK'}
            ],
            cashCardType:"",
            accountNumber:"",
            isRadioCheck:false,
            isRadioMlhuillier:false,
            isRadioPalawan:false,
            cashCardField:true,
            memberRemarks:"",
            dateCreated: new Date(),
            dateSubmitted: "",
            clearanceStatus:"",
            // END MEMBER FIELDS

            clientRemarks:"",
            clientTabDDL:[],
            lrdRemarks:"",
            lrdTabDDL:[],
            rmtRemarks:"",
            rmtDDL:[],
            serviceTabDDL:[],
            servicesRemarks:"",
            attachedDDL:[],

            disabledButtonMember:true,
            disabledCleintButton:true,
            disabledLrdButton:true,
            disabledRmtButton:true,
            disabledServicesButton:true,
            dateRecieved : "",
            dateForwarded : "",
            notEligible : false,
            eligible : false,
            isClearanceStatus : "",
            files : [],
            daysCount : "0",
            cycleDays : "",

            //from edit details
            employeeName:"",
            fromDateCreated : "",
            toDateCreated : "",
            fromClearanceDate:"",
            toClearanceDate:"",
            fromDateReceived:"",
            toDateReceived:"",
            fromLastWorkingDate:"",
            toLastWorkingDate:"",
            statusId : "",
            statusDDL : [],
            toLastWorkingDate : "",
            fromLastWorkingDate : "",
            toDateReceived : "",
            fromDateReceived :"", 
            toClearanceDate : "",
            fromClearanceDate : "",
            toDateCreated : "",
            fromDateCreated : "",
            datenow : new Date(),
            batchNumber : "",
            filteredBatch : [],
            selectedId : [],
            _tmpData : [],
            dataListArr:[],
            collectionArray:[],

            chargesArray : [],
            totalBalanceArr : [],
        }
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
    }

    componentDidMount(){
        let sleep = (milliseconds) => {return new Promise(resolve => setTimeout(resolve, milliseconds))}
        this.GetClient()
        sessionStorage.setItem("autofill", JSON.stringify("")) 
        this.getStatusClearance()
    }
    GetTrainingFilters = async() => {
        this.setState({isloading:true})
        let param = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.userinfo.clientId,
            "UserId"        :   this.state.userinfo.userId,
        };
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetTrainingFilters",  param)
        .then(res => {
            let data = res.data;
            
            this.setState({trainingDDL:data.trainings,isloading:false, trainingGrid : data});
        })
        .catch(error=>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
    };
    GetClient = async() =>{
        this.setState({isloading:true})
        let params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
            "EmployeeId":this.state.userinfo.roleId==="1" || 
                        this.state.userinfo.roleId==="8" ? "" : this.state.userinfo.employeeId
        }
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Client/GetClientList", params)
        //.post(AppConfiguration.Setting().noserapiendpoint + "Client/GetClientPerAccess", params)
        .then(res => {
            let data = res.data;
            let arr = []
            if(this.state.userinfo.roleId!=="1"){
                let fieldaccess = JSON.parse(sessionStorage.getItem("userControlAccess"))
                if(fieldaccess.filter(x=>x.accessCode==="0001").length>0){
                    let access = fieldaccess.find(x=>x.accessCode==="0001" && x.controlAccessDetails.length>0)
                    if(access.controlAccessDetails.filter(x=>x.isClient==="1").length>0){
                        let brr = access.controlAccessDetails.filter(x=>x.isClient==="1")
                        arr = data.clients.filter(f => brr.some(item => item.referenceId === f.id))
                    }
                }
                this.setState({clientDDL : arr,})
            }
            else{
                this.setState({clientDDL : data.clients,})
            }
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
        this.GetDisengagementMode();
        this.GetDataGrid()
    };
    GetEmployees = () => {
    this.setState({isloading:true})
        let getParams = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.clientId,
            "UserId"        :   this.state.userinfo.userId,
            "EmployeeName"  :   "",
            "EmployeeNo"    :   "",
            "ClientName"    :   ""
        }
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Employee/GetEmployees",  getParams)
        .then(res => {
            let data = res.data
            this.setState({
                employeeDDL        :   data.employees,
                isloading           :   false,
            }) 
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        }) 
    
    };
    handleTrainingChanged = (e) => {
        if(e.length === 0) {
            this.state.trainingId=""
            return
        }
        this.state.trainingId=e[0].id
        this.setState({isshow:false})
    }
    handleClientChanged = (e) => {
        if(e.length === 0) {
            this.state.clientId=""
            return
        }
        this.state.clientId=e[0].id
        this.setState({isshow:false})

    }
    GetTrainings = async()=> {
        this.setState({isloading:true})

        let param = {
            "IpAddress":"0.0.0.0",
            "UserId":this.state.userinfo.userId,
            "ClientId":this.state.clientId,
            "TrainingId":this.state.trainingId,
        };
        await axios
        .post(
            AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetTrainings",  param
        )
            .then(res => {
                let data = res.data;
                this.setState({ trainingGrid:data.trainings, isloading : false })
                if(data.status=="0"){
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   data.message,
                    fade        :   true
                });
            
            }
                
        })
        .catch(error=>{
            this.setState({
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
        
    }
    handleClickSearch = async() => {
        let arrLst = []
        let arrid = []
        if (this.state.batchNumber !== ""){
            //let trainngBatch = this.state.dataListArr.filter( x => x.batchNumber === this.state.batchNumber)
            this.state.dataListArr.forEach(itm =>{
                this.GetChargesAmount(itm, itm.employeeNo, itm.employeeName)
            })
        }else {
            this.setState({isloading:true})
            let param = {
                "IpAddress"             :   "0.0.0.0",
                "UserId"                :   this.state.userinfo.userId,
                "ClientId"              :   this.state.clientId,
                "ProfileId"             :   this.state.profileId,
                "ClearanceStatusId"     :   this.state.statusId,
                "FromDateCreated"       :   this.state.fromDateCreated,
                "ToDateCreated"         :   this.state.toDateCreated,
                "FromClearanceDate"     :   this.state.fromClearanceDate,
                "ToClearanceDate"       :   this.state.toClearanceDate,
                "FromDateReceive"       :   this.state.fromDateReceived,
                "ToDateReceive"         :   this.state.toDateReceived,
                "FromLastWorkingDate"   :   this.state.fromLastWorkingDate,
                "ToLastWorkingDate"     :   this.state.toLastWorkingDate,
                "BatchNumber"           :   this.state.batchNumber === "" ? "" : this.state.batchNumber,
                "SubmittedDateFromPayroll" : "",
                "SubmittedDateToPayroll" : "",
                "ReviewStatusId" : "",
                // "ReasonForLeaving"      :   this.state.reason === "" ? "" : this.state.reason,
            };
            await axios
            .post(AppConfiguration.Setting().noserapiendpoint + "Clearance/GetClearance",  param)
            .then(res => {
                let data = res.data.clearance;
                let _trainingFIlter = data.filter( x => x.isClearanceStatus === "0" 
                                                     || x.isClearanceStatus === "1" 
                                                     || x.isClearanceStatus === "2"
                                                     || x.isClearanceStatus === "5"
                                                     || x.isClearanceStatus === "6")
                if(this.state.reason === ""){
                    this.setState({
                        trainingGrid : _trainingFIlter,
                        isloading : false
                    })
                }else {
                    this.setState({
                        trainingGrid : _trainingFIlter.filter(x => x.reasonForLeaving === this.state.reason),
                        isloading : false
                    })
                }
                if(data.status==="0"){
                    this.setState(  {
                        isloading       :   false,
                        alerttype       :   "Error!",
                        isshow          :   true,
                        color           :   "danger",
                        message         :   data.message,
                        fade            :   true
                    })
                }
            })
            .catch(error=>{
                this.setState(  {
                    isloading       :   false,
                    alerttype       :   "Error!",
                    isshow          :   true,
                    color           :   "danger",
                    message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                    fade            :   true
                })
            })
        }
    }
    GetChargesAmount = async(dataList, employeeNo, employeeName) => {
        this.setState({isloading:true})
        let arr = []
        let finalArrays = []
        let arrid = []
        let resp = await GetRequest({
            url : AppConfiguration.Setting().noserapi + "action/get",
            params : {
                "dbname"      :  AppConfiguration.Setting().noseraredb,
                "_collection" : "SubsidiaryLedgerStaging",
                "filter_data" : {"isDeleted" : '0', "employeeNo" : employeeNo}
            }    
        })
        if(resp.status==="1"){
            var nf = new Intl.NumberFormat();
            let arrList = []
            let data = resp.ResultList.filter(x => x.debit !== "0.00")

            // start nrf
            let nrfCharges = resp.ResultList.filter(x => x.deductionId === "1" && x.debit !== "0.00")
            let nrftotalcharges = nrfCharges.reduce((total, currentValue) => total = total + parseFloat(currentValue.debit),0)
            let nrfcollection = resp.ResultList.filter(x => x.deductionId === "1" && x.credit !== "0.00")
            let totalnrfcollection = nrfcollection.reduce((total, currentValue) => total = total + parseFloat(currentValue.credit),0)
            
            let nrfArr = nrfCharges.filter((thing, index, self) =>
                index === self.findIndex((t) => (
                    t.deductionId === thing.deductionId
                ))
            )
            //var nrfTotalBalance = parseFloat(nrftotalcharges).toFixed(2) - parseFloat(nrfcollection).toFixed(2)
            nrfArr.forEach( itm => {
            })
            let nrfObj = {
                "employeeName" : employeeName,
                "employeeNo" : employeeNo,
                //"chargesAmount" : parseFloat(totalCharges),
                "deductionType" : "MEMBERSHIP FEE",
                "deduction" : 'NRF',
                "chargesAmount" : (nrftotalcharges).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
                "collectionAmount" : (totalnrfcollection).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
                "remarks" : "",
                "checkbox" : "",
                //"totalBalance" : parseFloat(nrftotalcharges) - parseFloat(nrfcollection),
                "totalBalance" : (nrftotalcharges - totalnrfcollection).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
            }
            arrList.push(nrfObj)
            // end nrf

            // start aap
            let aapCharges = resp.ResultList.filter(x => x.deductionId === "2" && x.debit !== "0.00")
            let aaptotalcharges = aapCharges.reduce((total, currentValue) => total = total + parseFloat(currentValue.debit),0)
            let aapcollection = resp.ResultList.filter(x => x.deductionId === "2" && x.credit !== "0.00")
            let totalaapcollection = aapcollection.reduce((total, currentValue) => total = total + parseFloat(currentValue.credit),0)
            
            let aapArr = aapCharges.filter((thing, index, self) =>
                index === self.findIndex((t) => (
                    t.deductionId === thing.deductionId
                ))
            )
            let aapObj = {
                "employeeName" : employeeName,
                "employeeNo" : employeeNo,
                //"chargesAmount" : parseFloat(totalCharges),
                "deductionType" : "MEMBERSHIP FEE",
                "deduction" : 'AAP',
                "chargesAmount" : (aaptotalcharges).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
                "collectionAmount" : (totalaapcollection).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
                "remarks" : "",
                "checkbox" : "",
                "totalBalance" : (aaptotalcharges - totalaapcollection).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
            }
            arrList.push(aapObj)
            // end aap
            // start bank transfer fee
            let banktransferfeeCharges = resp.ResultList.filter(x => x.deductionId === "3" && x.debit !== "0.00")
            let banktransferfeetotalcharges = banktransferfeeCharges.reduce((total, currentValue) => total = total + parseFloat(currentValue.debit),0)
            let banktransferfeecollection = resp.ResultList.filter(x => x.deductionId === "3" && x.credit !== "0.00")
            let totalbanktransferfeecollection = banktransferfeecollection.reduce((total, currentValue) => total = total + parseFloat(currentValue.credit),0)
            
            let banktransferfeeArr = banktransferfeeCharges.filter((thing, index, self) =>
                index === self.findIndex((t) => (
                    t.deductionId === thing.deductionId
                ))
            )
            let banktransferfeeObj = {
                "employeeName" : employeeName,
                "employeeNo" : employeeNo,
                //"chargesAmount" : parseFloat(totalCharges),
                "deductionType" : "BANK FEES",
                "deduction" : 'BANK TRANSFER FEE',
                "chargesAmount" : (banktransferfeetotalcharges).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
                "collectionAmount" : (totalbanktransferfeecollection).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
                "remarks" : "",
                "checkbox" : "",
                "totalBalance" : (banktransferfeetotalcharges - totalbanktransferfeecollection).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
            }
            arrList.push(banktransferfeeObj)
            // end bank transaction

            // start bank feed
            let bankfeedCharges = resp.ResultList.filter(x => x.deductionId === "4" && x.debit !== "0.00")
            let bankfeedtotalcharges = bankfeedCharges.reduce((total, currentValue) => total = total + parseFloat(currentValue.debit),0)
            let bankfeedcollection = resp.ResultList.filter(x => x.deductionId === "4" && x.credit !== "0.00")
            let totalbankfeedcollection = bankfeedcollection.reduce((total, currentValue) => total = total + parseFloat(currentValue.credit),0)
            
            let bankfeedArr = bankfeedCharges.filter((thing, index, self) =>
                index === self.findIndex((t) => (
                    t.deductionId === thing.deductionId
                ))
            )
            let bankfeedObj = {
                "employeeName" : employeeName,
                "employeeNo" : employeeNo,
                //"chargesAmount" : parseFloat(totalCharges),
                "deductionType" : "BANK FEES",
                "deduction" : 'BANK FEED',
                "chargesAmount" : (bankfeedtotalcharges).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
                "collectionAmount" : (totalbankfeedcollection).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
                "remarks" : "",
                "checkbox" : "",
                "totalBalance" : (bankfeedtotalcharges - totalbankfeedcollection).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
            }
            arrList.push(bankfeedObj)
            // end bank feed

            // start cbu
            let cbuCharges = resp.ResultList.filter(x => x.deductionId === "5" && x.debit !== "0.00")
            let cbutotalcharges = cbuCharges.reduce((total, currentValue) => total = total + parseFloat(currentValue.debit),0)
            let cbucollection = resp.ResultList.filter(x => x.deductionId === "5" && x.credit !== "0.00")
            let totalcbucollection = cbucollection.reduce((total, currentValue) => total = total + parseFloat(currentValue.credit),0)
            
            let cbuArr = cbuCharges.filter((thing, index, self) =>
                index === self.findIndex((t) => (
                    t.deductionId === thing.deductionId
                ))
            )
            let cbuObj = {
                "employeeName" : employeeName,
                "employeeNo" : employeeNo,
                //"chargesAmount" : parseFloat(totalCharges),
                "deductionType" : "CAPITAL BUILD UP",
                "deduction" : 'CBU',
                "chargesAmount" : (cbutotalcharges).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
                "collectionAmount" : (totalcbucollection).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
                "remarks" : "",
                "checkbox" : "",
                "totalBalance" : (cbutotalcharges - totalcbucollection).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
            }
            arrList.push(cbuObj)
            // end cbu

            // start hmo
            let hmoCharges = resp.ResultList.filter(x => x.deductionId === "6" && x.debit !== "0.00")
            let hmototalcharges = hmoCharges.reduce((total, currentValue) => total = total + parseFloat(currentValue.debit),0)
            let hmocollection = resp.ResultList.filter(x => x.deductionId === "6" && x.credit !== "0.00")
            let totalhmocollection = hmocollection.reduce((total, currentValue) => total = total + parseFloat(currentValue.credit),0)
            
            let hmoArr = hmoCharges.filter((thing, index, self) =>
                index === self.findIndex((t) => (
                    t.deductionId === thing.deductionId
                ))
            )
            let hmoObj = {
                "employeeName" : employeeName,
                "employeeNo" : employeeNo,
                //"chargesAmount" : parseFloat(totalCharges),
                "deductionType" : "HMO-NEW",
                "deduction" : 'HMO',
                "chargesAmount" : (hmototalcharges).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
                "collectionAmount" : (totalhmocollection).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
                "remarks" : "",
                "checkbox" : "",
                "totalBalance" : (hmototalcharges - totalhmocollection).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
            }
            arrList.push(hmoObj)
            // end hmo

            // start penalty
            let penaltyCharges = resp.ResultList.filter(x => x.deductionId === "7" && x.debit !== "0.00")
            let penaltytotalcharges = penaltyCharges.reduce((total, currentValue) => total = total + parseFloat(currentValue.debit),0)
            let penaltycollection = resp.ResultList.filter(x => x.deductionId === "7" && x.credit !== "0.00")
            let totalpenaltycollection = penaltycollection.reduce((total, currentValue) => total = total + parseFloat(currentValue.credit),0)
            
            let penaltyArr = penaltyCharges.filter((thing, index, self) =>
                index === self.findIndex((t) => (
                    t.deductionId === thing.deductionId
                ))
            )
            let penaltyObj = {
                "employeeName" : employeeName,
                "employeeNo" : employeeNo,
                //"chargesAmount" : parseFloat(totalCharges),
                "deductionType" : "PENALTIES",
                "deduction" : 'PENALTY',
                "chargesAmount" : (penaltytotalcharges).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
                "collectionAmount" : (totalpenaltycollection).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
                "remarks" : "",
                "checkbox" : "",
                "totalBalance" : (penaltytotalcharges - totalpenaltycollection).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
            }
            arrList.push(penaltyObj)
            // end penalty

            // start cash card kit
            let cashcardkitCharges = resp.ResultList.filter(x => x.deductionId === "8" && x.debit !== "0.00")
            let cashcardkittotalcharges = cashcardkitCharges.reduce((total, currentValue) => total = total + parseFloat(currentValue.debit),0)
            let cashcardkitcollection = resp.ResultList.filter(x => x.deductionId === "8" && x.credit !== "0.00")
            let totalcashcardkitcollection = cashcardkitcollection.reduce((total, currentValue) => total = total + parseFloat(currentValue.credit),0)
            
            let cashcardkitArr = cashcardkitCharges.filter((thing, index, self) =>
                index === self.findIndex((t) => (
                    t.deductionId === thing.deductionId
                ))
            )
            let cashcardkitObj = {
                "employeeName" : employeeName,
                "employeeNo" : employeeNo,
                //"chargesAmount" : parseFloat(totalCharges),
                "deductionType" : "EMPLOYEES CHARGES",
                "deduction" : 'CASH CARD KIT',
                "chargesAmount" : (cashcardkittotalcharges).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
                "collectionAmount" : (totalcashcardkitcollection).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
                "remarks" : "",
                "checkbox" : "",
                "totalBalance" : (cashcardkittotalcharges - totalcashcardkitcollection).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
            }
            arrList.push(cashcardkitObj)
            // end cash card kit

            // start bank transaction
            let banktransactionCharges = resp.ResultList.filter(x => x.deductionId === "9" && x.debit !== "0.00")
            let banktransactiontotalcharges = banktransactionCharges.reduce((total, currentValue) => total = total + parseFloat(currentValue.debit),0)
            let banktransactioncollection = resp.ResultList.filter(x => x.deductionId === "9" && x.credit !== "0.00")
            let totalbanktransactioncollection = banktransactioncollection.reduce((total, currentValue) => total = total + parseFloat(currentValue.credit),0)
            
            let banktransactionArr = banktransactionCharges.filter((thing, index, self) =>
                index === self.findIndex((t) => (
                    t.deductionId === thing.deductionId
                ))
            )
            let banktransactionObj = {
                "employeeName" : employeeName,
                "employeeNo" : employeeNo,
                //"chargesAmount" : parseFloat(totalCharges),
                "deductionType" : "EMPLOYEES CHARGES",
                "deduction" : 'BANK TRANSACTION',
                "chargesAmount" : (banktransactiontotalcharges).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
                "collectionAmount" : (totalbanktransactioncollection).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
                "remarks" : "",
                "checkbox" : "",
                "totalBalance" : (banktransactiontotalcharges - totalbanktransactioncollection).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
            }
            arrList.push(banktransactionObj)
            // end bank transaction

            // start ml ammendment
            let mlammendmentCharges = resp.ResultList.filter(x => x.deductionId === "10" && x.debit !== "0.00")
            let mlammendmenttotalcharges = mlammendmentCharges.reduce((total, currentValue) => total = total + parseFloat(currentValue.debit),0)
            let mlammendmentcollection = resp.ResultList.filter(x => x.deductionId === "10" && x.credit !== "0.00")
            let totalmlammendmentcollection = mlammendmentcollection.reduce((total, currentValue) => total = total + parseFloat(currentValue.credit),0)
            
            let mlammendmentArr = mlammendmentCharges.filter((thing, index, self) =>
                index === self.findIndex((t) => (
                    t.deductionId === thing.deductionId
                ))
            )
            let mlammendmentObj = {
                "employeeName" : employeeName,
                "employeeNo" : employeeNo,
                //"chargesAmount" : parseFloat(totalCharges),
                "deductionType" : "EMPLOYEES CHARGES",
                "deduction" : 'ML AMMENDMENT',
                "chargesAmount" : (mlammendmenttotalcharges).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
                "collectionAmount" : (totalmlammendmentcollection).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
                "remarks" : "",
                "checkbox" : "",
                "totalBalance" : (mlammendmenttotalcharges - totalmlammendmentcollection).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
            }
            arrList.push(mlammendmentObj)
            // end ml ammendment

            // start canteen stub
            let canteenstubCharges = resp.ResultList.filter(x => x.deductionId === "11" && x.debit !== "0.00")
            let canteenstubtotalcharges = canteenstubCharges.reduce((total, currentValue) => total = total + parseFloat(currentValue.debit),0)
            let canteenstubcollection = resp.ResultList.filter(x => x.deductionId === "11" && x.credit !== "0.00")
            let totalcanteenstubcollection = canteenstubcollection.reduce((total, currentValue) => total = total + parseFloat(currentValue.credit),0)
            
            let canteenstubArr = canteenstubCharges.filter((thing, index, self) =>
                index === self.findIndex((t) => (
                    t.deductionId === thing.deductionId
                ))
            )
            let canteenstubObj = {
                "employeeName" : employeeName,
                "employeeNo" : employeeNo,
                //"chargesAmount" : parseFloat(totalCharges),
                "deductionType" : "CANTEEN STUB",
                "deduction" : 'CANTEEN STUB',
                "chargesAmount" : (canteenstubtotalcharges).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
                "collectionAmount" : (totalcanteenstubcollection).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
                "remarks" : "",
                "checkbox" : "",
                "totalBalance" : (canteenstubtotalcharges - totalcanteenstubcollection).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
            }
            arrList.push(canteenstubObj)
            // end canteen stub

            // start uniform
            let uniformCharges = resp.ResultList.filter(x => x.deductionId === "12" && x.debit !== "0.00")
            let uniformtotalcharges = uniformCharges.reduce((total, currentValue) => total = total + parseFloat(currentValue.debit),0)
            let uniformcollection = resp.ResultList.filter(x => x.deductionId === "12" && x.credit !== "0.00")
            let totaluniformcollection = uniformcollection.reduce((total, currentValue) => total = total + parseFloat(currentValue.credit),0)
            
            let uniformArr = uniformCharges.filter((thing, index, self) =>
                index === self.findIndex((t) => (
                    t.deductionId === thing.deductionId
                ))
            )
            let uniformObj = {
                "employeeName" : employeeName,
                "employeeNo" : employeeNo,
                //"chargesAmount" : parseFloat(totalCharges),
                "deductionType" : "PARAMOUNT UNIFORM",
                "deduction" : 'UNIFORM',
                "chargesAmount" : (uniformtotalcharges).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
                "collectionAmount" : (totaluniformcollection).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
                "remarks" : "",
                "checkbox" : "",
                "totalBalance" : (uniformtotalcharges - totaluniformcollection).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
            }
            arrList.push(uniformObj)
            // end uniform

            // start cash advance
            let cashadvanceCharges = resp.ResultList.filter(x => x.deductionId === "13" && x.debit !== "0.00")
            let cashadvancetotalcharges = cashadvanceCharges.reduce((total, currentValue) => total = total + parseFloat(currentValue.debit),0)
            let cashadvancecollection = resp.ResultList.filter(x => x.deductionId === "13" && x.credit !== "0.00")
            let totalcashadvancecollection = cashadvancecollection.reduce((total, currentValue) => total = total + parseFloat(currentValue.credit),0)
            
            let cashadvanceArr = cashadvanceCharges.filter((thing, index, self) =>
                index === self.findIndex((t) => (
                    t.deductionId === thing.deductionId
                ))
            )
            let cashadvanceObj = {
                "employeeName" : employeeName,
                "employeeNo" : employeeNo,
                //"chargesAmount" : parseFloat(totalCharges),
                "deductionType" : "CASH ADVANCE",
                "deduction" : 'CASH ADVANCE',
                "chargesAmount" : (cashadvancetotalcharges).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
                "collectionAmount" : (totalcashadvancecollection).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
                "remarks" : "",
                "checkbox" : "",
                "totalBalance" : (cashadvancetotalcharges - totalcashadvancecollection).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
            }
            arrList.push(cashadvanceObj)
            // end cash advance

            // start loss and damages
            let lossdamagesCharges = resp.ResultList.filter(x => x.deductionId === "14" && x.debit !== "0.00")
            let lossdamagestotalcharges = lossdamagesCharges.reduce((total, currentValue) => total = total + parseFloat(currentValue.debit),0)
            let lossdamagescollection = resp.ResultList.filter(x => x.deductionId === "14" && x.credit !== "0.00")
            let totallossdamagescollection = lossdamagescollection.reduce((total, currentValue) => total = total + parseFloat(currentValue.credit),0)
            
            let lossdamagesArr = lossdamagesCharges.filter((thing, index, self) =>
                index === self.findIndex((t) => (
                    t.deductionId === thing.deductionId
                ))
            )
            let lossdamagesObj = {
                "employeeName" : employeeName,
                "employeeNo" : employeeNo,
                //"chargesAmount" : parseFloat(totalCharges),
                "deductionType" : "LOSS AND DAMAGES",
                "deduction" : 'LOSS AND DAMAGES',
                "chargesAmount" : (lossdamagestotalcharges).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
                "collectionAmount" : (totallossdamagescollection).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
                "remarks" : "",
                "checkbox" : "",
                //"totalBalance" : parseFloat(lossdamagestotalcharges) - parseFloat(totallossdamagescollection),
                "totalBalance" : (lossdamagestotalcharges - totallossdamagescollection).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
            }
            arrList.push(lossdamagesObj)
            // end loss and damages

            // start over payment
            let overpaymentCharges = resp.ResultList.filter(x => x.deductionId === "15" && x.debit !== "0.00")
            let overpaymenttotalcharges = overpaymentCharges.reduce((total, currentValue) => total = total + parseFloat(currentValue.debit),0)
            let overpaymentcollection = resp.ResultList.filter(x => x.deductionId === "15" && x.credit !== "0.00")
            let totaloverpaymentcollection = overpaymentcollection.reduce((total, currentValue) => total = total + parseFloat(currentValue.credit),0)
            
            let overpaymentArr = overpaymentCharges.filter((thing, index, self) =>
                index === self.findIndex((t) => (
                    t.deductionId === thing.deductionId
                ))
            )
            let overpaymentObj = {
                "employeeName" : employeeName,
                "employeeNo" : employeeNo,
                //"chargesAmount" : parseFloat(totalCharges),
                "deductionType" : "OVER PAYMENT",
                "deduction" : 'OVER PAYMENT',
                "chargesAmount" : (overpaymenttotalcharges).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
                "collectionAmount" : (totaloverpaymentcollection).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
                "remarks" : "",
                "checkbox" : "",
                "totalBalance" : (overpaymenttotalcharges - totaloverpaymentcollection).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
            }
            arrList.push(overpaymentObj)
            // end over payment

            // start shortages
            let shortagesCharges = resp.ResultList.filter(x => x.deductionId === "16" && x.debit !== "0.00")
            let shortagestotalcharges = shortagesCharges.reduce((total, currentValue) => total = total + parseFloat(currentValue.debit),0)
            let shortagescollection = resp.ResultList.filter(x => x.deductionId === "16" && x.credit !== "0.00")
            let totalshortagescollection = shortagescollection.reduce((total, currentValue) => total = total + parseFloat(currentValue.credit),0)
            
            let shortagesArr = shortagesCharges.filter((thing, index, self) =>
                index === self.findIndex((t) => (
                    t.deductionId === thing.deductionId
                ))
            )
            let shortagesObj = {
                "employeeName" : employeeName,
                "employeeNo" : employeeNo,
                //"chargesAmount" : parseFloat(totalCharges),
                "deductionType" : "SHORTAGES",
                "deduction" : 'SHORTAGES',
                "chargesAmount" : (shortagestotalcharges).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
                "collectionAmount" : (totalshortagescollection).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
                "remarks" : "",
                "checkbox" : "",
                //"totalBalance" : parseFloat(shortagestotalcharges) - parseFloat(totalshortagescollection),
                "totalBalance" : (shortagestotalcharges - totalshortagescollection).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
            }
            arrList.push(shortagesObj)
            // end shortages				// end shortages

            // start credit bread
            let creditbreadCharges = resp.ResultList.filter(x => x.deductionId === "17" && x.debit !== "0.00")
            let creditbreadtotalcharges = creditbreadCharges.reduce((total, currentValue) => total = total + parseFloat(currentValue.debit),0)
            let creditbreadcollection = resp.ResultList.filter(x => x.deductionId === "17" && x.credit !== "0.00")
            let totalcreditbreadcollection = creditbreadcollection.reduce((total, currentValue) => total = total + parseFloat(currentValue.credit),0)
            
            let creditbreadArr = creditbreadCharges.filter((thing, index, self) =>
                index === self.findIndex((t) => (
                    t.deductionId === thing.deductionId
                ))
            )
            let creditbreadObj = {
                "employeeName" : employeeName,
                "employeeNo" : employeeNo,
                //"chargesAmount" : parseFloat(totalCharges),
                "deductionType" : "CREDIT BREAD",
                "deduction" : 'CREDIT BREAD',
                "chargesAmount" : (creditbreadtotalcharges).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
                "collectionAmount" : (totalcreditbreadcollection).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
                "remarks" : "",
                "checkbox" : "",
                //"totalBalance" : parseFloat(creditbreadtotalcharges) - parseFloat(totalcreditbreadcollection),
                "totalBalance" : (creditbreadtotalcharges - totalcreditbreadcollection).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
            }
            arrList.push(creditbreadObj)
            // end credit bread

            // start credit pork
            let creditporkCharges = resp.ResultList.filter(x => x.deductionId === "18" && x.debit !== "0.00")
            let creditporktotalcharges = creditporkCharges.reduce((total, currentValue) => total = total + parseFloat(currentValue.debit),0)
            let creditporkcollection = resp.ResultList.filter(x => x.deductionId === "18" && x.credit !== "0.00")
            let totalcreditporkcollection = creditporkcollection.reduce((total, currentValue) => total = total + parseFloat(currentValue.credit),0)
            
            let creditporkArr = creditporkCharges.filter((thing, index, self) =>
                index === self.findIndex((t) => (
                    t.deductionId === thing.deductionId
                ))
            )
            let creditporkObj = {
                "employeeName" : employeeName,
                "employeeNo" : employeeNo,
                //"chargesAmount" : parseFloat(totalCharges),
                "deductionType" : "CREDIT PORK",
                "deduction" : 'CREDIT PORK',
                "chargesAmount" : (creditporktotalcharges).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
                "collectionAmount" : (totalcreditporkcollection).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
                "remarks" : "",
                "checkbox" : "",
                //"totalBalance" : parseFloat(creditporktotalcharges) - parseFloat(totalcreditporkcollection),
                "totalBalance" : (creditporktotalcharges - totalcreditporkcollection).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
            }
            arrList.push(creditporkObj)
            // end credit pork

            // start true money kit
            let moneykitCharges = resp.ResultList.filter(x => x.deductionId === "19" && x.debit !== "0.00")
            let moneykittotalcharges = moneykitCharges.reduce((total, currentValue) => total = total + parseFloat(currentValue.debit),0)
            let moneykitcollection = resp.ResultList.filter(x => x.deductionId === "19" && x.credit !== "0.00")
            let totalmoneykitcollection = moneykitcollection.reduce((total, currentValue) => total = total + parseFloat(currentValue.credit),0)
            
            let moneykitArr = moneykitCharges.filter((thing, index, self) =>
                index === self.findIndex((t) => (
                    t.deductionId === thing.deductionId
                ))
            )
            let moneykitObj = {
                "employeeName" : employeeName,
                "employeeNo" : employeeNo,
                //"chargesAmount" : parseFloat(totalCharges),
                "deductionType" : "OTHERS",
                "deduction" : 'TRUE MONEY KIT',
                "chargesAmount" : (moneykittotalcharges).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
                "collectionAmount" : (totalmoneykitcollection).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
                "remarks" : "",
                "checkbox" : "",
                //"totalBalance" : parseFloat(moneykittotalcharges) - parseFloat(totalmoneykitcollection),
                "totalBalance" : (moneykittotalcharges - totalmoneykitcollection).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
            }
            arrList.push(moneykitObj)
            // end true money kit

            // start paramount id
            let paramountidCharges = resp.ResultList.filter(x => x.deductionId === "20" && x.debit !== "0.00")
            let paramountidtotalcharges = paramountidCharges.reduce((total, currentValue) => total = total + parseFloat(currentValue.debit),0)
            let paramountidcollection = resp.ResultList.filter(x => x.deductionId === "20" && x.credit !== "0.00")
            let totalparamountidcollection = paramountidcollection.reduce((total, currentValue) => total = total + parseFloat(currentValue.credit),0)
            
            let paramountidArr = paramountidCharges.filter((thing, index, self) =>
                index === self.findIndex((t) => (
                    t.deductionId === thing.deductionId
                ))
            )
            let paramountidObj = {
                "employeeName" : employeeName,
                "employeeNo" : employeeNo,
                //"chargesAmount" : parseFloat(totalCharges),
                "deductionType" : "PARAMOUNT ID",
                "deduction" : 'PARAMOUNT ID',
                "chargesAmount" : (paramountidtotalcharges).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
                "collectionAmount" : (totalparamountidcollection).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
                "remarks" : "",
                "checkbox" : "",
                //"totalBalance" : parseFloat(paramountidtotalcharges) - parseFloat(totalparamountidcollection),
                "totalBalance" : (paramountidtotalcharges - totalparamountidcollection).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
            }
            arrList.push(paramountidObj)
            // end paramount id

            // start medical
            let medicalCharges = resp.ResultList.filter(x => x.deductionId === "21" && x.debit !== "0.00")
            let medicaltotalcharges = medicalCharges.reduce((total, currentValue) => total = total + parseFloat(currentValue.debit),0)
            let medicalcollection = resp.ResultList.filter(x => x.deductionId === "21" && x.credit !== "0.00")
            let totalmedicalcollection = medicalcollection.reduce((total, currentValue) => total = total + parseFloat(currentValue.credit),0)
            
            let medicalArr = medicalCharges.filter((thing, index, self) =>
                index === self.findIndex((t) => (
                    t.deductionId === thing.deductionId
                ))
            )
            let medicalObj = {
                "employeeName" : employeeName,
                "employeeNo" : employeeNo,
                //"chargesAmount" : parseFloat(totalCharges),
                "deductionType" : "EMPLOYEES CHARGES",
                "deduction" : 'MEDICAL',
                "chargesAmount" : (medicaltotalcharges).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
                "collectionAmount" : (totalmedicalcollection).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
                "remarks" : "",
                "checkbox" : "",
                //"totalBalance" : parseFloat(medicaltotalcharges) - parseFloat(totalmedicalcollection),
                "totalBalance" : (medicaltotalcharges - totalmedicalcollection).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
            }
            arrList.push(medicalObj)
            // end medical

            // start other charges
            let otherchargesCharges = resp.ResultList.filter(x => x.deductionId === "22" && x.debit !== "0.00")
            let otherchargestotalcharges = otherchargesCharges.reduce((total, currentValue) => total = total + parseFloat(currentValue.debit),0)
            let otherchargescollection = resp.ResultList.filter(x => x.deductionId === "22" && x.credit !== "0.00")
            let totalotherchargescollection = otherchargescollection.reduce((total, currentValue) => total = total + parseFloat(currentValue.credit),0)
            
            let otherchargesArr = otherchargesCharges.filter((thing, index, self) =>
                index === self.findIndex((t) => (
                    t.deductionId === thing.deductionId
                ))
            )
            let otherchargesObj = {
                "employeeName" : employeeName,
                "employeeNo" : employeeNo,
                //"chargesAmount" : parseFloat(totalCharges),
                "deductionType" : "EMPLOYEES CHARGES",
                "deduction" : 'OTHER CHARGES',
                "chargesAmount" : (otherchargestotalcharges).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
                "collectionAmount" : (totalotherchargescollection).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
                "remarks" : "",
                "checkbox" : "",
                //"totalBalance" : parseFloat(otherchargestotalcharges) - parseFloat(totalotherchargescollection),
                "totalBalance" : (otherchargestotalcharges - totalotherchargescollection).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
            }
            arrList.push(otherchargesObj)
            // end other charges

            // start gadget loan
            let gadgetloanCharges = resp.ResultList.filter(x => x.deductionId === "23" && x.debit !== "0.00")
            let gadgetloantotalcharges = gadgetloanCharges.reduce((total, currentValue) => total = total + parseFloat(currentValue.debit),0)
            let gadgetloancollection = resp.ResultList.filter(x => x.deductionId === "23" && x.credit !== "0.00")
            let totalgadgetloancollection = gadgetloancollection.reduce((total, currentValue) => total = total + parseFloat(currentValue.credit),0)
            
            let gadgetloanArr = gadgetloanCharges.filter((thing, index, self) =>
                index === self.findIndex((t) => (
                    t.deductionId === thing.deductionId
                ))
            )
            let gadgetloanObj = {
                "employeeName" : employeeName,
                "employeeNo" : employeeNo,
                //"chargesAmount" : parseFloat(totalCharges),
                "deductionType" : "EMPLOYEES CHARGES",
                "deduction" : 'GADGET LOAN',
                "chargesAmount" : (gadgetloantotalcharges).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
                "collectionAmount" : (totalgadgetloancollection).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
                "remarks" : "",
                "checkbox" : "",
                //"totalBalance" : parseFloat(gadgetloantotalcharges) - parseFloat(totalgadgetloancollection),
                "totalBalance" : (gadgetloantotalcharges - totalgadgetloancollection).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
            }
            arrList.push(gadgetloanObj)
            // end gadget loan

            // start voucher
            let voucherCharges = resp.ResultList.filter(x => x.deductionId === "24" && x.debit !== "0.00")
            let vouchertotalcharges = voucherCharges.reduce((total, currentValue) => total = total + parseFloat(currentValue.debit),0)
            let vouchercollection = resp.ResultList.filter(x => x.deductionId === "24" && x.credit !== "0.00")
            let totalvouchercollection = vouchercollection.reduce((total, currentValue) => total = total + parseFloat(currentValue.credit),0)
            
            let voucherArr = voucherCharges.filter((thing, index, self) =>
                index === self.findIndex((t) => (
                    t.deductionId === thing.deductionId
                ))
            )
            let voucherObj = {
                "employeeName" : employeeName,
                "employeeNo" : employeeNo,
                //"chargesAmount" : parseFloat(totalCharges),
                "deductionType" : "GROCERY LOAN",
                "deduction" : 'VOUCHER',
                "chargesAmount" : (vouchertotalcharges).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
                "collectionAmount" : (totalvouchercollection).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
                "remarks" : "",
                "checkbox" : "",
                //"totalBalance" : parseFloat(vouchertotalcharges) - parseFloat(totalvouchercollection),
                "totalBalance" : (vouchertotalcharges - totalvouchercollection).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
            }
            arrList.push(voucherObj)
            // end voucher

            // start double credit
            let doublecreditCharges = resp.ResultList.filter(x => x.deductionId === "25" && x.debit !== "0.00")
            let doublecredittotalcharges = doublecreditCharges.reduce((total, currentValue) => total = total + parseFloat(currentValue.debit),0)
            let doublecreditcollection = resp.ResultList.filter(x => x.deductionId === "25" && x.credit !== "0.00")
            let totaldoublecreditcollection = doublecreditcollection.reduce((total, currentValue) => total = total + parseFloat(currentValue.credit),0)
            
            let doublecreditArr = doublecreditCharges.filter((thing, index, self) =>
                index === self.findIndex((t) => (
                    t.deductionId === thing.deductionId
                ))
            )
            let doublecreditObj = {
                "employeeName" : employeeName,
                "employeeNo" : employeeNo,
                //"chargesAmount" : parseFloat(totalCharges),
                "deductionType" : "DOUBLE CREDIT",
                "deduction" : 'DOUBLE CREDIT',
                "chargesAmount" : (doublecredittotalcharges).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
                "collectionAmount" : (totaldoublecreditcollection).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
                "remarks" : "",
                "checkbox" : "",
                //"totalBalance" : parseFloat(doublecredittotalcharges) - parseFloat(totaldoublecreditcollection),
                "totalBalance" : (doublecredittotalcharges - totaldoublecreditcollection).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
            }
            arrList.push(doublecreditObj)
            // end double credit

            // start freight
            let freightCharges = resp.ResultList.filter(x => x.deductionId === "26" && x.debit !== "0.00")
            let freighttotalcharges = freightCharges.reduce((total, currentValue) => total = total + parseFloat(currentValue.debit),0)
            let freightcollection = resp.ResultList.filter(x => x.deductionId === "26" && x.credit !== "0.00")
            let totalfreightcollection = freightcollection.reduce((total, currentValue) => total = total + parseFloat(currentValue.credit),0)
            
            let freightArr = freightCharges.filter((thing, index, self) =>
                index === self.findIndex((t) => (
                    t.deductionId === thing.deductionId
                ))
            )
            let freightObj = {
                "employeeName" : employeeName,
                "employeeNo" : employeeNo,
                //"chargesAmount" : parseFloat(totalCharges),
                "deductionType" : "EMPLOYEES CHARGES",
                "deduction" : 'FREIGHT',
                "chargesAmount" : (freighttotalcharges).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
                "collectionAmount" : (totalfreightcollection).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
                "remarks" : "",
                "checkbox" : "",
                //"totalBalance" : parseFloat(freighttotalcharges) - parseFloat(totalfreightcollection),
                "totalBalance" : (freighttotalcharges - totalfreightcollection).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
            }
            arrList.push(freightObj)
            // end freight

            // start sss salary loan
            let sssSalaryloanCharges = resp.ResultList.filter(x => x.deductionId === "30" && x.debit !== "0.00")
            let sssSalaryloantotalcharges = sssSalaryloanCharges.reduce((total, currentValue) => total = total + parseFloat(currentValue.debit),0)
            let sssSalaryloancollection = resp.ResultList.filter(x => x.deductionId === "30" && x.credit !== "0.00")
            let totalsssSalaryloancollection = sssSalaryloancollection.reduce((total, currentValue) => total = total + parseFloat(currentValue.credit),0)
            
            let sssSalaryloanArr = sssSalaryloanCharges.filter((thing, index, self) =>
                index === self.findIndex((t) => (
                    t.deductionId === thing.deductionId
                ))
            )
            let sssSalaryloanObj = {
                "employeeName" : employeeName,
                "employeeNo" : employeeNo,
                //"chargesAmount" : parseFloat(totalCharges),
                "deductionType" : "LOANS",
                "deduction" : 'SSS SALARY LOAN',
                "chargesAmount" : (sssSalaryloantotalcharges).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
                "collectionAmount" : (totalsssSalaryloancollection).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
                "remarks" : "",
                "checkbox" : "",
                "totalBalance" : (sssSalaryloantotalcharges - totalsssSalaryloancollection).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
            }
            arrList.push(sssSalaryloanObj)
            // end sss salary loan

            // start hdmf salary loan
            let hdmfSalaryloanCharges = resp.ResultList.filter(x => x.deductionId === "31" && x.debit !== "0.00")
            let hdmfSalaryloantotalcharges = hdmfSalaryloanCharges.reduce((total, currentValue) => total = total + parseFloat(currentValue.debit),0)
            let hdmfSalaryloancollection = resp.ResultList.filter(x => x.deductionId === "31" && x.credit !== "0.00")
            let totalhdmfSalaryloancollection = hdmfSalaryloancollection.reduce((total, currentValue) => total = total + parseFloat(currentValue.credit),0)
            
            let hdmfSalaryloanArr = hdmfSalaryloanCharges.filter((thing, index, self) =>
                index === self.findIndex((t) => (
                    t.deductionId === thing.deductionId
                ))
            )
            let hdmfSalaryloanObj = {
                "employeeName" : employeeName,
                "employeeNo" : employeeNo,
                //"chargesAmount" : parseFloat(totalCharges),
                "deductionType" : "LOANS",
                "deduction" : 'HDMF SALARY LOAN',
                "chargesAmount" : (hdmfSalaryloantotalcharges).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
                "collectionAmount" : (totalhdmfSalaryloancollection).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
                "remarks" : "",
                "checkbox" : "",
                "totalBalance" : (hdmfSalaryloantotalcharges - totalhdmfSalaryloancollection).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
            }
            arrList.push(hdmfSalaryloanObj)
            // end hdmf salary loan

            // start coop loan
            let cooploanCharges = resp.ResultList.filter(x => x.deductionId === "32" && x.debit !== "0.00")
            let cooploantotalcharges = cooploanCharges.reduce((total, currentValue) => total = total + parseFloat(currentValue.debit),0)
            let cooploancollection = resp.ResultList.filter(x => x.deductionId === "32" && x.credit !== "0.00")
            let totalcooploancollection = cooploancollection.reduce((total, currentValue) => total = total + parseFloat(currentValue.credit),0)
            
            let cooploanArr = cooploanCharges.filter((thing, index, self) =>
                index === self.findIndex((t) => (
                    t.deductionId === thing.deductionId
                ))
            )
            let cooploanObj = {
                "employeeName" : employeeName,
                "employeeNo" : employeeNo,
                //"chargesAmount" : parseFloat(totalCharges),
                "deductionType" : "LOANS",
                "deduction" : 'COOP LOAN',
                "chargesAmount" : (cooploantotalcharges).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
                "collectionAmount" : (totalcooploancollection).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
                "remarks" : "",
                "checkbox" : "",
                "totalBalance" : (cooploantotalcharges - totalcooploancollection).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
            }
            arrList.push(cooploanObj)
            // end coop loan

            // start sss calamity loan
            let ssscalamityloanCharges = resp.ResultList.filter(x => x.deductionId === "33" && x.debit !== "0.00")
            let ssscalamityloantotalcharges = ssscalamityloanCharges.reduce((total, currentValue) => total = total + parseFloat(currentValue.debit),0)
            let ssscalamityloancollection = resp.ResultList.filter(x => x.deductionId === "33" && x.credit !== "0.00")
            let totalssscalamityloancollection = ssscalamityloancollection.reduce((total, currentValue) => total = total + parseFloat(currentValue.credit),0)
            
            let ssscalamityloanArr = ssscalamityloanCharges.filter((thing, index, self) =>
                index === self.findIndex((t) => (
                    t.deductionId === thing.deductionId
                ))
            )
            let ssscalamityloanObj = {
                "employeeName" : employeeName,
                "employeeNo" : employeeNo,
                //"chargesAmount" : parseFloat(totalCharges),
                "deductionType" : "LOANS",
                "deduction" : 'SSS CALAMITY LOAN',
                "chargesAmount" : (ssscalamityloantotalcharges).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
                "collectionAmount" : (totalssscalamityloancollection).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
                "remarks" : "",
                "checkbox" : "",
                "totalBalance" : (ssscalamityloantotalcharges - totalssscalamityloancollection).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
            }
            arrList.push(ssscalamityloanObj)
            // end sss calamity loan

            // start hdmf calamity loan
            let hdmfcalamityloanCharges = resp.ResultList.filter(x => x.deductionId === "34" && x.debit !== "0.00")
            let hdmfcalamityloantotalcharges = hdmfcalamityloanCharges.reduce((total, currentValue) => total = total + parseFloat(currentValue.debit),0)
            let hdmfcalamityloancollection = resp.ResultList.filter(x => x.deductionId === "34" && x.credit !== "0.00")
            let totalhdmfcalamityloancollection = hdmfcalamityloancollection.reduce((total, currentValue) => total = total + parseFloat(currentValue.credit),0)
            
            let hdmfcalamityloanArr = hdmfcalamityloanCharges.filter((thing, index, self) =>
                index === self.findIndex((t) => (
                    t.deductionId === thing.deductionId
                ))
            )
            let hdmfcalamityloanObj = {
                "employeeName" : employeeName,
                "employeeNo" : employeeNo,
                //"chargesAmount" : parseFloat(totalCharges),
                "deductionType" : "LOANS",
                "deduction" : 'HDMF CALAMITY LOAN',
                "chargesAmount" : (hdmfcalamityloantotalcharges).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
                "collectionAmount" : (totalhdmfcalamityloancollection).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
                "remarks" : "",
                "checkbox" : "",
                //"totalBalance" : parseFloat(hdmfcalamityloantotalcharges) - parseFloat(totalhdmfcalamityloancollection),
                "totalBalance" : (hdmfcalamityloantotalcharges - totalhdmfcalamityloancollection).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
            }
            arrList.push(hdmfcalamityloanObj)
            // end hdmf calamity loan

            // start emergency loan
            let emergencyloanCharges = resp.ResultList.filter(x => x.deductionId === "35" && x.debit !== "0.00")
            let emergencyloantotalcharges = emergencyloanCharges.reduce((total, currentValue) => total = total + parseFloat(currentValue.debit),0)
            let emergencyloancollection = resp.ResultList.filter(x => x.deductionId === "35" && x.credit !== "0.00")
            let totalemergencyloancollection = emergencyloancollection.reduce((total, currentValue) => total = total + parseFloat(currentValue.credit),0)
            
            let emergencyloanArr = emergencyloanCharges.filter((thing, index, self) =>
                index === self.findIndex((t) => (
                    t.deductionId === thing.deductionId
                ))
            )
            let emergencyloanObj = {
                "employeeName" : employeeName,
                "employeeNo" : employeeNo,
                //"chargesAmount" : parseFloat(totalCharges),
                "deductionType" : "LOANS",
                "deduction" : 'EMERGENCY LOAN',
                "chargesAmount" : (emergencyloantotalcharges).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
                "collectionAmount" : (totalemergencyloancollection).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
                "remarks" : "",
                "checkbox" : "",
                "totalBalance" : (emergencyloantotalcharges - totalemergencyloancollection).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
            }
            arrList.push(emergencyloanObj)
            // end emergency loan

            // start motorcycle loan
            let motorcycleloanCharges = resp.ResultList.filter(x => x.deductionId === "36" && x.debit !== "0.00")
            let motorcycleloantotalcharges = motorcycleloanCharges.reduce((total, currentValue) => total = total + parseFloat(currentValue.debit),0)
            let motorcycleloancollection = resp.ResultList.filter(x => x.deductionId === "36" && x.credit !== "0.00")
            let totalmotorcycleloancollection = motorcycleloancollection.reduce((total, currentValue) => total = total + parseFloat(currentValue.credit),0)
            
            let motorcycleloanArr = motorcycleloanCharges.filter((thing, index, self) =>
                index === self.findIndex((t) => (
                    t.deductionId === thing.deductionId
                ))
            )
            let motorcycleloanObj = {
                "employeeName" : employeeName,
                "employeeNo" : employeeNo,
                //"chargesAmount" : parseFloat(totalCharges),
                "deductionType" : "LOANS",
                "deduction" : 'MOTORCYCLE LOAN',
                "chargesAmount" : (motorcycleloantotalcharges).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
                "collectionAmount" : (totalmotorcycleloancollection).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
                "remarks" : "",
                "checkbox" : "",
                "totalBalance" : (motorcycleloantotalcharges - totalmotorcycleloancollection).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
            }
            arrList.push(motorcycleloanObj)
            // end motorcycle loan

            // start gadget loan
            let gadgetloansCharges = resp.ResultList.filter(x => x.deductionId === "37" && x.debit !== "0.00")
            let gadgetloanstotalcharges = gadgetloansCharges.reduce((total, currentValue) => total = total + parseFloat(currentValue.debit),0)
            let gadgetloanscollection = resp.ResultList.filter(x => x.deductionId === "37" && x.credit !== "0.00")
            let totalgadgetloanscollection = gadgetloanscollection.reduce((total, currentValue) => total = total + parseFloat(currentValue.credit),0)
            
            let gadgetloansArr = gadgetloansCharges.filter((thing, index, self) =>
                index === self.findIndex((t) => (
                    t.deductionId === thing.deductionId
                ))
            )
            let gadgetloansObj = {
                "employeeName" : employeeName,
                "employeeNo" : employeeNo,
                //"chargesAmount" : parseFloat(totalCharges),
                "deductionType" : "LOANS",
                "deduction" : 'GADGET LOAN',
                "chargesAmount" : (gadgetloanstotalcharges).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
                "collectionAmount" : (totalgadgetloanscollection).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
                "remarks" : "",
                "checkbox" : "",
                "totalBalance" : (gadgetloanstotalcharges - totalgadgetloanscollection).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
            }
            arrList.push(gadgetloansObj)
            // end gadget loans

            // start hmo - 2020
            let hmo2020Charges = resp.ResultList.filter(x => x.deductionId === "38" && x.debit !== "0.00")
            let hmo2020totalcharges = hmo2020Charges.reduce((total, currentValue) => total = total + parseFloat(currentValue.debit),0)
            let hmo2020collection = resp.ResultList.filter(x => x.deductionId === "38" && x.credit !== "0.00")
            let totalhmo2020collection = hmo2020collection.reduce((total, currentValue) => total = total + parseFloat(currentValue.credit),0)
            
            let hmo2020Arr = hmo2020Charges.filter((thing, index, self) =>
                index === self.findIndex((t) => (
                    t.deductionId === thing.deductionId
                ))
            )
            let hmo2020Obj = {
                "employeeName" : employeeName,
                "employeeNo" : employeeNo,
                //"chargesAmount" : parseFloat(totalCharges),
                "deductionType" : "HMO-OLD",
                "deduction" : 'HMO - 2020',
                "chargesAmount" : (hmo2020totalcharges).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
                "collectionAmount" : (totalhmo2020collection).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
                "remarks" : "",
                "checkbox" : "",
                "totalBalance" : (hmo2020totalcharges - totalhmo2020collection).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
            }
            arrList.push(hmo2020Obj)
            // end hmo - 2020

            // start west appliances
            let westappliancesCharges = resp.ResultList.filter(x => x.deductionId === "39" && x.debit !== "0.00")
            let westappliancestotalcharges = westappliancesCharges.reduce((total, currentValue) => total = total + parseFloat(currentValue.debit),0)
            let westappliancescollection = resp.ResultList.filter(x => x.deductionId === "39" && x.credit !== "0.00")
            let totalwestappliancescollection = westappliancescollection.reduce((total, currentValue) => total = total + parseFloat(currentValue.credit),0)
            
            let westappliancesArr = westappliancesCharges.filter((thing, index, self) =>
                index === self.findIndex((t) => (
                    t.deductionId === thing.deductionId
                ))
            )
            let westappliancesObj = {
                "employeeName" : employeeName,
                "employeeNo" : employeeNo,
                //"chargesAmount" : parseFloat(totalCharges),
                "deductionType" : "EMPLOYEES CHARGES",
                "deduction" : 'WEST - APPLIANCES',
                "chargesAmount" : (westappliancestotalcharges).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
                "collectionAmount" : (totalwestappliancescollection).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
                "remarks" : "",
                "checkbox" : "",
                "totalBalance" : (westappliancestotalcharges - totalwestappliancescollection).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
            }
            arrList.push(westappliancesObj)
            // end west appliances

            // start inventory variance
            let inventoryvarianceCharges = resp.ResultList.filter(x => x.deductionId === "40" && x.debit !== "0.00")
            let inventoryvariancetotalcharges = inventoryvarianceCharges.reduce((total, currentValue) => total = total + parseFloat(currentValue.debit),0)
            let inventoryvariancecollection = resp.ResultList.filter(x => x.deductionId === "40" && x.credit !== "0.00")
            let totalinventoryvariancecollection = inventoryvariancecollection.reduce((total, currentValue) => total = total + parseFloat(currentValue.credit),0)
            
            let inventoryvarianceArr = inventoryvarianceCharges.filter((thing, index, self) =>
                index === self.findIndex((t) => (
                    t.deductionId === thing.deductionId
                ))
            )
            let inventoryvarianceObj = {
                "employeeName" : employeeName,
                "employeeNo" : employeeNo,
                //"chargesAmount" : parseFloat(totalCharges),
                "deductionType" : "INVENTORY VARIANCE",
                "deduction" : 'INVENTORY VARIANCE',
                "chargesAmount" : (inventoryvariancetotalcharges).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
                "collectionAmount" : (totalinventoryvariancecollection).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
                "remarks" : "",
                "checkbox" : "",
                "totalBalance" : (inventoryvariancetotalcharges - totalinventoryvariancecollection).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
            }
            arrList.push(inventoryvarianceObj)
            // end inventory variance

            // start hmo old
            let hmooldCharges = resp.ResultList.filter(x => x.deductionId === "41" && x.debit !== "0.00")
            let hmooldtotalcharges = hmooldCharges.reduce((total, currentValue) => total = total + parseFloat(currentValue.debit),0)
            let hmooldcollection = resp.ResultList.filter(x => x.deductionId === "41" && x.credit !== "0.00")
            let totalhmooldcollection = hmooldcollection.reduce((total, currentValue) => total = total + parseFloat(currentValue.credit),0)
            
            let hmooldArr = hmooldCharges.filter((thing, index, self) =>
                index === self.findIndex((t) => (
                    t.deductionId === thing.deductionId
                ))
            )
            let hmooldObj = {
                "employeeName" : employeeName,
                "employeeNo" : employeeNo,
                //"chargesAmount" : parseFloat(totalCharges),
                "deductionType" : "HMO-OLD",
                "deduction" : 'HMO-OLD',
                "chargesAmount" : (hmooldtotalcharges).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
                "collectionAmount" : (totalhmooldcollection).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
                "remarks" : "",
                "checkbox" : "",
                "totalBalance" : (hmooldtotalcharges - totalhmooldcollection).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
            }
            arrList.push(hmooldObj)
            // end hmo old

            // start bike loan
            let bikeloanCharges = resp.ResultList.filter(x => x.deductionId === "42" && x.debit !== "0.00")
            let bikeloantotalcharges = bikeloanCharges.reduce((total, currentValue) => total = total + parseFloat(currentValue.debit),0)
            let bikeloancollection = resp.ResultList.filter(x => x.deductionId === "42" && x.credit !== "0.00")
            let totalbikeloancollection = bikeloancollection.reduce((total, currentValue) => total = total + parseFloat(currentValue.credit),0)
            
            let bikeloanArr = bikeloanCharges.filter((thing, index, self) =>
                index === self.findIndex((t) => (
                    t.deductionId === thing.deductionId
                ))
            )
            let bikeloanObj = {
                "employeeName" : employeeName,
                "employeeNo" : employeeNo,
                //"chargesAmount" : parseFloat(totalCharges),
                "deductionType" : "EMPLOYEES CHARGES",
                "deduction" : 'BIKE LOAN',
                "chargesAmount" : (bikeloantotalcharges).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
                "collectionAmount" : (totalbikeloancollection).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
                "remarks" : "",
                "checkbox" : "",
                "totalBalance" : (bikeloantotalcharges - totalbikeloancollection).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
            }
            arrList.push(bikeloanObj)
            // end bike loan

            // start mobile charges
            let mobilechargesCharges = resp.ResultList.filter(x => x.deductionId === "43" && x.debit !== "0.00")
            let mobilechargestotalcharges = mobilechargesCharges.reduce((total, currentValue) => total = total + parseFloat(currentValue.debit),0)
            let mobilechargescollection = resp.ResultList.filter(x => x.deductionId === "43" && x.credit !== "0.00")
            let totalmobilechargescollection = mobilechargescollection.reduce((total, currentValue) => total = total + parseFloat(currentValue.credit),0)
            
            let mobilechargesArr = mobilechargesCharges.filter((thing, index, self) =>
                index === self.findIndex((t) => (
                    t.deductionId === thing.deductionId
                ))
            )
            let mobilechargesObj = {
                "employeeName" : employeeName,
                "employeeNo" : employeeNo,
                //"chargesAmount" : parseFloat(totalCharges),
                "deductionType" : "MOBILE CHARGES",
                "deduction" : 'MOBILE CHARGES',
                "chargesAmount" : (mobilechargestotalcharges).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
                "collectionAmount" : (totalmobilechargescollection).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
                "remarks" : "",
                "checkbox" : "",
                "totalBalance" : (mobilechargestotalcharges - totalmobilechargescollection).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
            }
            arrList.push(mobilechargesObj)
            // end mobile charges

            // start app charges
            let appchargesCharges = resp.ResultList.filter(x => x.deductionId === "44" && x.debit !== "0.00")
            let appchargestotalcharges = appchargesCharges.reduce((total, currentValue) => total = total + parseFloat(currentValue.debit),0)
            let appchargescollection = resp.ResultList.filter(x => x.deductionId === "44" && x.credit !== "0.00")
            let totalappchargescollection = appchargescollection.reduce((total, currentValue) => total = total + parseFloat(currentValue.credit),0)
            
            let appchargesArr = appchargesCharges.filter((thing, index, self) =>
                index === self.findIndex((t) => (
                    t.deductionId === thing.deductionId
                ))
            )
            let appchargesObj = {
                "employeeName" : employeeName,
                "employeeNo" : employeeNo,
                //"chargesAmount" : parseFloat(totalCharges),
                "deductionType" : "EMPLOYEES CHARGES",
                "deduction" : 'AAP CHARGES',
                "chargesAmount" : (appchargestotalcharges).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
                "collectionAmount" : (totalappchargescollection).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
                "remarks" : "",
                "checkbox" : "",
                "totalBalance" : (appchargestotalcharges - totalappchargescollection).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
            }
            arrList.push(appchargesObj)
            // end app charges

            // start car fee
            let carfeeCharges = resp.ResultList.filter(x => x.deductionId === "45" && x.debit !== "0.00")
            let carfeetotalcharges = carfeeCharges.reduce((total, currentValue) => total = total + parseFloat(currentValue.debit),0)
            let carfeecollection = resp.ResultList.filter(x => x.deductionId === "45" && x.credit !== "0.00")
            let totalcarfeecollection = carfeecollection.reduce((total, currentValue) => total = total + parseFloat(currentValue.credit),0)
            
            let carfeeArr = carfeeCharges.filter((thing, index, self) =>
                index === self.findIndex((t) => (
                    t.deductionId === thing.deductionId
                ))
            )
            let carfeeObj = {
                "employeeName" : employeeName,
                "employeeNo" : employeeNo,
                //"chargesAmount" : parseFloat(totalCharges),
                "deductionType" : "CAR FEE",
                "deduction" : 'CAR FEE',
                "chargesAmount" : (carfeetotalcharges).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
                "collectionAmount" : (totalcarfeecollection).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
                "remarks" : "",
                "checkbox" : "",
                "totalBalance" : (carfeetotalcharges - totalcarfeecollection).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
            }
            arrList.push(carfeeObj)
            // end car fee

            // start cp loan charges
            let cploanchargesCharges = resp.ResultList.filter(x => x.deductionId === "46" && x.debit !== "0.00")
            let cploanchargestotalcharges = cploanchargesCharges.reduce((total, currentValue) => total = total + parseFloat(currentValue.debit),0)
            let cploanchargescollection = resp.ResultList.filter(x => x.deductionId === "46" && x.credit !== "0.00")
            let totalcploanchargescollection = cploanchargescollection.reduce((total, currentValue) => total = total + parseFloat(currentValue.credit),0)
            
            let cploanchargesArr = cploanchargesCharges.filter((thing, index, self) =>
                index === self.findIndex((t) => (
                    t.deductionId === thing.deductionId
                ))
            )
            let cploanchargesObj = {
                "employeeName" : employeeName,
                "employeeNo" : employeeNo,
                //"chargesAmount" : parseFloat(totalCharges),
                "deductionType" : "EMPLOYEES CHARGES",
                "deduction" : 'CP LOAN CHARGES',
                "chargesAmount" : (cploanchargestotalcharges).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
                "collectionAmount" : (totalcploanchargescollection).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
                "remarks" : "",
                "checkbox" : "",
                "totalBalance" : (cploanchargestotalcharges - totalcploanchargescollection).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
            }
            arrList.push(cploanchargesObj)
            // end cp loan charges

            // start travel fund
            let travelfundCharges = resp.ResultList.filter(x => x.deductionId === "51" && x.debit !== "0.00")
            let travelfundtotalcharges = travelfundCharges.reduce((total, currentValue) => total = total + parseFloat(currentValue.debit),0)
            let travelfundcollection = resp.ResultList.filter(x => x.deductionId === "51" && x.credit !== "0.00")
            let totaltravelfundcollection = travelfundcollection.reduce((total, currentValue) => total = total + parseFloat(currentValue.credit),0)
            
            let travelfundArr = travelfundCharges.filter((thing, index, self) =>
                index === self.findIndex((t) => (
                    t.deductionId === thing.deductionId
                ))
            )
            let travelfundObj = {
                "employeeName" : employeeName,
                "employeeNo" : employeeNo,
                //"chargesAmount" : parseFloat(totalCharges),
                "deductionType" : "TRAVEL FUND",
                "deduction" : 'TRAVEL FUND',
                "chargesAmount" : (travelfundtotalcharges).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
                "collectionAmount" : (totaltravelfundcollection).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
                "remarks" : "",
                "checkbox" : "",
                "totalBalance" : (travelfundtotalcharges - totaltravelfundcollection).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
            }
            arrList.push(travelfundObj)
            // end travel fund

            // start coop calamity loan
            let coopcalamityloanCharges = resp.ResultList.filter(x => x.deductionId === "52" && x.debit !== "0.00")
            let coopcalamityloantotalcharges = coopcalamityloanCharges.reduce((total, currentValue) => total = total + parseFloat(currentValue.debit),0)
            let coopcalamityloancollection = resp.ResultList.filter(x => x.deductionId === "52" && x.credit !== "0.00")
            let totalcoopcalamityloancollection = coopcalamityloancollection.reduce((total, currentValue) => total = total + parseFloat(currentValue.credit),0)
            
            let coopcalamityloanArr = coopcalamityloanCharges.filter((thing, index, self) =>
                index === self.findIndex((t) => (
                    t.deductionId === thing.deductionId
                ))
            )
            let coopcalamityloanObj = {
                "employeeName" : employeeName,
                "employeeNo" : employeeNo,
                //"chargesAmount" : parseFloat(totalCharges),
                "deductionType" : "LOANS",
                "deduction" : 'COOP CALAMITY LOAN',
                "chargesAmount" : (coopcalamityloantotalcharges).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
                "collectionAmount" : (totalcoopcalamityloancollection).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
                "remarks" : "",
                "checkbox" : "",
                "totalBalance" : (coopcalamityloantotalcharges - totalcoopcalamityloancollection).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
            }
            arrList.push(coopcalamityloanObj)
            // end coop calamity loan

            // start eecharges hmo
            let eechargeshmoCharges = resp.ResultList.filter(x => x.deductionId === "53" && x.debit !== "0.00")
            let eechargeshmototalcharges = eechargeshmoCharges.reduce((total, currentValue) => total = total + parseFloat(currentValue.debit),0)
            let eechargeshmocollection = resp.ResultList.filter(x => x.deductionId === "53" && x.credit !== "0.00")
            let totaleechargeshmocollection = eechargeshmocollection.reduce((total, currentValue) => total = total + parseFloat(currentValue.credit),0)
            
            let eechargeshmoArr = eechargeshmoCharges.filter((thing, index, self) =>
                index === self.findIndex((t) => (
                    t.deductionId === thing.deductionId
                ))
            )
            let eechargeshmoObj = {
                "employeeName" : employeeName,
                "employeeNo" : employeeNo,
                //"chargesAmount" : parseFloat(totalCharges),
                "deductionType" : "HMO-OLD",
                "deduction" : 'EECHARGES - HMO',
                "chargesAmount" : (eechargeshmototalcharges).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
                "collectionAmount" : (totaleechargeshmocollection).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
                "remarks" : "",
                "checkbox" : "",
                "totalBalance" : (eechargeshmototalcharges - totaleechargeshmocollection).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
            }
            arrList.push(eechargeshmoObj)
            // end eecharges hmo

            // start hmo current month
            let hmocurrentmonthCharges = resp.ResultList.filter(x => x.deductionId === "54" && x.debit !== "0.00")
            let hmocurrentmonthtotalcharges = hmocurrentmonthCharges.reduce((total, currentValue) => total = total + parseFloat(currentValue.debit),0)
            let hmocurrentmonthcollection = resp.ResultList.filter(x => x.deductionId === "54" && x.credit !== "0.00")
            let totalhmocurrentmonthcollection = hmocurrentmonthcollection.reduce((total, currentValue) => total = total + parseFloat(currentValue.credit),0)
            
            let hmocurrentmonthArr = hmocurrentmonthCharges.filter((thing, index, self) =>
                index === self.findIndex((t) => (
                    t.deductionId === thing.deductionId
                ))
            )
            let hmocurrentmonthObj = {
                "employeeName" : employeeName,
                "employeeNo" : employeeNo,
                //"chargesAmount" : parseFloat(totalCharges),
                "deductionType" : "HMO-NEW",
                "deduction" : 'HMO-CURRENT MONTH',
                "chargesAmount" : (hmocurrentmonthtotalcharges).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
                "collectionAmount" : (totalhmocurrentmonthcollection).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
                "remarks" : "",
                "checkbox" : "",
                "totalBalance" : (hmocurrentmonthtotalcharges - totalhmocurrentmonthcollection).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
            }
            arrList.push(hmocurrentmonthObj)
            // end hmo current month

            // start cash bond
            let cashbondCharges = resp.ResultList.filter(x => x.deductionId === "55" && x.debit !== "0.00")
            let cashbondtotalcharges = cashbondCharges.reduce((total, currentValue) => total = total + parseFloat(currentValue.debit),0)
            let cashbondcollection = resp.ResultList.filter(x => x.deductionId === "55" && x.credit !== "0.00")
            let totalcashbondcollection = cashbondcollection.reduce((total, currentValue) => total = total + parseFloat(currentValue.credit),0)
            
            let cashbondArr = cashbondCharges.filter((thing, index, self) =>
                index === self.findIndex((t) => (
                    t.deductionId === thing.deductionId
                ))
            )
            let cashbondObj = {
                "employeeName" : employeeName,
                "employeeNo" : employeeNo,
                //"chargesAmount" : parseFloat(totalCharges),
                "deductionType" : "EMPLOYEES CHARGES",
                "deduction" : 'CASH BOND',
                "chargesAmount" : (cashbondtotalcharges).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
                "collectionAmount" : (totalcashbondcollection).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
                "remarks" : "",
                "checkbox" : "",
                "totalBalance" : (cashbondtotalcharges - totalcashbondcollection).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
            }
            arrList.push(cashbondObj)
            // end cash bond

            // start coop loan special
            let cooploanspecialCharges = resp.ResultList.filter(x => x.deductionId === "56" && x.debit !== "0.00")
            let cooploanspecialtotalcharges = cooploanspecialCharges.reduce((total, currentValue) => total = total + parseFloat(currentValue.debit),0)
            let cooploanspecialcollection = resp.ResultList.filter(x => x.deductionId === "56" && x.credit !== "0.00")
            let totalcooploanspecialcollection = cooploanspecialcollection.reduce((total, currentValue) => total = total + parseFloat(currentValue.credit),0)
            
            let cooploanspecialArr = cooploanspecialCharges.filter((thing, index, self) =>
                index === self.findIndex((t) => (
                    t.deductionId === thing.deductionId
                ))
            )
            let cooploanspecialObj = {
                "employeeName" : employeeName,
                "employeeNo" : employeeNo,
                //"chargesAmount" : parseFloat(totalCharges),
                "deductionType" : "EMPLOYEES CHARGES",
                "deduction" : 'COOP LOAN-SPECIAL',
                "chargesAmount" : (cooploanspecialtotalcharges).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
                "collectionAmount" : (totalcooploanspecialcollection).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
                "remarks" : "",
                "checkbox" : "",
                "totalBalance" : (cooploanspecialtotalcharges - totalcooploanspecialcollection).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
            }
            arrList.push(cooploanspecialObj)
            // end coop loan special

            // start hdmf mp
            let hdmfmpCharges = resp.ResultList.filter(x => x.deductionId === "57" && x.debit !== "0.00")
            let hdmfmptotalcharges = hdmfmpCharges.reduce((total, currentValue) => total = total + parseFloat(currentValue.debit),0)
            let hdmfmpcollection = resp.ResultList.filter(x => x.deductionId === "57" && x.credit !== "0.00")
            let totalhdmfmpcollection = hdmfmpcollection.reduce((total, currentValue) => total = total + parseFloat(currentValue.credit),0)
            
            let hdmfmpArr = hdmfmpCharges.filter((thing, index, self) =>
                index === self.findIndex((t) => (
                    t.deductionId === thing.deductionId
                ))
            )
            let hdmfmpObj = {
                "employeeName" : employeeName,
                "employeeNo" : employeeNo,
                //"chargesAmount" : parseFloat(totalCharges),
                "deductionType" : "HDMF MP2",
                "deduction" : 'HDMF MP1',
                "chargesAmount" : (hdmfmptotalcharges).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
                "collectionAmount" : (totalhdmfmpcollection).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
                "remarks" : "",
                "checkbox" : "",
                "totalBalance" : (hdmfmptotalcharges - totalhdmfmpcollection).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
            }
            arrList.push(hdmfmpObj)
            // end hdmf mp

            // start hmo old1
            let hmoOld1Charges = resp.ResultList.filter(x => x.deductionId === "58" && x.debit !== "0.00")
            let hmoOld1totalcharges = hmoOld1Charges.reduce((total, currentValue) => total = total + parseFloat(currentValue.debit),0)
            let hmoOld1collection = resp.ResultList.filter(x => x.deductionId === "58" && x.credit !== "0.00")
            let totalhmoOld1collection = hmoOld1collection.reduce((total, currentValue) => total = total + parseFloat(currentValue.credit),0)
            
            let hmoOld1Arr = hmoOld1Charges.filter((thing, index, self) =>
                index === self.findIndex((t) => (
                    t.deductionId === thing.deductionId
                ))
            )
            let hmoOld1Obj = {
                "employeeName" : employeeName,
                "employeeNo" : employeeNo,
                //"chargesAmount" : parseFloat(totalCharges),
                "deductionType" : "HMO-OLD",
                "deduction" : 'HMO OLD',
                "chargesAmount" : (hmoOld1totalcharges).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
                "collectionAmount" : (totalhmoOld1collection).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
                "remarks" : "",
                "checkbox" : "",
                "totalBalance" : (hmoOld1totalcharges - totalhmoOld1collection).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
            }
            arrList.push(hmoOld1Obj)
            // end hmo old1

            // start hmo current
            let hmocurrentCharges = resp.ResultList.filter(x => x.deductionId === "59" && x.debit !== "0.00")
            let hmocurrenttotalcharges = hmocurrentCharges.reduce((total, currentValue) => total = total + parseFloat(currentValue.debit),0)
            let hmocurrentcollection = resp.ResultList.filter(x => x.deductionId === "59" && x.credit !== "0.00")
            let totalhmocurrentcollection = hmocurrentcollection.reduce((total, currentValue) => total = total + parseFloat(currentValue.credit),0)
            
            let hmocurrentArr = hmocurrentCharges.filter((thing, index, self) =>
                index === self.findIndex((t) => (
                    t.deductionId === thing.deductionId
                ))
            )
            let hmocurrentObj = {
                "employeeName" : employeeName,
                "employeeNo" : employeeNo,
                //"chargesAmount" : parseFloat(totalCharges),
                "deductionType" : "HMO-NEW",
                "deduction" : 'HMO-CURRENT',
                "chargesAmount" : (hmocurrenttotalcharges).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
                "collectionAmount" : (totalhmocurrentcollection).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
                "remarks" : "",
                "checkbox" : "",
                "totalBalance" : (hmocurrenttotalcharges - totalhmocurrentcollection).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
            }
            arrList.push(hmocurrentObj)
            // end hmo current

            // start philhealth
            let philhealthCharges = resp.ResultList.filter(x => x.deductionId === "60" && x.debit !== "0.00")
            let philhealthtotalcharges = philhealthCharges.reduce((total, currentValue) => total = total + parseFloat(currentValue.debit),0)
            let philhealthcollection = resp.ResultList.filter(x => x.deductionId === "60" && x.credit !== "0.00")
            let totalphilhealthcollection = philhealthcollection.reduce((total, currentValue) => total = total + parseFloat(currentValue.credit),0)
            
            let philhealthArr = philhealthCharges.filter((thing, index, self) =>
                index === self.findIndex((t) => (
                    t.deductionId === thing.deductionId
                ))
            )
            let philhealthObj = {
                "employeeName" : employeeName,
                "employeeNo" : employeeNo,
                //"chargesAmount" : parseFloat(totalCharges),
                "deductionType" : "PHILHEALTH 1%",
                "deduction" : 'PHILHEALTH 1%',
                "chargesAmount" : (philhealthtotalcharges).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
                "collectionAmount" : (totalphilhealthcollection).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
                "remarks" : "",
                "checkbox" : "",
                "totalBalance" : (philhealthtotalcharges - totalphilhealthcollection).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
            }
            arrList.push(philhealthObj)
            // end philhealth

            // start hmo-old 2020
            let hmoold2020Charges = resp.ResultList.filter(x => x.deductionId === "61" && x.debit !== "0.00")
            let hmoold2020totalcharges = hmoold2020Charges.reduce((total, currentValue) => total = total + parseFloat(currentValue.debit),0)
            let hmoold2020collection = resp.ResultList.filter(x => x.deductionId === "61" && x.credit !== "0.00")
            let totalhmoold2020collection = hmoold2020collection.reduce((total, currentValue) => total = total + parseFloat(currentValue.credit),0)
            
            let hmoold2020Arr = hmoold2020Charges.filter((thing, index, self) =>
                index === self.findIndex((t) => (
                    t.deductionId === thing.deductionId
                ))
            )
            let hmoold2020Obj = {
                "employeeName" : employeeName,
                "employeeNo" : employeeNo,
                //"chargesAmount" : parseFloat(totalCharges),
                "deductionType" : "HMO-OLD",
                "deduction" : 'HMO OLD-2020',
                "chargesAmount" : (hmoold2020totalcharges).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
                "collectionAmount" : (totalhmoold2020collection).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
                "remarks" : "",
                "checkbox" : "",
                "totalBalance" : (hmoold2020totalcharges - totalhmoold2020collection).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
            }
            arrList.push(hmoold2020Obj)
            // end  hmo-old 2020

            // start hmo-old 2021
            let hmoold2021Charges = resp.ResultList.filter(x => x.deductionId === "62" && x.debit !== "0.00")
            let hmoold2021totalcharges = hmoold2021Charges.reduce((total, currentValue) => total = total + parseFloat(currentValue.debit),0)
            let hmoold2021collection = resp.ResultList.filter(x => x.deductionId === "62" && x.credit !== "0.00")
            let totalhmoold2021collection = hmoold2021collection.reduce((total, currentValue) => total = total + parseFloat(currentValue.credit),0)
            
            let hmoold2021Arr = hmoold2021Charges.filter((thing, index, self) =>
                index === self.findIndex((t) => (
                    t.deductionId === thing.deductionId
                ))
            )
            let hmoold2021Obj = {
                "employeeName" : employeeName,
                "employeeNo" : employeeNo,
                //"chargesAmount" : parseFloat(totalCharges),
                "deductionType" : "HMO-OLD",
                "deduction" : 'HMO OLD-2021',
                "chargesAmount" : (hmoold2021totalcharges).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
                "collectionAmount" : (totalhmoold2021collection).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
                "remarks" : "",
                "checkbox" : "",
                "totalBalance" : (hmoold2021totalcharges - totalhmoold2021collection).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
            }
            arrList.push(hmoold2021Obj)
            // end  hmo-old 2021

            // start hmo-old-jan-oct2022
            let hmooldjanoct2022Charges = resp.ResultList.filter(x => x.deductionId === "63" && x.debit !== "0.00")
            let hmooldjanoct2022totalcharges = hmooldjanoct2022Charges.reduce((total, currentValue) => total = total + parseFloat(currentValue.debit),0)
            let hmooldjanoct2022collection = resp.ResultList.filter(x => x.deductionId === "63" && x.credit !== "0.00")
            let totalhmooldjanoct2022collection = hmooldjanoct2022collection.reduce((total, currentValue) => total = total + parseFloat(currentValue.credit),0)
            
            let hmooldjanoct2022Arr = hmooldjanoct2022Charges.filter((thing, index, self) =>
                index === self.findIndex((t) => (
                    t.deductionId === thing.deductionId
                ))
            )
            let hmooldjanoct2022Obj = {
                "employeeName" : employeeName,
                "employeeNo" : employeeNo,
                //"chargesAmount" : parseFloat(totalCharges),
                "deductionType" : "HMO-OLD",
                "deduction" : 'HMO OLD-JAN-OCT 2022',
                "chargesAmount" : (hmooldjanoct2022totalcharges).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
                "collectionAmount" : (totalhmooldjanoct2022collection).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
                "remarks" : "",
                "checkbox" : "",
                "totalBalance" : (hmooldjanoct2022totalcharges - totalhmooldjanoct2022collection).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
            }
            arrList.push(hmooldjanoct2022Obj)
            // end hmo-old-jan-oct2022

            // start hmo current month new
            let hmocurrentmonthnewCharges = resp.ResultList.filter(x => x.deductionId === "64" && x.debit !== "0.00")
            let hmocurrentmonthnewtotalcharges = hmocurrentmonthnewCharges.reduce((total, currentValue) => total = total + parseFloat(currentValue.debit),0)
            let hmocurrentmonthnewcollection = resp.ResultList.filter(x => x.deductionId === "64" && x.credit !== "0.00")
            let totalhmocurrentmonthnewcollection = hmocurrentmonthnewcollection.reduce((total, currentValue) => total = total + parseFloat(currentValue.credit),0)
            
            let hmocurrentmonthnewArr = hmocurrentmonthnewCharges.filter((thing, index, self) =>
                index === self.findIndex((t) => (
                    t.deductionId === thing.deductionId
                ))
            )
            let hmocurrentmonthnewObj = {
                "employeeName" : employeeName,
                "employeeNo" : employeeNo,
                //"chargesAmount" : parseFloat(totalCharges),
                "deductionType" : "HMO-NEW",
                "deduction" : 'HMO-CURRENT MONTH - NEW',
                "chargesAmount" : (hmocurrentmonthnewtotalcharges).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
                "collectionAmount" : (totalhmocurrentmonthnewcollection).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
                "remarks" : "",
                "checkbox" : "",
                "totalBalance" : (hmocurrentmonthnewtotalcharges - totalhmocurrentmonthnewcollection).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
            }
            arrList.push(hmocurrentmonthnewObj)
            // end hmo current month new

            // start workers permit
            let workerspermitCharges = resp.ResultList.filter(x => x.deductionId === "65" && x.debit !== "0.00")
            let workerspermittotalcharges = workerspermitCharges.reduce((total, currentValue) => total = total + parseFloat(currentValue.debit),0)
            let workerspermitcollection = resp.ResultList.filter(x => x.deductionId === "65" && x.credit !== "0.00")
            let totalworkerspermitcollection = workerspermitcollection.reduce((total, currentValue) => total = total + parseFloat(currentValue.credit),0)
            
            let workerspermitArr = workerspermitCharges.filter((thing, index, self) =>
                index === self.findIndex((t) => (
                    t.deductionId === thing.deductionId
                ))
            )
            let workerspermitObj = {
                "employeeName" : employeeName,
                "employeeNo" : employeeNo,
                //"chargesAmount" : parseFloat(totalCharges),
                "deductionType" : "EMPLOYEES CHARGES",
                "deduction" : 'WORKERS PERMIT',
                "chargesAmount" : (workerspermittotalcharges).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
                "collectionAmount" : (totalworkerspermitcollection).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
                "remarks" : "",
                "checkbox" : "",
                "totalBalance" : (workerspermittotalcharges - totalworkerspermitcollection).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
            }
            arrList.push(workerspermitObj)
            // end workers permit

            // start assessment charges
            let assessmentCharges = resp.ResultList.filter(x => x.deductionId === "66" && x.debit !== "0.00")
            let assessmenttotalcharges = assessmentCharges.reduce((total, currentValue) => total = total + parseFloat(currentValue.debit),0)
            let assessmentcollection = resp.ResultList.filter(x => x.deductionId === "66" && x.credit !== "0.00")
            let totalassessmentcollection = assessmentcollection.reduce((total, currentValue) => total = total + parseFloat(currentValue.credit),0)
            
            let assessmentArr = assessmentCharges.filter((thing, index, self) =>
                index === self.findIndex((t) => (
                    t.deductionId === thing.deductionId
                ))
            )
            let assessmentObj = {
                "employeeName" : employeeName,
                "employeeNo" : employeeNo,
                //"chargesAmount" : parseFloat(totalCharges),
                "deductionType" : "EMPLOYEES CHARGES",
                "deduction" : 'ASSESSMENT CHARGES',
                "chargesAmount" : (assessmenttotalcharges).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
                "collectionAmount" : (totalassessmentcollection).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
                "remarks" : "",
                "checkbox" : "",
                "totalBalance" : (assessmenttotalcharges - totalassessmentcollection).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
            }
            arrList.push(assessmentObj)
            // end assessment charges

            // start palawan charges
            let palawanCharges = resp.ResultList.filter(x => x.deductionId === "67" && x.debit !== "0.00")
            let palawantotalcharges = palawanCharges.reduce((total, currentValue) => total = total + parseFloat(currentValue.debit),0)
            let palawancollection = resp.ResultList.filter(x => x.deductionId === "67" && x.credit !== "0.00")
            let totalpalawancollection = palawancollection.reduce((total, currentValue) => total = total + parseFloat(currentValue.credit),0)
            
            let palawanArr = palawanCharges.filter((thing, index, self) =>
                index === self.findIndex((t) => (
                    t.deductionId === thing.deductionId
                ))
            )
            let palawanObj = {
                "employeeName" : employeeName,
                "employeeNo" : employeeNo,
                //"chargesAmount" : parseFloat(totalCharges),
                "deductionType" : "EMPLOYEES CHARGES",
                "deduction" : 'PALAWAN CHARGES',
                "chargesAmount" : (palawantotalcharges).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
                "collectionAmount" : (totalpalawancollection).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
                "remarks" : "",
                "checkbox" : "",
                "totalBalance" : (palawantotalcharges - totalpalawancollection).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
            }
            arrList.push(palawanObj)
            // end palawan charges

            // start pilferage charges
            let pilferageCharges = resp.ResultList.filter(x => x.deductionId === "69" && x.debit !== "0.00")
            let pilferagetotalcharges = pilferageCharges.reduce((total, currentValue) => total = total + parseFloat(currentValue.debit),0)
            let pilferagecollection = resp.ResultList.filter(x => x.deductionId === "69" && x.credit !== "0.00")
            let totalpilferagecollection = pilferagecollection.reduce((total, currentValue) => total = total + parseFloat(currentValue.credit),0)
            
            let pilferageArr = pilferageCharges.filter((thing, index, self) =>
                index === self.findIndex((t) => (
                    t.deductionId === thing.deductionId
                ))
            )
            let pilferageObj = {
                "employeeName" : employeeName,
                "employeeNo" : employeeNo,
                //"chargesAmount" : parseFloat(totalCharges),
                "deductionType" : "EMPLOYEES CHARGES",
                "deduction" : 'PILFERAGE CHARGE',
                "chargesAmount" : (pilferagetotalcharges).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
                "collectionAmount" : (totalpilferagecollection).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
                "remarks" : "",
                "checkbox" : "",
                "totalBalance" : (pilferagetotalcharges - totalpilferagecollection).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
            }
            arrList.push(pilferageObj)
            // end pilferage charges

            // start raffle ticket
            let raffleticketCharges = resp.ResultList.filter(x => x.deductionId === "70" && x.debit !== "0.00")
            let raffletickettotalcharges = raffleticketCharges.reduce((total, currentValue) => total = total + parseFloat(currentValue.debit),0)
            let raffleticketcollection = resp.ResultList.filter(x => x.deductionId === "70" && x.credit !== "0.00")
            let totalraffleticketcollection = raffleticketcollection.reduce((total, currentValue) => total = total + parseFloat(currentValue.credit),0)
            
            let raffleticketArr = raffleticketCharges.filter((thing, index, self) =>
                index === self.findIndex((t) => (
                    t.deductionId === thing.deductionId
                ))
            )
            let raffleticketObj = {
                "employeeName" : employeeName,
                "employeeNo" : employeeNo,
                //"chargesAmount" : parseFloat(totalCharges),
                "deductionType" : "EMPLOYEES CHARGES",
                "deduction" : 'RAFLE TICKET',
                "chargesAmount" : (raffletickettotalcharges).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
                "collectionAmount" : (totalraffleticketcollection).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
                "remarks" : "",
                "checkbox" : "",
                "totalBalance" : (raffletickettotalcharges - totalraffleticketcollection).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
            }
            arrList.push(raffleticketObj)
            // end raffle ticket

            // start mc-excess book value
            let mcexcessbookvalueCharges = resp.ResultList.filter(x => x.deductionId === "73" && x.debit !== "0.00")
            let mcexcessbookvaluetotalcharges = mcexcessbookvalueCharges.reduce((total, currentValue) => total = total + parseFloat(currentValue.debit),0)
            let mcexcessbookvaluecollection = resp.ResultList.filter(x => x.deductionId === "73" && x.credit !== "0.00")
            let totalmcexcessbookvaluecollection = mcexcessbookvaluecollection.reduce((total, currentValue) => total = total + parseFloat(currentValue.credit),0)
            
            let mcexcessbookvalueArr = mcexcessbookvalueCharges.filter((thing, index, self) =>
                index === self.findIndex((t) => (
                    t.deductionId === thing.deductionId
                ))
            )
            let mcexcessbookvalueObj = {
                "employeeName" : employeeName,
                "employeeNo" : employeeNo,
                //"chargesAmount" : parseFloat(totalCharges),
                "deductionType" : "EMPLOYEES CHARGES",
                "deduction" : 'MC-EXCESS BOOK VALUE',
                "chargesAmount" : (mcexcessbookvaluetotalcharges).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
                "collectionAmount" : (totalmcexcessbookvaluecollection).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
                "remarks" : "",
                "checkbox" : "",
                "totalBalance" : (mcexcessbookvaluetotalcharges - totalmcexcessbookvaluecollection).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
            }
            arrList.push(mcexcessbookvalueObj)
            // end mc-excess book value

            // start mc-processing fee
            let mcprocessingfeeCharges = resp.ResultList.filter(x => x.deductionId === "74" && x.debit !== "0.00")
            let mcprocessingfeetotalcharges = mcprocessingfeeCharges.reduce((total, currentValue) => total = total + parseFloat(currentValue.debit),0)
            let mcprocessingfeecollection = resp.ResultList.filter(x => x.deductionId === "74" && x.credit !== "0.00")
            let totalmcprocessingfeecollection = mcprocessingfeecollection.reduce((total, currentValue) => total = total + parseFloat(currentValue.credit),0)
            
            let mcprocessingfeeArr = mcprocessingfeeCharges.filter((thing, index, self) =>
                index === self.findIndex((t) => (
                    t.deductionId === thing.deductionId
                ))
            )
            let mcprocessingfeeObj = {
                "employeeName" : employeeName,
                "employeeNo" : employeeNo,
                //"chargesAmount" : parseFloat(totalCharges),
                "deductionType" : "EMPLOYEES CHARGES",
                "deduction" : 'MC-PROCESSING FEE',
                "chargesAmount" : (mcprocessingfeetotalcharges).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
                "collectionAmount" : (totalmcprocessingfeecollection).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
                "remarks" : "",
                "checkbox" : "",
                "totalBalance" : (mcprocessingfeetotalcharges - totalmcprocessingfeecollection).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
            }
            arrList.push(mcprocessingfeeObj)
            // end mc-processing fee

            let _deductions = arrList.reduce((total, currentValue) => total = total + parseFloat(String(currentValue.totalBalance).replace(",","").replace(",","").replace(",","")),0)
            let dataTest = arrList.filter( x => x.totalBalance !== "0.00")
            let gridArry = []
            for ( let i = 0; i < dataTest.length; i++){
                let x_id = JSON.stringify(i + 1)
                let newArry = {
                    "id" : x_id,
                    "employeeName" : dataTest[i].employeeName,
                    "employeeNo" : dataTest[i].employeeNo,
                    "deductionType" : dataTest[i].deductionType,
                    "deduction" : dataTest[i].deduction,
                    "chargesAmount" : dataTest[i].chargesAmount,
                    "collectionAmount" : dataTest[i].collectionAmount,
                    "remarks" : dataTest[i].remarks,
                    "checkbox" : dataTest[i].checkbox,
                    "totalBalance" : dataTest[i].totalBalance,
                }
                gridArry.push(newArry)
            }

            let finalObjListArray = {
                "batchNumber" : dataList.batchNumber,
                "cashCardNumberMember":dataList.cashCardNumberMember,
                "clearanceDateMember":dataList.clearanceDateMember,
                "clearanceDetails":dataList.clearanceDetails,
                "clearanceStatus":dataList.clearanceStatus,
                "clientId":dataList.clientId,
                "clientName":dataList.clientName,
                "createdBy":dataList.createdBy,
                "createdByName":dataList.createdByName,
                "createdDate":dataList.createdDate,
                "cycleDays":dataList.cycleDays,
                "dateCreated":dataList.dateCreated,
                "dateForwardedToAmt":dataList.dateForwardedToAmt,
                "dateOfBirth":dataList.dateOfBirth,
                "dateReceivedByAdmin":dataList.dateReceivedByAdmin,
                "dateReceivedFromAmt":dataList.dateReceivedFromAmt,
                "dateReturnedByARE":dataList.dateReturnedByARE,
                "dateReturnedByClient":dataList.dateReturnedByClient,
                "dateReturnedByFinance":dataList.dateReturnedByFinance,
                "dateReturnedByLRD":dataList.dateReturnedByLRD, // cash card mode of payment
                "dateReturnedByRMT":dataList.dateReturnedByRMT,
                "dateReturnedByServices":dataList.dateReturnedByServices,
                "dateSettle":dataList.dateSettle,
                "dateStart":dataList.dateStart,
                "dateSubmitted":dataList.dateSubmitted,
                "dateSubmittedByLead":dataList.dateSubmittedByLead,
                "dateSubmittedByStaff":dataList.dateSubmittedByStaff,
                "dateUpdatedByARE":dataList.dateUpdatedByARE,
                "dateUpdatedByClient":dataList.dateUpdatedByClient,
                "dateUpdatedByFinance": dataList.dateUpdatedByFinance,
                "dateUpdatedByLRD": dataList.dateUpdatedByLRD,
                "dateUpdatedByRMT":dataList.dateUpdatedByRMT,
                "dateUpdatedByServices":dataList.dateUpdatedByServices,
                "employeeId": dataList.employeeId,
                "employeeName": dataList.employeeName,
                "firstName": dataList.firstName,
                "hdmfNumber": dataList.hdmfNumber,

                "id":dataList.id,
                "isCOERequestFormMember":dataList.isCOERequestFormMember,
                "isCash":dataList.isCash,
                "isCashCardMember":dataList.isCashCardMember,

                "isCheckMember":dataList.isCheckMember,
                "isCheckedByRmt":dataList.isCheckedByRmt,
                "isClearanceStatus":dataList.isClearanceStatus,
                "isClearedClient":dataList.isClearedClient ,
                "isClearedRmt":dataList.isClearedRmt,
                "isDeleted":dataList.isDeleted,
                "isEligible":dataList.isEligible,

                "isEligibleClient":dataList.isEligibleClient,
                "isForwardToARERmt":dataList.isForwardToARERmt ,

                "isHDMFNumberMember": dataList.isHDMFNumberMember,
                "isMlhuillierMember" :dataList.isMlhuillierMember,
                    
                /* ADDITIONAL */
                "isModified": dataList.isModified,
                "isNonEligibleClient":dataList.isNonEligibleClient,

                "isNotEligible":dataList.isNotEligible,
                "isPHICNumberMember":dataList.isPHICNumberMember,

                "isPalawanMember":dataList.isPalawanMember,
                "isResignationLetterMember":dataList.isResignationLetterMember,

                "isRetrunedServicesRmt":dataList.isRetrunedServicesRmt,
                "isReturnedRmt":dataList.isReturnedRmt,

                "isSSSNumberMember":dataList.isSSSNumberMember,
                "isSettleWithDole":dataList.isSettleWithDole,

                "isTINNumberMember":dataList.isTINNumberMember,
                "isTurnoverChecklistMember":dataList.isTurnoverChecklistMember,
                "isTypeOfCashCardMember":dataList.isTypeOfCashCardMember,
                "isValidIdMember": dataList.isValidIdMember,
                "lastName":dataList.lastName,
                "lastWorkingDateMember":dataList.lastWorkingDateMember,

                "locationId":dataList.locationId,
                "locationName":dataList.locationName,
                "middleName":dataList.middleName,
                "modifiedBy":dataList.modifiedBy,
                "modifiedByName":dataList.modifiedByName,
                "modifiedDate":dataList.modifiedDate,
                "notesMember":dataList.notesMember,
                "paycardTypeIdMember":dataList.paycardTypeIdMember,
                "phicNumber":dataList.phicNumber,
                "positionId":dataList.positionId,
                "positionName":dataList.positionName,
                "profileId":dataList.profileId,
                "reasonForLeaving" : dataList.reasonForLeaving,
                "reasonForLeavingId"  :  dataList.reasonForLeavingId,
                "remarksClient" : dataList.remarksClient,
                "remarksLrd" : dataList.remarksLrd,
                "remarksMember" : dataList.remarksMember,
                "remarksRmt" : dataList.remarksRmt,
                "resignationDateMember" : dataList.resignationDateMember,
                "returnedByARE" : dataList.returnedByARE,
                "returnedByClient" : dataList.returnedByClient,
                "returnedByFinance" : dataList.returnedByFinance,
                "returnedByLRD" : dataList.returnedByLRD,
                "returnedByRMT" : dataList.returnedByRMT,
                "returnedByServices" : dataList.returnedByServices,
                "rowIndex" : dataList.rowIndex,
                "servicesRemarks" : dataList.isCash,
                "sssNumber" : dataList.sssNumber,
                "submissionDateMember" : dataList.submissionDateMember,
                "submittedByLead" : dataList.submittedByLead,
                "submittedByStaff" : dataList.submittedByStaff,
                "suffixName" : dataList.suffixName,
                "timeStamp" : dataList.timeStamp,
                "tinNumber" : dataList.tinNumber,
                "typeOfCashCardMember" : dataList.typeOfCashCardMember,
                "updatedByARE" : dataList.updatedByARE,
                "updatedByClient" : dataList.updatedByClient,
                "updatedByFinance" : dataList.updatedByFinance,
                "updatedByLRD" : dataList.updatedByLRD,
                "updatedByRMT" : dataList.updatedByRMT,
                "updatedByServices" : dataList.updatedByServices,
                "validateByRmt" : dataList.validateByRmt,
                "paramountId" : dataList.paramountId,
                "isSelected" : "1",
                "isDeleted" : "1",
                "totalBalances" : _deductions,
                "atd" : "",
                "submitted" : "",
                "netPay" : "",
                "grossPay" : "",
                "inclusions" : "",
                "thirteenthMonth" : "",
                "sil" : "",
                "incentives" : "",
                "lastSalary" : "",
                "closingRemarks" : dataList.closingRemarks,
                "batchNumber" : dataList.batchNumber,
                "deductions" : gridArry.filter(x => x.chargesAmount !== "0.00"),
                "servicesAttachments" : dataList.servicesAttachments,
                /* END */
            }
            arrid.push(dataList.id)
            finalArrays.push(finalObjListArray)
            let totalCharges = 0
            let deduction = ""
            let arrCbU = []
            let arrCashAd = []
            this.setState({
                isloading   :   false,
            })
            if(data.length === 0){
                this.setState({
                    isloading   :   false,
                })
            }
            this.setState({
                totalBalanceArr : gridArry,
                trainingGrid : finalArrays,
                isshowExpand : true,
                selectedId : arrid
            })
        }
        else{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   resp.message,
                fade        :   true
            });
            return
        }
        
    }
    GetCollectionAmount = async(isDeleted, docNo, deductionId, employeeNo, employeeName,amount, deductionType, deduction, transactionDate, id) => {
        let arr = []
        let resp = await GetRequest({
            url : AppConfiguration.Setting().noserapi + "action/get",
            params : {
                "dbname"      :  AppConfiguration.Setting().noseraredb,
                "_collection" : "SubsidiaryLedgerStaging",
                "filter_data" : {"isDeleted" : isDeleted, "docNo" : docNo, "deductionId" : deductionId, "employeeNo" : employeeNo}
            }    
        })

        if(resp.status==="1"){
            let collectionAmount = resp.ResultList.filter(x => x.credit !== "0.00").sort((a, b) => a.docNo - b.docNo)
            let chargesAmount = resp.ResultList.filter(x => x.debit !== "0.00").sort((a, b) => a.docNo - b.docNo)
            let arrLst = []
            let data = resp.ResultList
            let totalCollected = collectionAmount.reduce((total, currentValue) => total = total + parseFloat(currentValue.credit),0)
            let totalCharges = chargesAmount.reduce((total, currentValue) => total = total + parseFloat(currentValue.debit),0)
            
            let obj = {
                "id" : id,
                "docNo" : docNo,
                "employeeName" : employeeName,
                "chargesAmount" : parseFloat(totalCharges),
                "deductionType" : deductionType,
                "deduction" : deduction,
                "totalBalance" : parseFloat(totalCharges) - parseFloat(totalCollected),
                "remarks" : "",
                "checkbox" : "",
            }
            arrLst.push(obj)
            this.setState({
                isloading   :   false,
            })
        }
        else{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   resp.message,
                fade        :   true
            });
            return
        }
    }
    GetDataGrid = async() => {
       
        this.setState({isloading:true})
        let param = {
        "IpAddress"         :   "0.0.0.0",
        "ClientId"          :   "",
        "UserId"            :   "35954",
        "ProfileId"            :   ""
        };
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Clearance/GetClearance",  param)
        .then(res => {
            let data = res.data.clearance;
            let _dataTmp = res.data.clearance;
            let userinfo = this.state.userinfo.userId            
            let filterData = data.filter( x => x.isClearanceStatus === "0" 
                                          || x.isClearanceStatus === "1" 
                                          || x.isClearanceStatus === "2"
                                          || x.isClearanceStatus === "5"
                                        )
            // console.log("filterData")
            // console.log(filterData)
            if(data.length > 0) {
                this.setState({
                    trainingGrid : filterData.sort((a, b) => new Date(a.dateCreated) > new Date(b.dateCreated) ? -1 : 1),
                    _tmpData: filterData.sort((a, b) => new Date(a.dateCreated) > new Date(b.dateCreated) ? -1 : 1)
                })
            }
            if(data.length === 1){
                this.setState({
                    isDisabledDelete : false
                })
            }else {
                this.setState({
                    isDisabledDelete : true
                })
            }
            data.forEach(itm => {
                if(itm.isClearanceSubmitted === "1"){
                    this.setState({
                        clearanceId : itm.id,
                        isClearanceSubmitted : true,
                        dateCleared : new Date(itm.dateCleared),
                        dateSubmitted : new Date(itm.dateSubmitted),
                        remarks : itm.remarks
                    })
                }else {
                    this.setState({
                        clearanceId : itm.id,
                        isClearanceSubmitted : false,
                        dateCleared : new Date(itm.dateCleared),
                        dateSubmitted : new Date(itm.dateSubmitted),
                        remarks : itm.remarks
                    })
                }
            });
            let _filteredBatch = _dataTmp.filter( x => x.batchNumber !== "")
            
            let _BatchNumberDDL = _filteredBatch.filter((thing, index, self) =>
                index === self.findIndex((t) => (
                    t.batchNumber === thing.batchNumber
                ))
            )
            this.setState({
                filteredBatch    : _BatchNumberDDL,
                isloading   : false,
            });
            
            if(data.status==="0"){
                this.setState(  {
                    isloading       :   false,
                    alerttype       :   "Error!",
                    isshow          :   true,
                    color           :   "danger",
                    message         :   data.message,
                    fade            :   true
                })
            }
        })
        .catch(error=>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
    };
    getStatusClearance = async() =>{
        let statusParams = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
            "Code":"0040"
        };
        

        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetDataReferences", statusParams)
        .then(res => {
            let data = res.data;
            this.setState({
                statusDDL : res.data.dataReferences
            })
                
        })
        .catch(error=>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
    }
    handleSaveClick = async() =>{
       // this.setState({isloading:true})
        let a = this.state.trainingGrid

        let param = {
            "IpAddress" : "0.0.0.0",
            "UserId" : this.state.userinfo.userId,
            "ClientId" : this.state.userinfo.clientId,
            "clearance" : this.state.trainingGrid.filter(x=>x.isModified==="1")
        };
        axios.post(AppConfiguration.Setting().noserapiendpoint + "Clearance/EditClearance",  param)
            .then(res => {
                let data = res.data;
                this.GetDataGrid()
                this.setState({
                    isloading   :   false,
                    alerttype   :   data.status==="1" ? "Success!" : "Error!",
                    isshow      :   true,
                    color       :   data.status==="1" ? "success" : "error",
                    message     :   data.message,
                    fade        :   true,
                    disablebtn  :   true
                });
        })
        .catch(error=>{
            this.setState({
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
        this.setState({trainingGrid : []})
    }
    GridDataModified(oldValue, newValue, id, column) {
        let disable = true
        this.state.trainingGrid.map(function(item,i) {
            if (item.id===id){
                item.isModified = newValue!=oldValue ? "1" : "0"
                disable = newValue!=oldValue ? false : true
            }
        })
        this.setState({disablebtn:disable})
    }
    GridDataModifiedSubCol(oldValue, newValue, id, column) {
        // let disable = true
        this.state.collectionArray.map(function(item,i) {
            if (item.id===id){
                item.isModified = newValue!=oldValue ? "1" : "0"
                item.remarks = newValue
            }
        })
    }
    handleEditClientClick = (row) =>{
        this.setState({openClientModal:true})
        this.child.initialize(row)
    }
    handleEditPositionClick = (row) =>{
        this.child1.initialize(row)
        this.setState({openPositionModal:true})
    }
    handleClientModalClose = (e) =>{
        this.setState({openClientModal:false})
    }
    handlePositionModalClose = (e) =>{
        this.setState({openPositionModal:false})
    }

    GetDisengagementMode = async() => {
        this.setState({isloading:true})
        let param = {
            "IpAddress"         :   "0.0.0.0",
            "UserId"            :   this.state.userinfo.userId,
            "ClientId"          :   this.state.userinfo.clientId,
            "DisengagementId"   :   "",
        };
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetDisengagementMode",  param)
        .then(res => {
            let data = res.data.disengagementMode;
            this.setState({
                reasonDDL: data,
            });

            if(data.status==="0"){
                this.setState(  {
                    isloading       :   false,
                    alerttype       :   "Error!",
                    isshow          :   true,
                    color           :   "danger",
                    message         :   data.message,
                    fade            :   true
                })
            }
        })
        .catch(error=>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
    };
    handleEditClick = (row)=>{
        sessionStorage.setItem("clearanceeditdetails_" , JSON.stringify(row))
        this.setState({navigate: true})
    }

    GridExcellReports = () =>{
        let newGridData = []
        let dataSelected = this.state.trainingGrid.filter( x=> x.isDeleted === "1")
        let _isSelected = []
        if(dataSelected.length === 0){
            _isSelected = this.state.trainingGrid
        }else{
            _isSelected = dataSelected
        }
        for (let i = 0; i < _isSelected.length; i++){
            let obj = {
                "EMPLOYEE NAME"         :   _isSelected[i]["employeeName"],
                "CLIENT NAME"           :   _isSelected[i]["clientName"],
                "BRANCH / LOCATION"     :   _isSelected[i]["locationName"],
                "DATE START"            :   _isSelected[i]["dateStart"],
                "LAST WORKING DATE"     :   _isSelected[i]["lastWorkingDateMember"],
                "CLEARANCE DATE"        :   _isSelected[i]["clearanceDateMember"],
                "STATUS"                :   _isSelected[i]["clearanceStatus"],
                "COMPLETION DATE"       :   _isSelected[i][""],
                "CYCLE DAYS"            :   _isSelected[i]["cycleDays"],
                "FULL NAME"             :   _isSelected[i]["createdByName"],
                "FULL NAME (UPDATEDBY )" :   _isSelected[i]["modifiedByName"],
            }
            newGridData.push(obj)
        }
        this.setState({gridDataExcel : newGridData})
    }

    /* Romel */

    
    
    handleChangeResignationDate = date =>{
        this.setState({ resignationDate: date, isshow:false , })
    }
    handleChangeClearanceDate = date =>{
        this.setState({ clearanceDate: date, isshow:false , })
    }
    handleChangeSubmissionDate = date =>{
        this.setState({ submissionDate: date, isshow:false , })
    }
    handleChangeWorkingDate = date =>{
        this.setState({ workingDate: date, isshow:false , })
    }
    handleChangeWorkingDate = date =>{
        this.setState({ workingDate: date, isshow:false , })
    }
    handleChangeFromLastWorkingDate = date =>{
        this.setState({ fromLastWorkingDate: date, isshow:false , })
    }
    handleChangeToLastWorkingDate = date =>{
        this.setState({ toLastWorkingDate: date, isshow:false , })
    }
    handleChangeToDateReceived = date =>{
        this.setState({ toDateReceived: date, isshow:false , })
    }
    handleChangeFromDateReceived = date =>{
        this.setState({ fromDateReceived: date, isshow:false , })
    }
    handleChangeToClearanceDate = date =>{
        this.setState({ toClearanceDate: date, isshow:false , })
    }
    handleChangeFromClearanceDate = date =>{
        this.setState({ fromClearanceDate: date, isshow:false , })
    }
    handleChangeFromDateCreated = date =>{
        this.setState({ fromDateCreated: date, isshow:false , })
    }
    handleChangeToDateCreated = date =>{
        this.setState({ toDateCreated: date, isshow:false , })
    }

    handleChangeClient = (e) => {
        if(e.length == 0) {
            this.state.clientId     =   ""
            this.state.clientName   =   ""
            return
        }
        this.state.clientId     =   e[0].id
        this.state.clientName   =   e[0].name
         this.setState({
            isshow:false,
            
         })
        this.GetEmployees();
        // this.refBatch.current.clear()
    };

    handleChangedBatchNumber = async(e) =>{
        if(e.length == 0) {
            this.state.batchNumber   =   ""
            this.setState({ trainingGrid : this.state._tmpData, selectedId : [], batchNumber : "" })
        } else{
            this.state.batchNumber   =   e[0].name
            this.setState({
                isshow:false,
            })
        }
        if (e.length !== 0){
            this.Selectbatch(e[0].batchNumber)
        }
    }

    Selectbatch = async(batchSelected) =>{
        // let filterbatch = batchSelected.filter(x => x.batchNumber !== "")
        let trainngBatch = this.state.trainingGrid.filter( x => x.batchNumber === batchSelected)

        let arrLst = []
        let arrid = []
        trainngBatch.forEach(itm =>{
            arrLst.push({
                "batchNumber" : itm.batchNumber,
                "cashCardNumberMember":itm.cashCardNumberMember,
                "clearanceDateMember":itm.clearanceDateMember,
                "clearanceDetails":itm.clearanceDetails,
                "clearanceStatus":itm.clearanceStatus,
                "clientId":itm.clientId,
                "clientName":itm.clientName,
                "createdBy":itm.createdBy,
                "createdByName":itm.createdByName,
                "createdDate":itm.createdDate,
                "cycleDays":itm.cycleDays,
                "dateCreated":itm.dateCreated,
                "dateForwardedToAmt":itm.dateForwardedToAmt,
                "dateOfBirth":itm.dateOfBirth,
                "dateReceivedByAdmin":itm.dateReceivedByAdmin,
                "dateReceivedFromAmt":itm.dateReceivedFromAmt,
                "dateReturnedByARE":itm.dateReturnedByARE,
                "dateReturnedByClient":itm.dateReturnedByClient,
                "dateReturnedByFinance":itm.dateReturnedByFinance,
                "dateReturnedByLRD":itm.dateReturnedByLRD, // cash card mode of payment
                "dateReturnedByRMT":itm.dateReturnedByRMT,
                "dateReturnedByServices":itm.dateReturnedByServices,
                "dateSettle":itm.dateSettle,
                "dateStart":itm.dateStart,
                "dateSubmitted":itm.dateSubmitted,
                "dateSubmittedByLead":itm.dateSubmittedByLead,
                "dateSubmittedByStaff":itm.dateSubmittedByStaff,
                "dateUpdatedByARE":itm.dateUpdatedByARE,
                "dateUpdatedByClient":itm.dateUpdatedByClient,
                "dateUpdatedByFinance": itm.dateUpdatedByFinance,
                "dateUpdatedByLRD": itm.dateUpdatedByLRD,
                "dateUpdatedByRMT":itm.dateUpdatedByRMT,
                "dateUpdatedByServices":itm.dateUpdatedByServices,
                "employeeId": itm.employeeId,
                "employeeNo": itm.employeeNo,
                "employeeName": itm.employeeName,
                "firstName": itm.firstName,
                "hdmfNumber": itm.hdmfNumber,

                "id":itm.id,
                "isCOERequestFormMember":itm.isCOERequestFormMember,
                "isCash":itm.isCash,
                "isCashCardMember":itm.isCashCardMember,

                "isCheckMember":itm.isCheckMember,
                "isCheckedByRmt":itm.isCheckedByRmt,
                "isClearanceStatus":itm.isClearanceStatus,
                "isClearedClient":itm.isClearedClient ,
                "isClearedRmt":itm.isClearedRmt,
                "isDeleted":itm.isDeleted,
                "isEligible":itm.isEligible,

                "isEligibleClient":itm.isEligibleClient,
                "isForwardToARERmt":itm.isForwardToARERmt ,

                "isHDMFNumberMember": itm.isHDMFNumberMember,
                "isMlhuillierMember" :itm.isMlhuillierMember,
                    
                /* ADDITIONAL */
                "isModified": itm.isModified,
                "isNonEligibleClient":itm.isNonEligibleClient,

                "isNotEligible":itm.isNotEligible,
                "isPHICNumberMember":itm.isPHICNumberMember,

                "isPalawanMember":itm.isPalawanMember,
                "isResignationLetterMember":itm.isResignationLetterMember,

                "isRetrunedServicesRmt":itm.isRetrunedServicesRmt,
                "isReturnedRmt":itm.isReturnedRmt,

                "isSSSNumberMember":itm.isSSSNumberMember,
                "isSettleWithDole":itm.isSettleWithDole,

                "isTINNumberMember":itm.isTINNumberMember,
                "isTurnoverChecklistMember":itm.isTurnoverChecklistMember,
                "isTypeOfCashCardMember":itm.isTypeOfCashCardMember,
                "isValidIdMember": itm.isValidIdMember,
                "lastName":itm.lastName,
                "lastWorkingDateMember":itm.lastWorkingDateMember,

                "locationId":itm.locationId,
                "locationName":itm.locationName,
                "middleName":itm.middleName,
                "modifiedBy":itm.modifiedBy,
                "modifiedByName":itm.modifiedByName,
                "modifiedDate":itm.modifiedDate,
                "notesMember":itm.notesMember,
                "paycardTypeIdMember":itm.paycardTypeIdMember,
                "phicNumber":itm.phicNumber,
                "positionId":itm.positionId,
                "positionName":itm.positionName,
                "profileId":itm.profileId,
                "reasonForLeaving"        : itm.reasonForLeaving,
                "reasonForLeavingId"  :  itm.reasonForLeavingId,
                "remarksClient" : itm.remarksClient,
                "remarksLrd" : itm.remarksLrd,
                "remarksMember" : itm.remarksMember,
                "remarksRmt" : itm.remarksRmt,
                "resignationDateMember" : itm.resignationDateMember,
                "returnedByARE" : itm.returnedByARE,
                "returnedByClient" : itm.returnedByClient,
                "returnedByFinance" : itm.returnedByFinance,
                "returnedByLRD" : itm.returnedByLRD,
                "returnedByRMT" : itm.returnedByRMT,
                "returnedByServices" : itm.returnedByServices,
                "rowIndex" : itm.rowIndex,
                "servicesRemarks" : itm.isCash,
                "sssNumber" : itm.sssNumber,
                "submissionDateMember" : itm.submissionDateMember,
                "submittedByLead" : itm.submittedByLead,
                "submittedByStaff" : itm.submittedByStaff,
                "suffixName" : itm.suffixName,
                "timeStamp" : itm.timeStamp,
                "tinNumber" : itm.tinNumber,
                "typeOfCashCardMember" : itm.typeOfCashCardMember,
                "updatedByARE" : itm.updatedByARE,
                "updatedByClient" : itm.updatedByClient,
                "updatedByFinance" : itm.updatedByFinance,
                "updatedByLRD" : itm.updatedByLRD,
                "updatedByRMT" : itm.updatedByRMT,
                "updatedByServices" : itm.updatedByServices,
                "validateByRmt" : itm.validateByRmt,
                "paramountId" : itm.paramountId,
                "isSelected" : "1",
                "isDeleted" : "1",
                "totalBalances" : "",
                "atd" : "",
                "submitted" : "",
                "netPay" : "",
                "grossPay" : "",
                "inclusions" : "",
                "thirteenthMonth" : "",
                "sil" : "",
                "incentives" : "",
                "lastSalary" : "",
                "servicesAttachments" :itm.servicesAttachments,
                "closingRemarks" : itm.closingRemarks
                /* END */
            })
            arrid.push(itm.id)
        })
        this.setState({
            dataListArr : arrLst,
        })
    }

    handleClickTest = () => {
        let arrLst = []
        this.state.dataListArr.forEach(x => {
            let listObj = {
                "client" : x.client,
                "employeeName" : x.employeeName,
                "deductionDetails" : this.state.collectionArray,
            }
            arrLst.push(listObj)
        })
    }

    handleChangeEmployee = (e) => {
        
        if(e.length == 0) {
            this.state.employeeId   =   ""
            this.state.profileId    =   ""
            this.state.client       =   ""
            this.state.lastName     =   ""
            this.state.firstName    =   ""
            this.state.middleName   =   ""
            this.state.dateOfBirth  =   ""
            this.state.positionName =   ""
            this.state.contractDateStart  =   ""
            this.state.phicNo = ""
            this.state.sssNo = ""
            this.state.tinNo = ""
            this.state.pagibigNo = ""
            // return
        } else {

            this.state.employeeId   =   e[0].id
            this.state.profileId    =   e[0].profileId
            this.state.client       =   e[0].clientName
            this.state.lastName     =   e[0].lastName
            this.state.firstName    =   e[0].firstName
            this.state.middleName   =   e[0].middleName
            this.state.dateOfBirth  =   e[0].dateOfBirth
            this.state.positionName =   e[0].position
            this.state.locationName =   e[0].locationName
            this.state.contractDateStart  =   e[0].contractDateStart
            this.state.phicNo       =   e[0].phicNumber
            this.state.sssNo        =   e[0].sssNumber
            this.state.tinNo        =   e[0].tinNumber
            this.state.pagibigNo    =   e[0].hdmfNumber
        }
        this.setState({  })
        this.getEmployeeClearance();
    };

    getEmployeeClearance = async() =>{
        let param = {
            "IpAddress"             :   "0.0.0.0",
            "UserId"                :   this.state.userinfo.userId,
            "ClientId"              :   this.state.userinfo.clientId,
            "ProfileId"             :   this.state.userinfo.profileId,
            "DisengagementModeId"   :   this.state.disengagementModeId,
            "ClearanceDate"         :   this.state.clearanceDate,
            "ResignationDate"       :   this.state.resignationDate,
            "SubmissionDate"        :   this.state.submissionDate,
            "LastWorkingDate"       :   this.state.workingDate,
            "ReasonForLeaving"      :   this.state.reason
        };
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Clearance/GetClearance",  param)
        .then(res => {
            let data = res.data.clearance;

            if(data.status==="0"){
                this.setState(  {
                    isloading       :   false,
                    alerttype       :   "Error!",
                    isshow          :   true,
                    color           :   "danger",
                    message         :   data.message,
                    fade            :   true
                })
            }
        })
        .catch(error=>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
    };

    handleChangedReason = (e) => {
        if (e.length === 0){
            this.setState({ reason : "" })
        } else {
            this.setState({ reason : e[0].name })
        }
    }

    handleClickRefresh = () =>{
        window.location.reload(true)
    }

    handleClickSubmit = async() => {
        // // console.log('this.state.userinfo.userId')
        // // console.log(this.state.userinfo.userId)
        this.setState({isloading:true})
        let date = (this.state.datenow) ? moment(this.state.datenow).format('MM/DD/YYYY') : ""
        let arrLst = []
        let dedArr = []

        let _fltrAttchment = this.state.trainingGrid.filter( x => x.isDeleted === "1")

        if (_fltrAttchment.length === 0) {
            this.setState({
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :    "Please select atleast 1 clearance to process.",
                fade            :   true
            })
            return
        }
        let check_ = false
        let _empName = ""
        _fltrAttchment.map(function(itm,i){
            if (itm.isClearanceStatus === "5"){
                if (itm.servicesAttachments.length === 0) {
                     return check_ = true , _empName = itm.employeeName
                }
            } 
        })
        if ( check_ === true ){
            this.setState({
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :    "Attachment of scanned clearance is required to" + "  " + _empName,
                fade            :   true
            })
            return
        }

        let testArry = _fltrAttchment
        let arrayUniqueByKey = [...new Map(testArry.map(item =>
            [item['reasonForLeaving'], item])).values()];
        if(arrayUniqueByKey.filter(x=>x.reasonForLeaving==="DOLE CASES").length > 1){
            this.setState({
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "Error: Submitting multiple reasons for leaving. Please check.",
                fade            :    true
            })
            return
        }
        else{
            // alert("no dole selected")
            console.log(" no dole selected")
        }

        let _userId = this.state.userinfo.userId
        let userName = this.state.userinfo.fullName
        let setValidation = false

        let a = this.state.userinfo.userId
        let b = this.state.userinfo.firstName
        let c = this.state.userinfo.employeeId

        _fltrAttchment.map(function(itm,i){
        // _fltrAttchment.forEach((itm,i)=>{
            let ddctions = typeof itm.deductions !== "undefined"
            if (typeof itm.deductions === "undefined"){
                ddctions = []
            } 
            else {
                ddctions.forEach(x => {
                    let dedObj = {
                        "EmployeeId" : x.employeeNo,
                        "DeductiontypeId" : "0",
                        "DeductionType" : x.deductionType,
                        "DeductionId" : "0",
                        "Deduction" : x.deduction,
                        "Balance" : x.totalBalance,
                    }
                    dedArr.push(dedObj)
                })
            }


            let _clearanceStatus = ""

            if (itm.isClearanceStatus === "2"){
                if (itm.reasonForLeaving === "DOLE CASES"){
                    if (_userId === "34024"){
                        _clearanceStatus = "4"
                        setValidation = false
                    } 
                    else {
                        setValidation = true
                    }
                } 
                else {
                    if (itm.reasonForLeaving !== "DOLE CASES"){
                        if(_userId === "87961" ||_userId === "34004" || _userId === "89654" || _userId === "89654"  || _userId === "39965" || _userId === "91250" ||_userId === "89692" ||  _userId === "34007" || _userId === "34006"){
                            setValidation = false
                            // console.log("if")
                            _clearanceStatus = "4"
                        }
                        else{
                            setValidation = true
                            _clearanceStatus = "4"
                        }
                    }
                    else{
                        _clearanceStatus = "4"
                        setValidation = false
                    }
                }
            } 
            else {
                setValidation = false
                _clearanceStatus = String(parseFloat(itm.isClearanceStatus) + 1)
            }

            arrLst.push({
                "Id" : itm.id,
                "ClientId":itm.clientId,
                "ProfileId":itm.profileId,
                "EmployeeId":itm.employeeId,
                "PayPeriodId":itm.payPeriodId,
                "DateCreated":(itm.dateCreated) ? moment(itm.dateCreated).format('MM/DD/YYYY') : "",
                "ClearanceDateMember":(itm.clearanceDateMember) ? moment(itm.clearanceDateMember).format('MM/DD/YYYY') : "",
                "ResignationDateMember":(itm.resignationDateMember) ? moment(itm.resignationDateMember).format('MM/DD/YYYY') : "",
                "SubmissionDateMember":(itm.submissionDateMember) ? moment(itm.submissionDateMember).format('MM/DD/YYYY') : "",
                "LastWorkingDateMember":(itm.lastWorkingDateMember) ? moment(itm.lastWorkingDateMember).format('MM/DD/YYYY') : "",
                "DateSubmitted":(itm.dateSubmitted) ? moment(itm.dateSubmitted).format('MM/DD/YYYY') : "",
                "IsSSSNumberMember":itm.isSSSNumberMember,
                "IsTINNumberMember":itm.isTINNumberMember,
                "IsPHICNumberMember":itm.isPHICNumberMember,
                "IsHDMFNumberMember":itm.isHDMFNumberMember,
                "IsCashCardMember":itm.isCashCardMember,
                "IsValidIdMember":itm.isValidIdMember,
                "IsResignationLetterMember":itm.isResignationLetterMember,
                "IsTurnoverChecklistMember":itm.isTurnoverChecklistMember,
                "IsCOERequestFormMember":itm.isCOERequestFormMember,
                "IsTypeOfCashCardMember":itm.isTypeOfCashCardMember, // cash card mode of payment
                "PaycardTypeIdMember":itm.paycardTypeIdMember,
                "TypeOfCashCardMember":itm.typeOfCashCardMember,
                "CashCardNumberMember":itm.cashCardNumberMember,
                "IsCheckMember":itm.isCheckMember,
                "IsMlhuillierMember":itm.isMlhuillierMember,
                "IsPalawanMember":itm.isPalawanMember,
                "RemarksMember":itm.remarksMember,
                "NotesMember":itm.notesMember,
                "ReasonForLeaving":itm.reasonForLeaving,
                "DateForwardedToAmt": itm.dateForwardedToAmt,
                "DateReceivedFromAmt": itm.dateReceivedFromAmt,
                "IsEligible":itm.isEligible,
                "IsNotEligible":itm.isNotEligible,
                "SssNumber": itm.sssNumber,
                "TinNumber": itm.tinNumber,
                "PhicNumber": itm.phicNumber,
                "HdmfNumber": itm.hdmfNumber,
                "IsClearedClient":itm.isClearedClient,
                "IsEligibleClient":itm.isEligibleClient,
                "IsNonEligibleClient":itm.isNonEligibleClient,
                "RemarksClient":itm.remarksClient,

                "IsClearedRmt":itm.isNonEligibleClient,
                "IsReturnedRmt":itm.isNonEligibleClient,
                "ValidateByRmt":itm.isNonEligibleClient,
                "IsForwardToARERmt":itm.isNonEligibleClient ,
                "IsRetrunedServicesRmt":itm.isNonEligibleClient,
                "IsCheckedByRmt":itm.isCheckedByRmt,
                "RemarksRmt":itm.remarksRmt,

                "RemarksLrd":itm.remarksLrd,
                "ServicesRemarks":itm.servicesRemarks ,

                "IsClearanceStatus": _clearanceStatus,
                "clearanceDetails" :itm.clearanceDetails,
                    
                /* ADDITIONAL */
                "DateUpdatedByClient": date,
                "UpdatedByClient":_userId,

                "DateUpdatedByLRD":date,
                "UpdatedByLRD":_userId,

                "DateUpdatedByRMT":date,
                "UpdatedByRMT":_userId,

                "DateUpdatedByServices": itm.isClearanceStatus==="5" ? date : "",
                "UpdatedByServices":_userId,

                "DateUpdatedByARE":"",
                "UpdatedByARE":"",

                "DateUpdatedByFinance":date,
                "UpdatedByFinance":_userId,
                "DateSubmittedByStaff":date,
                "SubmittedByStaff": _userId,
                "DateSubmittedByLead":date,
                "SubmittedByLead":_userId,

                "DateReturnedByClient":itm.dateReturnedByClient,
                "ReturnedByClient":_userId,
                "DateReturnedByLRD":itm.dateReturnedByLRD,
                "ReturnedByLRD":_userId,
                "DateReturnedByRMT":itm.dateReturnedByRMT,
                "ReturnedByRMT":_userId,
                "DateReturnedByServices":itm.dateReturnedByServices,
                "ReturnedByServices":_userId,
                "DateReturnedByARE":itm.dateReturnedByARE,
                "ReturnedByARE":_userId,
                "DateReturnedByFinance":itm.dateReturnedByFinance,
                "ReturnedByFinance":_userId,
                
                "DateSettle"        : itm.dateSettle,
                "IsSettleWithDole"  :  itm.isSettleWithDole,

                "DateReceivedByAdmin" : itm.dateReceivedByAdmin,
                "IsCash" : itm.isCash,
                "BatchNumber" : itm.batchNumber,
                "servicesAttachments" : itm.servicesAttachments,
                "clearanceDeductions":dedArr.length === 0? [] : dedArr,

                
                // 21/07/2023
                "ParamountId" : itm.paramountId === "" ? "0" : itm.paramountId,
                "LastSalary" : itm.lastSalary,
                "Incentives" : itm.incentives,
                "Sil" : itm.sil,
                "ThirteenthMonth" : itm.thirteenthMonth,
                "Inclusions" : itm.inclusions,
                "GrossPay" : itm.grossPay,
                "NetPay" : itm.netPay,
                "totalBalance" : itm.totalBalance,
                "PeriodCovered" : itm.periodCovered,
                "IsModifiedByARE" : "0",
                "SeparationPay" : itm.separationPay,
                "ReviewStatus" : "0",
                "DateReturnedByPayroll" : "",
                "AreRemarks" : itm.areRemarks,
                "PayrollRemarks" : itm.payrollRemarks,
                "FinanceRemarks" : itm.financeRemarks,
                "IsClearedSubmit" : "0",
                "IsGenerated" : itm.isGenerated,
                "IsFinalized" : itm.isFinalized,
                // "ContractDateStart"  : typeof itm.contractDateStart === "undefined" ? "" : itm.contractDateStart,
                // "ContractDateEnd"  : typeof itm.contractDateEnd === "undefined" ? "" : itm.contractDateEnd,
                "DateResigned" : typeof itm.dateResigned === "undefined" ? "" : itm.dateResigned,
                "DateHired" : typeof itm.dateHired === "undefined" ? "" : itm.dateHired,
                'BatchPayroll' : "",
                "Adjustment" : typeof itm.adjustment === "undefined" ? "" : itm.adjustment,
                "OtherPayroll" : typeof itm.otherPayroll === "undefined" ? "" : itm.otherPayroll,
                "TotalInclusion" : itm.totalInclusion,
                "IsParent" : itm.isParent,
                "IsChild" : itm.isChild,
                "ParentId" : itm.parentId,
                
                "DateSubmittedToPayroll" : itm.dateSubmittedToPayroll,
                "IsAre" : itm.isAre,
                "IsPayroll" : itm.isPayroll,
                "LastModifiedBy" : userName,
                /* END */
            
            })
        })
        
        if (setValidation === true) {
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "access denied!..",
                fade        :   true
            })
        } 
        else {
            let param = {
                "IpAddress"             :"0.0.0.0",
                "UserId"                :this.state.userinfo.userId,
                "ClientId"              :this.state.userinfo.clientId,
                "clearance"             :arrLst
            }
            console.log("param")
            console.log(param)
    
            axios
            .post(AppConfiguration.Setting().noserapiendpoint + "Clearance/EditClearance",  param)
            .then(res => {
                let data = res.data;
                this.setState({
                    isloading   :   false,
                    alerttype   :   res.data.status=="1" ? "Success!" : "!Error",
                    isshow      :   true,
                    color       :   res.data.status=="1" ? "success" : "danger",
                    message     :   data.message,
                    fade        :   true
                });
                  this.GetDataGrid();
            })
            .catch(error=>{
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                    fade        :   true
                })
            })
        }
    }

    handleClickReturn = async(statusId) => {
        this.setState({isloading:true})
        let date = (this.state.datenow) ? moment(this.state.datenow).format('MM/DD/YYYY') : ""
        let arrLst = []
        let dedArr = []

        let _slctdData = this.state.trainingGrid.filter( x => x.isDeleted === "1")

        let userName = this.state.userinfo.fullName
        if (_slctdData.length === "0") {
            this.setState({
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :    "Please select atleast 1 clearance to process.",
                fade            :   true
            })
            return
        }

        _slctdData.forEach(itm => {
            let _clearanceStatus = ""
            if (itm.isClearanceStatus === "2"){
                if (itm.reasonForLeaving === "Dole Cases"){
                    _clearanceStatus = "0"
                }else {
                    _clearanceStatus = String(parseFloat(itm.isClearanceStatus) - 1)
                }
            }else{
                _clearanceStatus = String(parseFloat(itm.isClearanceStatus) - 1)
            }
            arrLst.push({
                "Id" : itm.id,
                "ClientId":itm.clientId,
                "ProfileId":itm.profileId,
                "EmployeeId":itm.employeeId,
                "PayPeriodId":itm.payPeriodId,
                "DateCreated":(itm.dateCreated) ? moment(itm.dateCreated).format('MM/DD/YYYY') : "",
                "ClearanceDateMember":(itm.clearanceDateMember) ? moment(itm.clearanceDateMember).format('MM/DD/YYYY') : "",
                "ResignationDateMember":(itm.resignationDateMember) ? moment(itm.resignationDateMember).format('MM/DD/YYYY') : "",
                "SubmissionDateMember":(itm.submissionDateMember) ? moment(itm.submissionDateMember).format('MM/DD/YYYY') : "",
                "LastWorkingDateMember":(itm.lastWorkingDateMember) ? moment(itm.lastWorkingDateMember).format('MM/DD/YYYY') : "",
                "DateSubmitted":(itm.dateSubmitted) ? moment(itm.dateSubmitted).format('MM/DD/YYYY') : "",
                "IsSSSNumberMember":itm.isSSSNumberMember,
                "IsTINNumberMember":itm.isTINNumberMember,
                "IsPHICNumberMember":itm.isPHICNumberMember,
                "IsHDMFNumberMember":itm.isHDMFNumberMember,
                "IsCashCardMember":itm.isCashCardMember,
                "IsValidIdMember":itm.isValidIdMember,
                "IsResignationLetterMember":itm.isResignationLetterMember,
                "IsTurnoverChecklistMember":itm.isTurnoverChecklistMember,
                "IsCOERequestFormMember":itm.isCOERequestFormMember,
                "IsTypeOfCashCardMember":itm.isTypeOfCashCardMember, // cash card mode of payment
                "PaycardTypeIdMember":itm.paycardTypeIdMember,
                "TypeOfCashCardMember":itm.typeOfCashCardMember,
                "CashCardNumberMember":itm.cashCardNumberMember,
                "IsCheckMember":itm.isCheckMember,
                "IsMlhuillierMember":itm.isMlhuillierMember,
                "IsPalawanMember":itm.isPalawanMember,
                "RemarksMember":itm.remarksMember,
                "NotesMember":itm.notesMember,
                "ReasonForLeaving":itm.reasonForLeaving,
                "DateForwardedToAmt": itm.dateForwardedToAmt,
                "DateReceivedFromAmt": itm.dateReceivedFromAmt,
                "IsEligible":itm.isEligible,
                "IsNotEligible":itm.isNotEligible,
                "SssNumber": itm.sssNumber,
                "TinNumber": itm.tinNumber,
                "PhicNumber": itm.phicNumber,
                "HdmfNumber": itm.hdmfNumber,

                "IsClearedClient":itm.isClearedClient,
                "IsEligibleClient":itm.isEligibleClient,
                "IsNonEligibleClient":itm.isNonEligibleClient,
                "RemarksClient":itm.remarksClient,

                "IsClearedRmt":itm.isNonEligibleClient,
                "IsReturnedRmt":itm.isNonEligibleClient,
                "ValidateByRmt":itm.isNonEligibleClient,
                "IsForwardToARERmt":itm.isNonEligibleClient ,
                "IsRetrunedServicesRmt":itm.isNonEligibleClient,
                "IsCheckedByRmt":itm.isNonEligibleClient,
                "RemarksRmt":itm.remarksRmt,

                "RemarksLrd":itm.remarksLrd,
                "ServicesRemarks":itm.servicesRemarks ,

                "IsClearanceStatus": _clearanceStatus,
                "clearanceDetails" :itm.clearanceDetails,
                    
                /* ADDITIONAL */
                "DateUpdatedByClient": date,
                "UpdatedByClient":this.state.userinfo.userId,

                "DateUpdatedByLRD":date,
                "UpdatedByLRD":this.state.userinfo.userId,

                "DateUpdatedByRMT":date,
                "UpdatedByRMT":this.state.userinfo.userId,

                "DateUpdatedByServices":date,
                "UpdatedByServices":this.state.userinfo.userId,

                "DateUpdatedByARE":date,
                "UpdatedByARE":this.state.userinfo.userId,

                "DateUpdatedByFinance":date,
                "UpdatedByFinance":this.state.userinfo.userId,
                "DateSubmittedByStaff":date,
                "SubmittedByStaff": this.state.userinfo.userId,
                "DateSubmittedByLead":date,
                "SubmittedByLead":this.state.userinfo.userId,

                "DateReturnedByClient":itm.dateReturnedByClient,
                "ReturnedByClient":this.state.userinfo.userId,
                "DateReturnedByLRD":itm.dateReturnedByLRD,
                "ReturnedByLRD":this.state.userinfo.userId,
                "DateReturnedByRMT":itm.dateReturnedByRMT,
                "ReturnedByRMT":this.state.userinfo.userId,
                "DateReturnedByServices":itm.dateReturnedByServices,
                "ReturnedByServices":this.state.userinfo.userId,
                "DateReturnedByARE":itm.dateReturnedByARE,
                "ReturnedByARE":this.state.userinfo.userId,
                "DateReturnedByFinance":itm.dateReturnedByFinance,
                "ReturnedByFinance":this.state.userinfo.userId,
                
                "DateSettle"        : itm.dateSettle,
                "IsSettleWithDole"  :  itm.isSettleWithDole,

                "DateReceivedByAdmin" : itm.dateReceivedByAdmin,
                "IsCash" : itm.isCash,
                "BatchNumber" : itm.batchNumber,
                "servicesAttachments" : itm.servicesAttachments,
                "clearanceDeductions":itm.clearanceDeductions,

                
                // 21/07/2023
                "ParamountId" : itm.paramountId === "" ? "0" : itm.paramountId,
                "LastSalary" : itm.lastSalary,
                "Incentives" : itm.incentives,
                "Sil" : itm.sil,
                "ThirteenthMonth" : itm.thirteenthMonth,
                "Inclusions" : itm.inclusions,
                "GrossPay" : itm.grossPay,
                "NetPay" : itm.netPay,
                "totalBalances" : itm.totalBalances,
                "PeriodCovered" : itm.periodCovered,
                "IsModifiedByARE" : "0",
                "SeparationPay" : itm.separationPay,
                "ReviewStatus" : "0",
                "DateReturnedByPayroll" : "",
                "AreRemarks" : itm.areRemarks,
                "PayrollRemarks" : itm.payrollRemarks,
                "FinanceRemarks" : itm.financeRemarks,
                "IsClearedSubmit" : "0",
                "IsGenerated" : itm.isGenerated,
                "IsFinalized" : itm.isFinalized,
                // "ContractDateStart"  : typeof itm.contractDateStart === "undefined" ? "" : itm.contractDateStart,
                // "ContractDateEnd"  : typeof itm.contractDateEnd === "undefined" ? "" : itm.contractDateEnd,
                "DateResigned" : typeof itm.dateResigned === "undefined" ? "" : itm.dateResigned,
                "DateHired" : typeof itm.dateHired === "undefined" ? "" : itm.dateHired,
                'BatchPayroll' : "",
                "Adjustment" : typeof itm.adjustment === "undefined" ? "" : itm.adjustment,
                "OtherPayroll" : typeof itm.otherPayroll === "undefined" ? "" : itm.otherPayroll,
                "TotalInclusion" : itm.totalInclusion,
                "IsParent" : itm.isParent,
                "IsChild" : itm.isChild,
                "ParentId" : itm.parentId,
                
                "DateSubmittedToPayroll" : itm.dateSubmittedToPayroll,
                "IsAre" : itm.isAre,
                "IsPayroll" : itm.isPayroll,
                "LastModifiedBy" : userName,
                /* END */
            
            })
            this.setState({ profileId : itm.profileId })
        })
        let param = {
            "IpAddress"             :"0.0.0.0",
            "UserId"                :this.state.userinfo.userId,
            "ClientId"              :this.state.userinfo.clientId,
            "clearance"             :arrLst
        }

        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Clearance/EditClearance",  param)
        .then(res => {
            let data = res.data;
            this.setState({
                isloading   :   false,
                alerttype   :   res.data.status=="1" ? "Success!" : "!Error",
                isshow      :   true,
                color       :   res.data.status=="1" ? "success" : "danger",
                message     :   data.message,
                fade        :   true,
                payrollRemarks : "",
            });
            this.GetDataGrid();
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
    }
    
    RefreshPage = async() => {
        this.setState({isloading:true})
        let param = {
            "IpAddress"             :   "0.0.0.0",
            "UserId"                :   this.state.userinfo.userId,
            "ClientId"              :   this.state.clientId,
            "ProfileId"             :   this.state.profileId,
            "ClearanceStatusId"     :   "",
            "FromDateCreated"       :   "",
            "ToDateCreated"         :   "",
            "FromClearanceDate"     :   "",
            "ToClearanceDate"       :   "",
            "FromDateReceive"       :   "",
            "ToDateReceive"         :   "",
            "FromLastWorkingDate"   :   "",
            "ToLastWorkingDate"     :   "",
        };
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Clearance/GetClearance",  param)
        .then(res => {
            let data = res.data.clearance;
            this.setState({
                isloading   : false,
            });
            data.forEach(itm => {
                this.setState({
                    clearanceId : itm.id,
                    clientId : itm.clientId,
                    profileId : itm.profileId,
                    employeeId : itm.employeeId,
                    dateCreated : itm.dateCreated,
                    dateSubmitted : itm.dateSubmitted,
                    clearanceStatus : itm.clearanceStatus,
                    isClearanceStatus : itm.isClearanceStatus,
                    memberNotes : itm.notesMember,
                    cashCardType : itm.typeOfCashCardMember,
                    accountNumber : itm.cashCardNumberMember,
                    memberRemarks : itm.remarksMember,
                    reason : itm.reasonForLeaving,
                    lrdRemarks : itm.remarksLrd,
                    rmtRemarks : itm.remarksRmt,
                    clientTabDDL : itm.clearanceDetails,
                    lrdTabDDL : itm.clearanceDetails,
                    rmtDDL:itm.clearanceDetails,
                    serviceTabDDL:itm.clearanceDetails,
                })
                if(itm.resignationDateMember === ""){
                    this.setState({
                        resignationDate : ""
                    })
                }else {
                    this.setState({
                        resignationDate : new Date(itm.resignationDateMember)
                    })
                }
                if(itm.clearanceDateMember === ""){
                    this.setState({
                        clearanceDate : ""
                    })
                }else {
                    this.setState({
                        clearanceDate : new Date(itm.clearanceDateMember)
                    })
                }
                if(itm.submissionDateMember === ""){
                    this.setState({
                        submissionDate : ""
                    })
                }else {
                    this.setState({
                        submissionDate : new Date(itm.submissionDateMember)
                    })
                }
                if(itm.lastWorkingDateMember === ""){
                    this.setState({
                        workingDate : ""
                    })
                }else {
                    this.setState({
                        workingDate : new Date(itm.lastWorkingDateMember)
                    })
                }
                if(itm.isCashCardMember === "1"){
                    this.setState({
                        isCheckCashCard : true
                    })
                }else {
                    this.setState({
                        isCheckCashCard : false
                    })
                }
                if(itm.isValidIdMember === "1"){
                    this.setState({
                        isCheckValidId : true
                    })
                }else {
                    this.setState({
                        isCheckValidId : false
                    })
                }
                if(itm.isResignationLetterMember === "1"){
                    this.setState({
                        isCheckResignation : true
                    })
                }else {
                    this.setState({
                        isCheckResignation : false
                    })
                }
                if(itm.isTurnoverChecklistMember === "1"){
                    this.setState({
                        isCheckTurnover : true
                    })
                }else {
                    this.setState({
                        isCheckTurnover : false
                    })
                }
                if(itm.isCOERequestFormMember === "1"){
                    this.setState({
                        isCheckRequestForm : true
                    })
                }else {
                    this.setState({
                        isCheckRequestForm : false
                    })
                }
                if(itm.isTypeOfCashCardMember === "1"){
                    this.setState({
                        isRadioCashcard : true,
                        cashCardField : false,
                    })
                }else {
                    this.setState({
                        isRadioCashcard : false,
                        cashCardField : true,
                    })
                }
                if(itm.isCheckMember === "1"){
                    this.setState({
                        isRadioCheck : true,
                    })
                }else {
                    this.setState({
                        isRadioCheck : false,
                    })
                }
                if(itm.isMlhuillierMember === "1"){
                    this.setState({
                        isRadioMlhuillier : true,
                    })
                }else {
                    this.setState({
                        isRadioMlhuillier : false,
                    })
                }
                if(itm.isMlhuillierMember === "1"){
                    this.setState({
                        isRadioMlhuillier : true,
                    })
                }else {
                    this.setState({
                        isRadioMlhuillier : false,
                    })
                }
                if(itm.isPalawanMember === "1"){
                    this.setState({
                        isRadioPalawan : true,
                    })
                }else {
                    this.setState({
                        isRadioPalawan : false,
                    })
                }
            });
            if(data.status==="0"){
                this.setState(  {
                    isloading       :   false,
                    alerttype       :   "Error!",
                    isshow          :   true,
                    color           :   "danger",
                    message         :   data.message,
                    fade            :   true
                })
            }
        })
        .catch(error=>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
    }					
    
    
    handleChangeFromClearanceDate = date =>{
        this.setState({ fromClearanceDate: date, isshow:false})
    }
    handleChangeToClearanceDate = date =>{
        this.setState({ toClearanceDate: date, isshow:false})
    }
    
    handleChangeFromDateReceived = date =>{
        this.setState({ fromDateReceived: date, isshow:false})
    }
    handleChangeToDateReceived = date =>{
        this.setState({ toDateReceived: date, isshow:false})
    }
    
    handleChangeFromLastWorkingDate = date =>{
        this.setState({ fromLastWorkingDate: date, isshow:false})
    }
    handleChangeToLastWorkingDate = date =>{
        this.setState({ toLastWorkingDate: date, isshow:false})
    }
    handleChangeStatus = (e) =>{
        if (e.length === 0){
            this.state.statusId = ""
        } else {
            this.state.statusId = e[0].id
        }
    }

    render() {
        var nf = new Intl.NumberFormat();
        let { navigate } = this.state;
        if(navigate){
            return <Redirect to="/clearance" push={true}/>
        };
        
        let { SearchBar, ClearSearchButton } = Search;
        let trainingColumn = [
            {
                dataField: 'batchNumber',
                text: 'BATCH NUMBER',
                editable: false,
                sort: true,
                headerStyle : () => {
                    return { width  : "7.69%" };
                }
            },
            {
                dataField: 'clientName',
                text: 'CLIENT',
                
                editable: false,
                headerStyle : () => {
                    return { width  : "7.69%" };
                }
            },
            {
                dataField: 'employeeName',
                text: 'EMPLOYEE',
                
                editable: false,
                headerStyle : () => {
                    return { width  : "7.69%" };
                }
            },
            {
                dataField: 'locationName',
                text: 'BRANCH / LOCATION',
                
                editable: false,
                headerStyle : () => {
                    return { width  : "7.69%" };
                }
            },
            {
                dataField: 'dateStart',
                text: 'START DATE',
                editable: false,
                headerStyle : () => {
                    return { width  : "7.69%", textAlign : "center" };
                },
                style:{textAlign : "center"}
            },
            {
                dataField: 'lastWorkingDateMember',
                text: 'LAST WORKING DATE',
                editable: false,
                headerStyle : () => {
                    return { width  : "7.69%",  textAlign : "center" };
                },
                style:{textAlign : "center"}
            },
            {
                dataField: 'reasonForLeaving',
                text: 'REASON',
                editable: false,
                formatter   :   (cell, row, isSelect) => {
                    if(row.reasonForLeaving === "DOLE CASES"){
                        return (
                            <Form.Label column sm="12" style={{fontWeight : "bold", fontSize : "9px", marginTop : "2%", color : "red"}}>
                                DOLE CASES
                            </Form.Label>   
                        )
                    } else {
                        return (
                            <Form.Label column sm="12" style={{fontWeight : "bold", fontSize : "9px", marginTop : "2%"}}>
                                {row.reasonForLeaving}
                            </Form.Label>
                        )
                    }
                        
                },
                headerStyle : () => {
                    return { width  : "5%", textAlign : "center" };
                },
                searchable: false
            },
            {
                dataField: 'clearanceDateMember',
                text: 'clearance Date',
                editable: false,
                headerStyle : () => {
                    return { width  : "7.69%", textAlign : "center" };
                },
                style:{textAlign : "center"}
            },
            {
                dataField: 'clearanceStatus',
                text: 'STATUS',
                editable: false,
                headerStyle : () => {
                    return { width  : "7.69%" };
                },
            },
            {
                dataField: '',
                text: 'COMPLETION DATE',
                editable: false,
                headerStyle : () => {
                    return { width  : "7.69%",  textAlign : "center" };
                },
                style:{textAlign : "center"}
            },
            {
                dataField: 'cycleDays',
                text: 'CYCLE DAYS',
                editable: false,
                headerStyle : () => {
                    return { width  : "7.69%" };
                }
            },
            {
                dataField: 'createdByName',
                text: 'CREATED BY',
                editable: false,
                headerStyle : () => {
                    return { width  : "7.69%" };
                }
            },
            {
                dataField: 'modifiedByName',
                text: 'MODIFIED BY',
                editable: false,
                headerStyle : () => {
                    return { width  : "7.69%" }
                }
            },      
            {
                dataField: 'atd',
                text: 'ATD',
                editable: false,
                formatter   :   (cell, row, isSelect) => {
                    if(row.isCheckedByRmt === "0"){
                        return (
                            <Form.Label column sm="12" style={{fontWeight : "bold", fontSize : "9px", marginTop : "3%", color : "black"}}>
                                --
                            </Form.Label>   
                        )
                    } 
                    else if (row.isCheckedByRmt === "1") {
                        return (
                            <Form.Label column sm="12" style={{fontWeight : "bold", fontSize : "9px", marginTop : "3%", color : "red"}}>
                                NO
                            </Form.Label>   
                        )
                    } 
                    else if (row.isCheckedByRmt === "2") {
                        return (
                            <Form.Label column sm="12" style={{fontWeight : "bold", fontSize : "9px", marginTop : "3%"}}>
                                YES
                            </Form.Label>  

                        )
                    }  
                },
                headerStyle : () => {
                    return { width  : "7.69%", textAlign : "center" };
                },
                style:{textAlign : "center"}
            },
        ]

        let selectRow = {
            mode: 'checkbox',
            clickToSelectAndEditCell: false,
            selected : this.state.selectedId,
            onSelect: (row, isSelect, rowIndex, e) => {
                let disable = true
                this.state.trainingGrid.map(function(item,i){
                    // let disabled = true
                    if(item.id===row.id)
                    {
                        item.isDeleted = isSelect ? "1" : "0"
                        // console.log(item)
                    }
                })
            },
            onSelectAll: (isSelect, row) => {
                let isDisable = true
                this.state.trainingGrid.map(function(itm){
                        itm.isDeleted = isSelect ? "1" : "0"
                })
            }
        };
        
        let subCols = [
            {
                dataField: 'deductionType',
                text: 'Deduction Type',
                editable : false,
                headerStyle: () => {
                    return { width: "20%"};
                },
                style:{width: "20%"},
            },
            {
                dataField: 'deduction',
                text: 'Deduction',
                editable : false,
                headerStyle: () => {
                    return { width: "20%"};
                },
                style:{width: "20%"},
            },
            {
                dataField: 'chargesAmount',
                text: 'Total Charges',
                editable : false,
                headerStyle: () => {
                    return { textAlign:'right', width: "20%"};
                },
                style:{textAlign:'right', width: "20%"},
            },
            {
                dataField: 'collectionAmount',
                text: 'Total Collection',
                editable : false,
                headerStyle: () => {
                    return { textAlign:'right', width: "20%"};
                },
                style:{textAlign:'right', width: "20%"},
            },
            {
                dataField: 'totalBalance',
                text: 'Total Balance',
                editable : false,
                headerStyle: () => {
                    return { textAlign:'right', width: "20%"};
                },
                style:{textAlign:'right', width: "10%"},
                //formatter: amountFormatter
            },
            {
                dataField: 'remarks',
                text: 'REMARKS',
                editable : true,
                headerStyle: () => {
                    return { width: "20%"};
                },
                style:{textAlign:'right', width: "20%"},
            },
            {
                dataField: 'checkbox',
                text: 'REVIEWED',
                // editable : true,
                editor: {
                    type: Type.CHECKBOX,
                    value: 'Y:N'
                },
                headerStyle: () => {
                    return { width: "10%",textAlign:'center'};
                },
                style:{textAlign:'center', width: "10%"},
            },
        ]

        let selectRowSubCols = {
            mode: 'checkbox',
            clickToSelectAndEditCell: true,
            // selected : this.state.selectedId,
            onSelect: (row, isSelect, rowIndex, e) => {
                let disable = true
                this.state.totalBalanceArr.map(function(item,i){
                    // let disabled = true
                    if(item.id===row.id)
                    {
                        item.isDeleted = isSelect ? "1" : "0"
                    }
                })
                // this.setState({disabled:disable})
            },
            onSelectAll: (isSelect, row) => {
                let isDisable = true
                this.state.totalBalanceArr.map(function(itm){
                    itm.isDeleted = isSelect ? "1" : "0"
                })
            }
        };

        let expandRow = {
            renderer: row => (
              <div>
                  <BootstrapTable
                    keyField = "id"
                    data = { row.deductions }
                    columns = { subCols }
                    pagination={ paginationFactory({sizePerPageRenderer}) }
                    noDataIndication={ () => <div style={{width:'100%'}}>No record found.</div> }
                    cellEdit = { cellEditFactory({
                        mode: 'click',
                        blurToSave: true,
                        afterSaveCell: (oldValue, newValue, row, column) => {
                            this.GridDataModifiedSubCol(oldValue, newValue, row.id, column.dataField)
                            }
                        })
                    }
                    rowClasses="noser-table-row-class"
                    striped
                    hover
                    condensed
                    selectRow={selectRowSubCols}
                />
              </div>
            ),
            showExpandColumn: this.state.isshowExpand
        };

    return(
            <div>
                <Banner />
                <Container className="mt-5" fluid>
                    <Card>
                        <Card.Header>CLEARANCE &#62;&#62; BATCH PROCESS</Card.Header>
                        <Card.Body>
                            <Form>
                                <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                                    <div className={this.state.color}></div> 
                                    {this.state.message}
                                </Alert>
                                <div>
                                <Accordion className="mt-2">
                                    <Card>
                                        <Card.Header>
                                            <Accordion.Toggle as={Button} variant="link" eventKey="0" style={{color: "#FFFFFF"}}>
                                                CLICK TO SEARCH
                                            </Accordion.Toggle>
                                        </Card.Header>
                                        <Accordion.Collapse eventKey="0">
                                            <Card.Body>
                                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                                    <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                    CLIENT
                                                    </Form.Label>
                                                    <Col>
                                                        <Typeahead
                                                            ref={this.client}
                                                            labelKey='name'
                                                            id="basic-example"
                                                            onChange={this.handleChangeClient}
                                                            options={this.state.clientDDL}
                                                            placeholder='SELECT CLIENT'
                                                            //selected={[this.state.clientName]}
                                                        />
                                                    </Col>
                                                    <Col sm="1">
                                                    </Col>
                                                    <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                    EMPLOYEE
                                                    </Form.Label>
                                                    <Col>
                                                        <Typeahead
                                                            ref={this.employee}
                                                            labelKey='employeeName'
                                                            id="basic-example"
                                                            onChange={this.handleChangeEmployee}
                                                            options={this.state.employeeDDL}
                                                            placeholder='SELECT EMPLOYEE'
                                                            //defaultSelected={[this.state.employeeName]}
                                                        />
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                                    <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                    REASON FOR LEAVING
                                                    </Form.Label>
                                                    <Col>
                                                        <Typeahead
                                                            ref={this.refReason}
                                                            labelKey='name'
                                                            id="basic-example"
                                                            onChange={this.handleChangedReason}
                                                            options={this.state.reasonDDL}
                                                            placeholder='Select reason for leaving'
                                                            value = {this.state.reason}

                                                        />
                                                    </Col>
                                                    <Col sm="1">
                                                    </Col>
                                                    <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                        Status
                                                    </Form.Label>
                                                    <Col>
                                                        <Typeahead
                                                            ref={this.name}
                                                            labelKey='name'
                                                            id="basic-example"
                                                            onChange={this.handleChangeStatus}
                                                            options={this.state.statusDDL} 
                                                            placeholder='SELECT STATUS'
                                                        />
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                                    <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                    From Date Created
                                                    </Form.Label>
                                                    <Col>
                                                        <DatePicker
                                                            ref='clearanceDate'
                                                            selected={this.state.fromDateCreated}
                                                            onChange={this.handleChangeFromDateCreated}
                                                            value={this.props.fromDateCreated}
                                                            dateFormat={"MM/dd/yyyy"}
                                                            className="form-control"
                                                            // placeholder='SELECT CLEARANCE DATE'
                                                            // wrapperClassName="datepicker"
                                                        />
                                                    </Col>
                                                    <Col sm="1">
                                                    </Col>
                                                    <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                    To Date Created
                                                    </Form.Label>
                                                    <Col>
                                                        <DatePicker
                                                            ref='clearanceDate'
                                                            selected={this.state.toDateCreated}
                                                            onChange={this.handleChangeToDateCreated}
                                                            value={this.props.toDateCreated}
                                                            dateFormat={"MM/dd/yyyy"}
                                                            className="form-control"
                                                            // placeholder='SELECT CLEARANCE DATE'
                                                            // wrapperClassName="datepicker"
                                                        />
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                                    <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                    From Clearance Date
                                                    </Form.Label>
                                                    <Col>
                                                        <DatePicker
                                                            ref='clearanceDate'
                                                            selected={this.state.fromClearanceDate}
                                                            onChange={this.handleChangeFromClearanceDate}
                                                            value={this.props.fromClearanceDate}
                                                            dateFormat={"MM/dd/yyyy"}
                                                            className="form-control"
                                                            // placeholder='SELECT CLEARANCE DATE'
                                                            // wrapperClassName="datepicker"
                                                        />
                                                    </Col>
                                                    <Col sm="1">
                                                    </Col>
                                                    <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                    To Clearance Date
                                                    </Form.Label>
                                                    <Col>
                                                        <DatePicker
                                                            ref='clearanceDate'
                                                            selected={this.state.toClearanceDate}
                                                            onChange={this.handleChangeToClearanceDate}
                                                            value={this.props.toClearanceDate}
                                                            dateFormat={"MM/dd/yyyy"}
                                                            className="form-control"
                                                            // placeholder='SELECT CLEARANCE DATE'
                                                            // wrapperClassName="datepicker"
                                                        />
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                                    <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                    From Date Received
                                                    </Form.Label>
                                                    <Col>
                                                        <DatePicker
                                                            ref='clearanceDate'
                                                            selected={this.state.fromDateReceived}
                                                            onChange={this.handleChangeFromDateReceived}
                                                            value={this.props.fromDateReceived}
                                                            dateFormat={"MM/dd/yyyy"}
                                                            className="form-control"
                                                            // placeholder='SELECT CLEARANCE DATE'
                                                            // wrapperClassName="datepicker"
                                                        />
                                                    </Col>
                                                    <Col sm="1">
                                                    </Col>
                                                    <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                    To Date Received
                                                    </Form.Label>
                                                    <Col>
                                                        <DatePicker
                                                            ref='clearanceDate'
                                                            selected={this.state.toDateReceived}
                                                            onChange={this.handleChangeToDateReceived}
                                                            value={this.props.toDateReceived}
                                                            dateFormat={"MM/dd/yyyy"}
                                                            className="form-control"
                                                            // placeholder='SELECT CLEARANCE DATE'
                                                            // wrapperClassName="datepicker"
                                                        />
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                                    <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                    From Last Working Date
                                                    </Form.Label>
                                                    <Col>
                                                        <DatePicker
                                                            ref='clearanceDate'
                                                            selected={this.state.fromLastWorkingDate}
                                                            onChange={this.handleChangeFromLastWorkingDate}
                                                            value={this.props.fromLastWorkingDate}
                                                            dateFormat={"MM/dd/yyyy"}
                                                            className="form-control"
                                                            // placeholder='SELECT CLEARANCE DATE'
                                                            // wrapperClassName="datepicker"
                                                        />
                                                    </Col>
                                                    <Col sm="1">
                                                    </Col>
                                                    <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                    To Last Working Date
                                                    </Form.Label>
                                                    <Col>
                                                        <DatePicker
                                                            ref='clearanceDate'
                                                            selected={this.state.toLastWorkingDate}
                                                            onChange={this.handleChangeToLastWorkingDate}
                                                            value={this.props.toLastWorkingDate}
                                                            dateFormat={"MM/dd/yyyy"}
                                                            className="form-control"
                                                            // placeholder='SELECT CLEARANCE DATE'
                                                            // wrapperClassName="datepicker"
                                                        />
                                                    </Col>
                                                </Form.Group>                                    
                                                <Form.Group as={Row} controlId="formPlaintextEmail" className='mb-5'>
                                                    <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                        RESiGNATION DATE
                                                    </Form.Label>
                                                    <Col>
                                                        <DatePicker
                                                            ref='resignationDate'
                                                            selected={this.state.resignationDate}
                                                            onChange={this.handleChangeResignationDate}
                                                            minDate={this.minDate}
                                                            value={this.props.resignationDate}
                                                            dateFormat={"MM/dd/yyyy"}
                                                            className="form-control"
                                                            // placeholder='SELECT RESIGNATION DATE'
                                                            wrapperClassName="datepicker"
                                                        />
                                                    </Col>
                                                </Form.Group>
                                                <div style={{height : "50px"}}></div>
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                </Accordion>
                                </div>
                                <ButtonToolbar className="mt-3">
                                    {/* <Button className="ml-auto noser-button-mr1 noser-button" variant="info" onClick={ this.handleClickTest }>
                                        TEst
                                    </Button>  */}
                                    <Button className="ml-auto noser-button-mr1 noser-button" variant="info" onClick={ this.handleClickRefresh }>
                                        Refresh
                                    </Button> 
                                    <Button variant="primary" className="noser-button-mr1 noser-button" onClick={ this.handleClickSearch }>
                                        Search
                                    </Button>
                                    <Button variant="info" className="noser-button" >
                                        <CSVLink style={{ color : "white" }} onClick ={this.GridExcellReports} filename= "Batch_Clearance.xls" data={this.state.gridDataExcel}>Export</CSVLink>
                                    </Button>
                                </ButtonToolbar>
                                <div className="mt-2">
                                    <BootstrapTable
                                        keyField = "id"
                                        data = { this.state.trainingGrid }
                                        columns = { trainingColumn }
                                        pagination={ paginationFactory({sizePerPageRenderer}) }
                                        noDataIndication={ () => <div style={{width:'100%'}}>No record found.</div> }
                                        rowClasses="noser-table-row-class"
                                        striped
                                        hover
                                        condensed
                                        cellEdit = { cellEditFactory({
                                            mode: 'dbclick',
                                            blurToSave: true,
                                            afterSaveCell: (oldValue, newValue, row, column) => {
                                                this.GridDataModified(oldValue, newValue, row.id, column.dataField)
                                                }
                                            })
                                        }
                                        selectRow = { selectRow }
                                        expandRow={ expandRow }
                                    />
                                </div>
                                <ButtonToolbar sm={12}>
                                    <Button onClick={ this.handleClickSubmit } /* disabled={this.state.userinfo.userId === "91118" || this.state.userinfo.userId === "87961" ? true : false } */ className="ml-auto noser-button-mr1 noser-button" variant="info">
                                        submit
                                    </Button>
                                    <Button onClick={this.handleClickReturn } /* disabled={this.state.disabled} */ className="noser-button-mr1 noser-button" variant="info">
                                        return
                                    </Button>
                                </ButtonToolbar>
                            </Form>
                        </Card.Body>
                    </Card>
                </Container>
                <NoserLoading show={this.state.isloading} />
                {/* <TrainingClient 
                    show={this.state.openClientModal}
                    onHide={this.handleClientModalClose}
                    onRefModal={ref => (this.child = ref)}
                />
                <TrainingPosition
                    show={this.state.openPositionModal}
                    onHide={this.handlePositionModalClose}
                    onRefModal={ref => (this.child1 = ref)}
                /> */}
            </div> 
        )
    }
}

export  default BatchClearance 
