import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card,
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, Redirect, DatePicker,
    Accordion,Modal, Tabs, Tab
}
from '../../noser-hris-component';
import {CSVLink, CSVDownload} from 'react-csv';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit'
import { GetRequest, PostRequest } from '../../noser.dataaccess'


// dev exx
import 'devextreme/dist/css/dx.light.css';
import DataGrid, { Column, Editing, Paging, Selection, Sorting, Export, Summary, TotalItem, ScrollView, AsyncRule, LoadPanel} from 'devextreme-react/data-grid';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver-es';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { Template } from 'devextreme-react/core/template';
import NButton from './DeductionBreakdown2';

let ref = React.createRef();
class ClearancePayrollSummary extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fade            :   true,
            openModal       :   false,
            color           :   "",
            isshow          :   false,
            message         :   "",
            userinfo        :   [],
            isloading       :   false,
            alerttype       :   "",

            clientDDL       :   [],
            employeeDDL     :   [],
            gridDataExcel   :   [],
            isshowExpand    :   false,
            reasonDDL       :   [],
            filteredBatch   :   [],
            positiveArry    :   [],
            negativeArry    :   [],
            deductionMaintenance : [],
            inclusionMaintenance : [],
            subsidiaryData  :   [],
            inclusionAre    :   [],
            paycardMaintenance  :   [],
            dividendLst     :   [],
            batchList       :   [],
            employeeId : "",
            unFinalizedArry : [],
            dsabldBttn  :   true,
            srchNgtve: [],
            srchPositve: [],
            getPositive: [],
            getNegative: [],
            sortEmp : [],
            // showCheckbox        : "always"

            // dev express

            showScrollBarMode: 'onScroll',
            pullDown: false,
            scrollByContent: true,
            scrollByThumb: true,
            CbuDividend : [],
            EmployeePayroll : [],
            DataSearch : [],
            employeeIdSrch : "",

            allMode: 'allPages',
            checkBoxesMode: 'always',
            // content: service.getContent(),
        }
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))

        // dev express

        this.dataGridRef = React.createRef();
        this.onExporting = this.onExporting.bind(this);
        this.onExportingNegative = this.onExportingNegative.bind(this);
        // this.onAllModeChanged = this.onAllModeChanged.bind(this);
        // this.onCheckBoxesModeChanged = this.onCheckBoxesModeChanged.bind(this);
    }

    componentDidMount(){
        let sleep = (milliseconds) => {return new Promise(resolve => setTimeout(resolve, milliseconds))}
        this.GetPayLastPayroll()
        this.GetClient()
    };

    /* Romel */
    GetClient = async() =>{
        // // // console.log("Loading CLient")
        this.setState({isloading:true})
        let param = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
            "EmployeeId":this.state.userinfo.roleId==="1" ||
                        this.state.userinfo.roleId==="8" ? "" : this.state.userinfo.employeeId
        }
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Client/GetClientList", param)
        .then(res => {
            let data = res.data;
            let arr = []
            if(this.state.userinfo.roleId!=="1"){
                let fieldaccess = JSON.parse(sessionStorage.getItem("userControlAccess"))
                if(fieldaccess.filter(x=>x.accessCode==="0001").length>0){
                    let access = fieldaccess.find(x=>x.accessCode==="0001" && x.controlAccessDetails.length>0)
                    if(access.controlAccessDetails.filter(x=>x.isClient==="1").length>0){
                        let brr = access.controlAccessDetails.filter(x=>x.isClient==="1")
                        arr = data.clients.filter(f => brr.some(item => item.referenceId === f.id))
                    }
                }
                this.setState({clientDDL : arr,})
            }
            else{
                this.setState({clientDDL : data.clients,})
            }
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
    };
    GetPayLastPayroll =async()=>{
        console.log("Loading Last Payroll")
        let lastPayroll = []
        const params = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   "",
            "UserId"        :   this.state.userinfo.userId,
            "EmployeeId"    :   "",
            "StatusId" : "0"
        };
        axios.post(AppConfiguration.Setting().noserapiendpoint + "Payroll/GetClearanceEmployeeLastPayrolls",  params)
        .then(res => {
            const data = res.data;
            // // // console.log("Last payroll")
            // // // console.log(data)
            this.setState({EmployeePayroll: data.lastPayrolls})
            if(data.status==="1"){
                this.GetDeductionMaintenance()
            }
        })
        .catch(error=>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
        // this.GetEmployees()
    };
    GetDeductionMaintenance = async() =>{
        console.log("Loading Deductions")
        this.setState({isloading:true})
        let params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId
        };
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetDeductions", params)
        .then(res => {
            this.setState({
                deductionMaintenance : res.data.deductions.filter(x=>x.isInclusion==="0").sort((a, b) => parseFloat(a.priority) > parseFloat(b.priority) ? 1 : -1),
                inclusionMaintenance : res.data.deductions.filter(x=>x.isInclusion==="1")
            })
            if(res.data.status==="1"){
                this.GetDividend()
            }
            else{
                this.setState(  {
                    isloading       :   false,
                    alerttype       :   "Error!",
                    isshow          :   true,
                    color           :   "danger",
                    message         :   res.data.message,
                    fade            :   true
                })
            }
        })
        .catch(error=>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
    };
    GetDividend = async() =>{
        console.log("GetDividend")
        // try{
        let _filterData = {"isDeleted" : "0", "isInclusion" : "1", "isSubmittedToPayroll" : "1"}
        let resp = await GetRequest({
            url : AppConfiguration.Setting().noserareapi + "action/get",
            params : {
                "dbname"      :  AppConfiguration.Setting().noseraredb,
                "_collection" : "InclusionLedger",
                "filter_data" : _filterData
            }
        })
        console.log("resp.ResultList")
        console.log(resp.ResultList)
        if(resp.status==="1"){
            this.setState({ CbuDividend : resp.ResultList, dividendLst : resp.ResultList.filter(x=>x.isInclusion==="0"), AreInlcusion : resp.ResultList.filter(x=>x.isInclusion==="1" && x.deduction!=="CBU" && x.deduction!=="DIVIDEND")})
            this.GetPayCardTypes()
        }
    };
    GetPayCardTypes = async()=> {
        console.log("Loading Paycard")
        const params = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.userinfo.clientId,
            "UserId"        :   this.state.userinfo.userId
        };
        await axios.post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetPayCardTypes",  params)
        .then(res => {
            const data = res.data;
            this.setState({paycardMaintenance:data.payCardTypes.filter(x=>x.id!=="3"&&x.id!=="5"&&x.id!=="7"&&x.id!=="8")});

            if(data.status==="1"){
                this.GetDataClearance()
            }
        })
        .catch(error=>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
    };
    GetDataClearance = async() => { // Grid Data
        console.log("Loading ClearanceData")
        this.setState({isloading:true})
        let param = {
            "IpAddress"         :   "0.0.0.0",
            "ClientId"          :   "",
            "UserId"            :   "35954",
            "ProfileId"         :   ""
        };
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Clearance/GetClearance",  param)
        .then(res => {

            let data = res.data.clearance;
            let modifyData = []
            let _sortData = data.filter(x => parseFloat(x.isClearanceStatus) > 7 && x.isFinalized ==="1")
            console.log("_sortData")
            console.log(_sortData)
            let filterData = _sortData.sort((a, b) => a.employeeName > b.employeeName ? 1 : -1)
            this.setState({ DataSearch : filterData })
            let _filteredBatch = filterData.filter(x=>x.batchNumber!== "")
            let _BatchNumberDDL = _filteredBatch.filter((thing, index, self) =>
                index === self.findIndex((t) => (
                    t.batchPayroll === thing.batchPayroll
                ))
            )
            let searchName = filterData.filter((thing, index, self) =>
            index === self.findIndex((t) => (
                t.employeeName === thing.employeeName
            )))
            this.setState({
                filteredBatch    : _BatchNumberDDL.length>0?_BatchNumberDDL.sort((a, b) => a.batchPayroll > b.batchPayroll ? -1 : 1):[],
                unFinalizedArry : filterData.sort((a, b) => new Date(a.id) > new Date(b.id) ? -1 : 1),
                sortEmp : searchName.sort((a, b) => a.employeeName < b.employeeName ? -1 : 1),
                getPositive : filterData.length>0?filterData.filter(x=>parseFloat(String(x.netPay).replace(',','')) >= 0).sort((a, b) => a.employeeName > b.employeeName ? 1 : -1):[],
                getNegative : filterData.length>0?filterData.filter(x=>parseFloat(String(x.netPay).replace(',','')) < 0).sort((a, b) => a.employeeName > b.employeeName ? 1 : -1):[],
                // isloading:false
            });
            this.GetEmployees()

            if(data.status==="0"){
                this.setState(  {
                    isloading       :   false,
                    alerttype       :   "Error!",
                    isshow          :   true,
                    color           :   "danger",
                    message         :   data.message,
                    fade            :   true
                })
            }
        })
        .catch(error=>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
    };
    GetEmployees = async() => {

        this.setState({isloading:true})
        // // // console.log("Loading Employees")
        let param = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.userinfo.clientId,
            "UserId"        :   "0",
        }
        // // // // // // console.log("param")
        // // // // // // console.log(param)
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Employee/GetEmployeeProfileFilter",  param)
        .then(res => {
            let data = res.data
            // // // // // // console.log("data.employees")
            // // // // // // console.log(data.employees)
            this.setState({
                employeeDDL        :   data.profiles.filter(x=>x.status==="INACTIVE"),
                isloading           :   false,
            })
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })

    };

    handleClickSearch = async() =>{
        this.setState({ isloading : true })

        this.GetSubsidiaryLedgerStaging()
        // console.log("this.state.unFinalizedArry")
        // console.log(this.state.unFinalizedArry.filter(x=> x.employeeName === "RELAMPAGOS, ELMIRA MORING"))


    };

    GetSubsidiaryLedgerStaging = async() =>{

        // let filterData = this.state.unFinalizedArry.filter(x=>x.batchPayroll===this.state.batchList)
        let filterData = this.state.unFinalizedArry
        console.log("filterData")
        console.log(filterData)

        let empNumber = []
        filterData.map(function(itm,idx) {
            empNumber.push({
                employeeNo : itm.employeeNo,
            })
        })
        let dataInc = []
        let dataSL = []
        let isStatus = false
        for (let i = 0; i < filterData.length; i++){
            let resp = await GetRequest({
                url : AppConfiguration.Setting().noserareapi + "action/get",
                params : {
                    "dbname"      :  AppConfiguration.Setting().noseraredb,
                    "_collection" : "SubsidiaryLedgerStaging",
                    "filter_data" : {"isDeleted" : '0', "employeeNo" : filterData[i].employeeNo}
                }
            })
            // // // console.log(' 3 after params')

            if(resp.status==="1"){
                this.setState({ subsidiaryData: resp.ResultList})
                // return isStatus = true
            }
            else{
                // // // console.log(' 7 error else ')
                this.setState(  {
                    isloading       :   false,
                    alerttype       :   "Error!",
                    isshow          :   true,
                    color           :   "danger",
                    message         :   resp.message,
                    fade            :   true
                })
                // isStatus = false
            }
        }
        this.CallGridData()
        this.GetEmployees()
    };
    CallGridData = async() =>{
        this.DataListPositive()
        this.DataListNegative()
    }
    DataListPositive = async() =>{

        let datalistArry = []

        if (this.state.employeeId === ""){

            datalistArry = this.state.unFinalizedArry.filter(f => this.state.batchList.some(item => item.batchPayroll === f.batchPayroll))
            // datalistArryNeg = this.state.unFinalizedArry.filter(f => this.state.batchList.some(item => item.batchPayroll === f.batchPayroll))
            // filterNegative = this.state.getNegative.filter(f => this.state.batchList.some(item => item.batchPayroll === f.batchPayroll))

        }
        else{

            datalistArry = this.state.DataSearch.filter(x=>x.employeeId === this.state.employeeIdSrch)
            // datalistArryNeg = this.state.DataSearch.filter(x=>x.employeeId === this.state.employeeIdSrch)
            // filterNegative = this.state.getNegative.filter(x=>x.employeeId === this.state.employeeId)
        }


        let modifyData = datalistArry.filter(x=>parseFloat(String(x.netPay).replace(',','') )>=0)
        let positiveData = []
        let dataInc = []

        let _inc = this.state.AreInlcusion
        let LastPayroll = this.state.EmployeePayroll
        let cbudividn = this.state.CbuDividend

        modifyData.sort((a, b) => a.employeeName > b.employeeName ? 1 : -1).map(function(itm,idx) {
            let _tmpData = []
            let tmpDvdnd = []
            if(itm.parentId!==""){
                console.log("child")
                _tmpData = _inc.filter( x => x.employeeNo===itm.employeeNo && x.isFloating==="1")
                dataInc = _inc.filter( x => x.employeeNo===itm.employeeNo && x.isFloating==="1")
                tmpDvdnd = cbudividn.filter( x => x.employeeNo===itm.employeeNo && x.isFloating==="1")
                
            }
            else{
                console.log("parent")
                _tmpData = _inc.filter( x => x.employeeNo===itm.employeeNo && x.isFloating==="0")
                dataInc = _inc.filter( x => x.employeeNo===itm.employeeNo && x.isFloating==="0")
                tmpDvdnd = cbudividn.filter( x => x.employeeNo===itm.employeeNo && x.isFloating==="0")
                
            }
            // dataInc = _tmpData
            // COMPUTATIONS
            // console.log("_tmpData")
            // console.log(_tmpData)
            let _totInc = _tmpData.length>0?_tmpData.reduce((total, currentValue) => total + parseFloat(String(currentValue.balanceAmount).replace(',','')),0):0
            let _cbu = tmpDvdnd.filter(x=> x.employeeNo===itm.employeeNo && x.isInclusion === "1" && x.deductionId ==="5").length>0 ?
                parseFloat(String(tmpDvdnd.find(x=> x.employeeNo===itm.employeeNo && x.isInclusion === "1" && x.deductionId ==="5").balanceAmount).replace(',','')) : 0
            let _dividend = itm.dividend!=="" ? itm.dividend : tmpDvdnd.filter(x=> x.employeeNo===itm.employeeNo && x.isInclusion === "1" && x.deductionId==="86").length>0 ?
                parseFloat(String(tmpDvdnd.find(x=> x.employeeNo===itm.employeeNo && x.isInclusion === "1").balanceAmount).replace(',','')) : 0

                let _lastSalary = ""
                let _adjustment = ""
                let _otherPayroll = ""
                let _thirteenthMonth = ""
                let _sil = ""
                let _separation = ""
    
                // if(itm.completionDate===""){
                //      _lastSalary = LastPayroll.filter(x=> x.employeeId===itm.employeeId).length>0 ?
                //         parseFloat(String(LastPayroll.find(x=> x.employeeId===itm.employeeId).lastPayroll).replace(',','')) : 0
                //      _adjustment = LastPayroll.filter(x=> x.employeeId===itm.employeeId).length>0 ?
                //         parseFloat(String(LastPayroll.find(x=> x.employeeId===itm.employeeId).adjustmentPayroll).replace(',','')) : 0
                //      _otherPayroll = LastPayroll.filter(x=> x.employeeId===itm.employeeId).length>0 ?
                //         parseFloat(String(LastPayroll.find(x=> x.employeeId===itm.employeeId).otherPayroll).replace(',','')) : 0
                //      _thirteenthMonth = LastPayroll.filter(x=> x.employeeId===itm.employeeId).length>0 ?
                //         parseFloat(String(LastPayroll.find(x=> x.employeeId===itm.employeeId).thirteenthMonth).replace(',','')) : 0
                //      _sil = LastPayroll.filter(x=> x.employeeId===itm.employeeId).length>0 ?
                //         parseFloat(String(LastPayroll.find(x=> x.employeeId===itm.employeeId).sil).replace(',','')) : 0
                //      _separation = LastPayroll.filter(x=> x.employeeId===itm.employeeId).length>0 ?
                //         parseFloat(String(LastPayroll.find(x=> x.employeeId===itm.employeeId).separationPay).replace(',','')) : 0
                // }
                // else {
    
                     _lastSalary = itm.lastSalary
                     _adjustment = itm.adjustment
                     _otherPayroll = itm.otherPayroll
                     _thirteenthMonth = itm.thirteenthMonth
                     _sil = itm.sil
                     _separation = itm.separationPay
                // }

            let sttlmntFee = itm.settlementFee === "" || typeof itm.settlementFee ==="undefined" ? 0: itm.settlementFee
            
            let _grossPay = parseFloat(String(_lastSalary)) + parseFloat(String(_adjustment)) + parseFloat(String(_otherPayroll)) + parseFloat(String(_thirteenthMonth)) + parseFloat(String(_sil)) + parseFloat(String(_separation)) + parseFloat(String(_cbu))+ parseFloat(String(_dividend)) + parseFloat(String(sttlmntFee))
            console.log("_grossPay")
            console.log(_grossPay)
            let totalGross = parseFloat(_grossPay) + parseFloat(_totInc)
            let netPayTmp = parseFloat(totalGross) - parseFloat(String(itm.totalBalance).replace(',',''))
            let _netPay = parseFloat(String(netPayTmp).replace(',','')).toFixed(2)

            let _tmpHc = parseFloat(idx+1)
            
            positiveData.push({
                hc : _tmpHc,
                batchNumber : itm.batchNumber,
                clientName : itm.clientName,
                employeeNo : itm.employeeNo,
                employeeName : itm.employeeName,
                reasonForLeaving : itm.reasonForLeaving,
                dateStart : itm.dateStart,
                dateEnd : itm.lastWorkingDateMember,
                paycardNumber : itm.cashCardNumberMember,
                cbu : parseFloat(itm.cbu),
                dividend : parseFloat(itm.dividend),
                lastSalary : parseFloat(itm.lastSalary),
                adjustment : parseFloat(itm.adjustment),
                otherPayroll : parseFloat(itm.otherPayroll),
                thirteenthMonth : parseFloat(itm.thirteenthMonth),
                sil : parseFloat(itm.sil),
                separationPay :parseFloat(itm.separationPay),
                settlementFee : typeof sttlmntFee === "undefined" ? 0 : parseFloat(sttlmntFee),
                incentives : itm.incentives,
                grossPay : parseFloat(_grossPay).toFixed(2),
                inclusions : parseFloat(totalGross).toFixed(2),
                totalInclusion : parseFloat(String(_totInc).replace(',','')),
                totalBalance : parseFloat(String(itm.totalBalance).replace(',','')),
                noAtm : itm.isCash === "1" || itm.isCheckMember === "1" ? parseFloat(String(itm.netPay).replace(',','')) : 0,
                totalBalance : parseFloat(String(itm.totalBalance).replace(',','')),
                netPay : _netPay === "" ? parseFloat(_netPay) : parseFloat(String(_netPay).replace(',','')),
                noAtm : itm.isCash === "1" || itm.isCheckMember === "1" || itm.paycardTypeIdMember === "8" ? parseFloat(itm.netPay) : 0,

                batchPayroll : itm.batchPayroll,
                typeOfCashCardMember : itm.typeOfCashCardMember,
                areRemarks : itm.areRemarks,
                clearanceDateMember : itm.clearanceDateMember,
                clearanceDeductions : itm.clearanceDeductions.sort((a, b) => parseFloat(a.priority) > parseFloat(b.priority) ? 1 : -1),
                clearanceDetails : itm.clearanceDetails,
                clearanceStatus : itm.clearanceStatus,
                clearedSubmit : itm.clearedSubmit,
                clientId : itm.clientId,
                closingRemarks : itm.closingRemarks,
                createdBy : itm.createdBy,
                createdByName : itm.createdByName,
                createdDate : itm.createdDate,
                cycleDays : itm.cycleDays,
                dateCreated : itm.dateCreated,
                dateForwardedToAmt : itm.dateForwardedToAmt,
                dateOfBirth : itm.dateOfBirth,
                dateReceivedByAdmin : itm.dateReceivedByAdmin,
                dateReceivedFromAmt : itm.dateReceivedFromAmt,
                dateReturnedByARE : itm.dateReturnedByARE,
                dateReturnedByClient : itm.dateReturnedByClient,
                dateReturnedByFinance : itm.dateReturnedByFinance,
                dateReturnedByLRD : itm.dateReturnedByLRD,
                dateReturnedByPayroll : itm.dateReturnedByPayroll,
                dateReturnedByRMT : itm.dateReturnedByRMT,
                dateReturnedByServices : itm.dateReturnedByServices,
                dateSettle : itm.dateSettle,
                dateSubmitted : itm.dateSubmitted,
                dateSubmittedByLead : itm.dateSubmittedByLead,
                dateSubmittedByStaff : itm.dateSubmittedByStaff,
                dateUpdatedByARE : itm.dateUpdatedByARE,
                dateUpdatedByClient : itm.dateUpdatedByClient,
                dateUpdatedByFinance : itm.dateUpdatedByFinance,
                dateUpdatedByLRD : itm.dateUpdatedByLRD,
                dateUpdatedByRMT : itm.dateUpdatedByRMT,
                dateUpdatedByServices : itm.dateUpdatedByServices,
                employeeId : itm.employeeId,
                finalized : itm.finalized,
                financeRemarks : itm.financeRemarks,
                firstName : itm.firstName,
                generation : itm.generation,
                // grossPay : itm.grossPay,
                hdmfNumber : itm.hdmfNumber,
                id : itm.id,
                isCOERequestFormMember : itm.isCOERequestFormMember,
                isCash : itm.isCash,
                isCashCardMember : itm.isCashCardMember,
                isCheckMember : itm.isCheckMember,
                isCheckedByRmt : itm.isCheckedByRmt,
                isClearanceStatus : itm.isClearanceStatus,
                isClearedClient : itm.isClearedClient,
                isClearedRmt : itm.isClearedRmt,
                isClearedSubmit : itm.isClearedSubmit,
                isDeleted : itm.isDeleted,
                isEligible : itm.isEligible,
                isEligibleClient : itm.isEligibleClient,
                isFinalized : itm.isFinalized,
                isForwardToARERmt : itm.isForwardToARERmt,
                isGenerated : itm.isGenerated,
                isHDMFNumberMember : itm.isHDMFNumberMember,
                isMlhuillierMember : itm.isMlhuillierMember,
                isModified : itm.isModified,
                isModifiedByARE : itm.isModifiedByARE,
                isNonEligibleClient : itm.isNonEligibleClient,
                isNotEligible : itm.isNotEligible,
                isPHICNumberMember : itm.isPHICNumberMember,
                isPalawanMember : itm.isPalawanMember,
                isResignationLetterMember : itm.isResignationLetterMember,
                isRetrunedServicesRmt : itm.isRetrunedServicesRmt,
                isReturnedRmt : itm.isReturnedRmt,
                isSSSNumberMember : itm.isSSSNumberMember,
                isSettleWithDole : itm.isSettleWithDole,
                isTINNumberMember : itm.isTINNumberMember,
                isTurnoverChecklistMember : itm.isTurnoverChecklistMember,
                isTypeOfCashCardMember : itm.isTypeOfCashCardMember,
                isValidIdMember : itm.isValidIdMember,
                lastName : itm.lastName,
                locationId : itm.locationId,
                locationName : itm.locationName,
                middleName : itm.middleName,
                modifiedBy : itm.modifiedBy,
                modifiedByName : itm.modifiedByName,
                modifiedDate : itm.modifiedDate,
                // netPay : itm.netPay,
                notesMember : itm.notesMember,
                paramountId : itm.paramountId,
                payPeriodId : itm.payPeriodId,
                paycardTypeIdMember : itm.paycardTypeIdMember,
                payrollPeriods : itm.payrollPeriods,
                payrollRemarks : itm.payrollRemarks,
                periodCovered : itm.periodCovered,
                phicNumber : itm.phicNumber,
                positionId : itm.positionId,
                positionName : itm.positionName,
                profileId : itm.profileId,
                reasonForLeaving : itm.reasonForLeaving,
                reasonForLeavingId : itm.reasonForLeavingId,
                remarksClient : itm.remarksClient,
                remarksLrd : itm.remarksLrd,
                remarksMember : itm.remarksMember,
                remarksRmt : itm.remarksRmt,
                resignationDateMember : itm.resignationDateMember,
                returnedByARE : itm.returnedByARE,
                returnedByClient : itm.returnedByClient,
                returnedByFinance : itm.returnedByFinance,
                returnedByLRD : itm.returnedByLRD,
                returnedByRMT : itm.returnedByRMT,
                returnedByServices : itm.returnedByServices,
                reviewStatus : itm.reviewStatus,
                reviewStatusResults : itm.reviewStatusResults,
                rowIndex : itm.rowIndex,
                servicesAttachments : itm.servicesAttachments,
                servicesRemarks : itm.servicesRemarks,
                // sil : itm.sil,
                sssNumber : itm.sssNumber,
                submissionDateMember : itm.submissionDateMember,
                submitted : itm.submitted,
                submittedByLead : itm.submittedByLead,
                submittedByStaff : itm.submittedByStaff,
                suffixName : itm.suffixName,
                timeStamp : itm.timeStamp,
                tinNumber : itm.tinNumber,
                // totalBalance : itm.totalBalance,
                updatedByARE : itm.updatedByARE,
                updatedByClient : itm.updatedByClient,
                updatedByFinance : itm.updatedByFinance,
                updatedByLRD : itm.updatedByLRD,
                updatedByRMT : itm.updatedByRMT,
                updatedByServices : itm.updatedByServices,
                validateByRmt : itm.validateByRmt,
                batchPayroll : itm.batchPayroll,
                dateHired : itm.dateHired,
                contractDateStart : itm.contractDateStart,
                contractDateEnd : itm.contractDateEnd,
                isParent : itm.isParent,
                isChild : itm.isChild,
                parentId : itm.parentId,
                
                dateSubmittedToPayroll : itm.dateSubmittedToPayroll,
                isAre : itm.isAre,
                isPayroll : itm.isPayroll,
                financePayoutDate : itm.financePayoutDate,
                isUpdatedByARE : itm.isUpdatedByARE,
                completionDate : itm.completionDate,
                lastModifiedBy : itm.lastModifiedBy,
            })
        })
        // ATTACHED DEDUCTIONS & INCLUSION


        let uniqpaycards = [...new Map(positiveData.map((item) => [JSON.stringify(item), item])).values(),];
        // let filterInclu = dataInc.filter(f => modifyData.some(item => f.employeeId === item.employeeId))
        let uniqinclusion = [...new Map(this.state.AreInlcusion.map((item) => [JSON.stringify(item), item])).values(),];
        let empdeduction = []
        positiveData.forEach(itm=>{
            itm.clearanceDeductions.forEach(ded =>{
                empdeduction.push({
                    deductionId : ded.deductionId,
                    deduction : ded.deduction
                })
            })
        })

        // // // console.log("empdeduction")
        // // // console.log(empdeduction)
        let uniqdeduction = [...new Map(empdeduction.map((item) => [JSON.stringify(item), item])).values(),];
        // // // console.log("uniqdeduction")
        // // // console.log(uniqdeduction)
        positiveData.forEach(itm =>{
            let _tmpData= []
            if(itm.parentId!==""){
                console.log("child")
                _tmpData = this.state.AreInlcusion.filter( x => x.isFloating==="1")
            }
            else{
                console.log("parent")
                _tmpData = this.state.AreInlcusion.filter( x => x.isFloating==="0")
            }
            this.state.deductionMaintenance.filter(f => uniqdeduction.some(item => item.deductionId === f.id)).forEach(ded=>{
                itm[ded.name]   = itm.clearanceDeductions.filter(x=>x.employeeNo===itm.employeeNo && x.deductionId===ded.id && x.chargesAmount!=="0.00").length>0 ?
                                    parseFloat(String(itm.clearanceDeductions.filter(x=>x.employeeNo===itm.employeeNo && x.deductionId===ded.id && x.chargesAmount!=="0.00").reduce((total, currentValue) => total + parseFloat(String(currentValue.balance).replace(',','')),0))) : 0.00
            })
            this.state.inclusionMaintenance.filter(f => uniqinclusion.some(item => f.id === item.deductionId)).forEach(inc=>{
                itm[inc.name]   = _tmpData.filter(x=>x.employeeNo===itm.employeeNo && x.deductionId===inc.id).length>0 ?
                                    parseFloat(String(_tmpData.find(x=>x.employeeNo===itm.employeeNo && x.deductionId===inc.id).balanceAmount).replace(',','')) : 0.00
            })
            this.state.paycardMaintenance.filter(f => uniqpaycards.some(item => item.typeOfCashCardMember === f.name)).forEach(pc => {
                itm[pc.name] = itm.typeOfCashCardMember === pc.name ? parseFloat((String(parseFloat(itm.netPay).toFixed(2)).replace("-",""))) : 0
            })
        })

        let newData = positiveData
            // // let totalTmp = JSON.parse(JSON.stringify(newData).replace(' ', '').replace('-', ''));
            // let totalTmp = newData.map(el => el.trim());
            // // // // console.log("totalTmp")
            // // // // console.log(totalTmp)
        let trimFieldNamePos = []
        this.state.unFinalizedArry.forEach(data=>{
            // // console.log(this.state.subsidiaryData.filter(x=> x.employeeId===data.employeeId))
            this.state.deductionMaintenance.filter(f => uniqdeduction.some(item => item.deductionId === f.id)).forEach(mnt=>{
                data[mnt.name] = data.clearanceDeductions.filter(x=> x.employeeId===data.employeeId && x.deductionId===mnt.id).length>0 ?
                                    data.clearanceDeductions.find(x=> x.employeeId===data.employeeId && x.deductionId===mnt.id).balance : 0
            })
            this.state.inclusionMaintenance.filter(f => uniqinclusion.some(item => f.id === item.deductionId)).forEach(mnt=>{
                data[mnt.name] = this.state.AreInlcusion.filter(x=> x.employeeId===data.employeeId && x.deductionId===mnt.id).length>0 ?
                                    this.state.AreInlcusion.find(x=> x.employeeId===data.employeeId && x.deductionId===mnt.id).amount : 0
            })
            this.state.paycardMaintenance.filter(f => uniqpaycards.some(item => item.typeOfCashCardMember === f.name)).forEach(mnt => {
                data[mnt.name] = mnt.typeOfCashCardMember === data.name ? positiveData.reduce((total, currentValue) => total + parseFloat(String(currentValue.netPay).replace(',','')),0).toFixed(2) : 0

            })
        })

        const res = positiveData.reduce((result, item) => {
            const keys = Object.keys(item);
            keys.forEach(key => {
                if (this.state.deductionMaintenance.filter(x=>x.name===key).length>0){
                    result[key] = result[key] ? result[key] + item[key] : item[key];
                }
                if (this.state.inclusionMaintenance.filter(x=>x.name===key).length>0){
                    result[key] = result[key] ? result[key] + item[key] : item[key];
                }
                if (this.state.paycardMaintenance.filter(x=>x.name===key).length>0){
                    result[key] = result[key] ? result[key] + item[key] : item[key];
                }
            });
            return result;
            },
            {
                "id" : "",
                "hc" : "",
                "batchNumber" : "",
                "employeeNo" : "",
                "reasonForLeaving" : "GRAND TOTAL : ",
                "clientName" : "",
                "employeeName" : "",
                "dateEnd" : "",
                "paycardNumber" : "",
                "cbu" : positiveData.reduce((total, currentValue) => total + parseFloat(String(currentValue.cbu).replace(',','')),0).toFixed(2),
                "dividend" : positiveData.reduce((total, currentValue) => total + parseFloat(String(currentValue.dividend).replace(',','')),0).toFixed(2),
                "lastSalary" : positiveData.reduce((total, currentValue) => total + parseFloat(String(currentValue.lastSalary).replace(',','')),0).toFixed(2),
                "adjustment" : positiveData.reduce((total, currentValue) => total + parseFloat(String(currentValue.adjustment).replace(',','')),0).toFixed(2),
                "otherPayroll" : positiveData.reduce((total, currentValue) => total + parseFloat(String(currentValue.otherPayroll).replace(',','')),0).toFixed(2),
                "thirteenthMonth" : positiveData.reduce((total, currentValue) => total + parseFloat(String(currentValue.thirteenthMonth).replace(',','')),0).toFixed(2),
                "sil" : positiveData.reduce((total, currentValue) => total + parseFloat(String(currentValue.sil).replace(',','')),0).toFixed(2),
                "separationPay" : positiveData.reduce((total, currentValue) => total + parseFloat(String(currentValue.separationPay).replace(',','')),0).toFixed(2),
                "settlementFee" : isNaN(positiveData.reduce((total, currentValue) => total + parseFloat(String(currentValue.settlementFee).replace(',','')),0)) ? "" : positiveData.reduce((total, currentValue) => total + parseFloat(String(currentValue.settlementFee).replace(',','')),0).toFixed(2),
                "inclusions" : positiveData.reduce((total, currentValue) => total + parseFloat(String(currentValue.inclusions).replace(',','')),0).toFixed(2),
                "grossPay" : positiveData.reduce((total, currentValue) => total + parseFloat(String(currentValue.grossPay).replace(',','')),0).toFixed(2),
                "totalInclusion" : positiveData.reduce((total, currentValue) => total + parseFloat(String(currentValue.totalInclusion).replace(',','')),0).toFixed(2),
                "totalBalance" : positiveData.reduce((total, currentValue) => total + parseFloat(String(currentValue.totalBalance).replace(',','')),0).toFixed(2),
                "netPay" : positiveData.reduce((total, currentValue) => total + parseFloat(String(currentValue.netPay).replace(',','')),0).toFixed(2),
                "noAtm" : positiveData.reduce((total, currentValue) => total + parseFloat(String(currentValue.noAtm).replace(',','')),0).toFixed(2),
            });
            positiveData.push(res);
            // "SettlementFee" : itm.settlementFee,


        // console.log("positiveData")
        // console.log(positiveData)

        this.setState({
            // unFinalizedArry : modifyData,
            // dsabldBttn : modifyData.length>0?false:true,
            srchPositve : positiveData,
            // srchNgtve : modifyDataNegative.concat(grandTotalNeg),
            isloading : false
        })
    }
    DataListNegative = async() =>{

        let datalistArry = []
        let datalistArryNeg = []
        let filterPositive = []
        if (this.state.employeeId === ""){

            // datalistArry = this.state.unFinalizedArry.filter(f => this.state.batchList.some(item => item.batchPayroll === f.batchPayroll))
            datalistArryNeg = this.state.unFinalizedArry.filter(f => this.state.batchList.some(item => item.batchPayroll === f.batchPayroll))
            // filterNegative = this.state.getNegative.filter(f => this.state.batchList.some(item => item.batchPayroll === f.batchPayroll))

        }
        else{

            // datalistArry = this.state.DataSearch.filter(x=>x.employeeId === this.state.employeeIdSrch)
            datalistArryNeg = this.state.DataSearch.filter(x=>x.employeeId === this.state.employeeIdSrch)
            // filterNegative = this.state.getNegative.filter(x=>x.employeeId === this.state.employeeId)
        }

        let NegativeClaims = datalistArryNeg.filter(x=>parseFloat(String(x.netPay).replace(',',''))<0)
        let modifyDataNegative = []
        let dataInc = []

        let _inc = this.state.AreInlcusion
        let LastPayroll = this.state.EmployeePayroll
        let cbudividn = this.state.CbuDividend

        NegativeClaims.forEach((itm,idx)=>{
            let _tmpData = []
            let tmpDvdnd = []
            if(itm.parentId!==""){
                console.log("child")
                _tmpData = _inc.filter( x => x.employeeNo===itm.employeeNo && x.isFloating==="1")
                dataInc = _inc.filter( x => x.employeeNo===itm.employeeNo && x.isFloating==="1")
                tmpDvdnd = cbudividn.filter( x => x.employeeNo===itm.employeeNo && x.isFloating==="1")
                
            }
            else{
                console.log("parent")
                _tmpData = _inc.filter( x => x.employeeNo===itm.employeeNo && x.isFloating==="0")
                dataInc = _inc.filter( x => x.employeeNo===itm.employeeNo && x.isFloating==="0")
                tmpDvdnd = cbudividn.filter( x => x.employeeNo===itm.employeeNo && x.isFloating==="0")
                
            }
            // dataInc = _tmpData
            
            let _totInc = _tmpData.length>0?_tmpData.filter( x => x.employeeNo===itm.employeeNo).reduce((total, currentValue) => total + parseFloat(String(currentValue.balanceAmount).replace(',','')),0):0
            let _cbu = tmpDvdnd.filter(x=> x.employeeNo===itm.employeeNo && x.isInclusion === "1" && x.deductionId ==="5").length>0 ?
                parseFloat(String(tmpDvdnd.find(x=> x.employeeNo===itm.employeeNo && x.isInclusion === "1" && x.deductionId ==="5").balanceAmount).replace(',','')) : 0
            let _dividend = itm.dividend!=="" ? itm.dividend : tmpDvdnd.filter(x=> x.employeeNo===itm.employeeNo && x.isInclusion === "1" && x.deductionId==="86").length>0 ?
                parseFloat(String(tmpDvdnd.find(x=> x.employeeNo===itm.employeeNo && x.isInclusion === "1").balanceAmount).replace(',','')) : 0


            // COMPUTATIONS
            let _lastSalary = ""
            let _adjustment = ""
            let _otherPayroll = ""
            let _thirteenthMonth = ""
            let _sil = ""
            let _separation = ""

            if(itm.completionDate===""){
                 _lastSalary = LastPayroll.filter(x=> x.employeeId===itm.employeeId).length>0 ?
                    parseFloat(String(LastPayroll.find(x=> x.employeeId===itm.employeeId).lastPayroll).replace(',','')) : 0
                 _adjustment = LastPayroll.filter(x=> x.employeeId===itm.employeeId).length>0 ?
                    parseFloat(String(LastPayroll.find(x=> x.employeeId===itm.employeeId).adjustmentPayroll).replace(',','')) : 0
                 _otherPayroll = LastPayroll.filter(x=> x.employeeId===itm.employeeId).length>0 ?
                    parseFloat(String(LastPayroll.find(x=> x.employeeId===itm.employeeId).otherPayroll).replace(',','')) : 0
                 _thirteenthMonth = LastPayroll.filter(x=> x.employeeId===itm.employeeId).length>0 ?
                    parseFloat(String(LastPayroll.find(x=> x.employeeId===itm.employeeId).thirteenthMonth).replace(',','')) : 0
                 _sil = LastPayroll.filter(x=> x.employeeId===itm.employeeId).length>0 ?
                    parseFloat(String(LastPayroll.find(x=> x.employeeId===itm.employeeId).sil).replace(',','')) : 0
                 _separation = LastPayroll.filter(x=> x.employeeId===itm.employeeId).length>0 ?
                    parseFloat(String(LastPayroll.find(x=> x.employeeId===itm.employeeId).separationPay).replace(',','')) : 0
            }
            else {

                 _lastSalary = itm.lastSalary
                 _adjustment = itm.adjustment
                 _otherPayroll = itm.otherPayroll
                 _thirteenthMonth = itm.thirteenthMonth
                 _sil = itm.sil
                 _separation = itm.separationPay
            }

            let sttlmntFee = itm.settlementFee === "" || typeof itm.settlementFee ==="undefined" ? 0: itm.settlementFee
            let _grossPay = parseFloat(String(_lastSalary)) + parseFloat(String(_adjustment)) + parseFloat(String(_otherPayroll)) + parseFloat(String(_thirteenthMonth)) + parseFloat(String(_sil)) + parseFloat(String(_separation)) + parseFloat(String(_cbu))+ parseFloat(String(_dividend)) + parseFloat(String(sttlmntFee))
            // console.log("_grossPay")
            // console.log(_grossPay)
            let totalGross = parseFloat(_grossPay) + parseFloat(_totInc)
            
            let netPayTmp = parseFloat(totalGross) - parseFloat(String(itm.totalBalance).replace(',',''))
            
            let _netPay = parseFloat(String(netPayTmp).replace(',','')).toFixed(2)
            // let totDeds = parseFloat(itm.clearanceDeductions).reduce

            let hc = idx+1
            modifyDataNegative.push({
                hc : hc,
                batchNumber : itm.batchNumber,
                clientName : itm.clientName,
                employeeNo : itm.employeeNo,
                employeeName : itm.employeeName,
                reasonForLeaving : itm.reasonForLeaving,
                dateStart : itm.dateStart,
                dateEnd : itm.lastWorkingDateMember,
                paycardNumber : itm.cashCardNumberMember,
                cbu : parseFloat(itm.cbu),
                dividend : parseFloat(itm.dividend),
                lastSalary : parseFloat(itm.lastSalary),
                adjustment : parseFloat(itm.adjustment),
                otherPayroll : parseFloat(itm.otherPayroll),
                thirteenthMonth : parseFloat(itm.thirteenthMonth),
                sil : parseFloat(itm.sil),
                separationPay :parseFloat(itm.separationPay),
                settlementFee : itm.settlementFee===""? 0 : parseFloat(itm.settlementFee),
                incentives : parseFloat(itm.incentives),
                grossPay : parseFloat(_grossPay).toFixed(2),
                inclusions : parseFloat(totalGross).toFixed(2),
                totalInclusion : parseFloat(String(_totInc).replace(',','')),
                totalBalance : parseFloat(String(itm.totalBalance).replace(',','')),
                netPay : parseFloat(String(_netPay).replace(',','')),
                noAtm : itm.isCash === "1" || itm.isCheckMember === "1" || itm.PaycardTypeIdMember === "8" ? parseFloat(String(_netPay).replace(',','')) : 0,

                batchPayroll : itm.batchPayroll,
                typeOfCashCardMember : itm.typeOfCashCardMember,
                areRemarks : itm.areRemarks,
                clearanceDateMember : itm.clearanceDateMember,
                clearanceDeductions : itm.clearanceDeductions.sort((a, b) => parseFloat(a.priority) > parseFloat(b.priority) ? 1 : -1),
                clearanceDetails : itm.clearanceDetails,
                clearanceStatus : itm.clearanceStatus,
                clearedSubmit : itm.clearedSubmit,
                clientId : itm.clientId,
                closingRemarks : itm.closingRemarks,
                createdBy : itm.createdBy,
                createdByName : itm.createdByName,
                createdDate : itm.createdDate,
                cycleDays : itm.cycleDays,
                dateCreated : itm.dateCreated,
                dateForwardedToAmt : itm.dateForwardedToAmt,
                dateOfBirth : itm.dateOfBirth,
                dateReceivedByAdmin : itm.dateReceivedByAdmin,
                dateReceivedFromAmt : itm.dateReceivedFromAmt,
                dateReturnedByARE : itm.dateReturnedByARE,
                dateReturnedByClient : itm.dateReturnedByClient,
                dateReturnedByFinance : itm.dateReturnedByFinance,
                dateReturnedByLRD : itm.dateReturnedByLRD,
                dateReturnedByPayroll : itm.dateReturnedByPayroll,
                dateReturnedByRMT : itm.dateReturnedByRMT,
                dateReturnedByServices : itm.dateReturnedByServices,
                dateSettle : itm.dateSettle,
                dateSubmitted : itm.dateSubmitted,
                dateSubmittedByLead : itm.dateSubmittedByLead,
                dateSubmittedByStaff : itm.dateSubmittedByStaff,
                dateUpdatedByARE : itm.dateUpdatedByARE,
                dateUpdatedByClient : itm.dateUpdatedByClient,
                dateUpdatedByFinance : itm.dateUpdatedByFinance,
                dateUpdatedByLRD : itm.dateUpdatedByLRD,
                dateUpdatedByRMT : itm.dateUpdatedByRMT,
                dateUpdatedByServices : itm.dateUpdatedByServices,
                employeeId : itm.employeeId,
                finalized : itm.finalized,
                financeRemarks : itm.financeRemarks,
                firstName : itm.firstName,
                generation : itm.generation,
                // grossPay : itm.grossPay,
                hdmfNumber : itm.hdmfNumber,
                id : itm.id,
                isCOERequestFormMember : itm.isCOERequestFormMember,
                isCash : itm.isCash,
                isCashCardMember : itm.isCashCardMember,
                isCheckMember : itm.isCheckMember,
                isCheckedByRmt : itm.isCheckedByRmt,
                isClearanceStatus : itm.isClearanceStatus,
                isClearedClient : itm.isClearedClient,
                isClearedRmt : itm.isClearedRmt,
                isClearedSubmit : itm.isClearedSubmit,
                isDeleted : itm.isDeleted,
                isEligible : itm.isEligible,
                isEligibleClient : itm.isEligibleClient,
                isFinalized : itm.isFinalized,
                isForwardToARERmt : itm.isForwardToARERmt,
                isGenerated : itm.isGenerated,
                isHDMFNumberMember : itm.isHDMFNumberMember,
                isMlhuillierMember : itm.isMlhuillierMember,
                isModified : itm.isModified,
                isModifiedByARE : itm.isModifiedByARE,
                isNonEligibleClient : itm.isNonEligibleClient,
                isNotEligible : itm.isNotEligible,
                isPHICNumberMember : itm.isPHICNumberMember,
                isPalawanMember : itm.isPalawanMember,
                isResignationLetterMember : itm.isResignationLetterMember,
                isRetrunedServicesRmt : itm.isRetrunedServicesRmt,
                isReturnedRmt : itm.isReturnedRmt,
                isSSSNumberMember : itm.isSSSNumberMember,
                isSettleWithDole : itm.isSettleWithDole,
                isTINNumberMember : itm.isTINNumberMember,
                isTurnoverChecklistMember : itm.isTurnoverChecklistMember,
                isTypeOfCashCardMember : itm.isTypeOfCashCardMember,
                isValidIdMember : itm.isValidIdMember,
                lastName : itm.lastName,
                locationId : itm.locationId,
                locationName : itm.locationName,
                middleName : itm.middleName,
                modifiedBy : itm.modifiedBy,
                modifiedByName : itm.modifiedByName,
                modifiedDate : itm.modifiedDate,
                // netPay : itm.netPay,
                notesMember : itm.notesMember,
                paramountId : itm.paramountId,
                payPeriodId : itm.payPeriodId,
                paycardTypeIdMember : itm.paycardTypeIdMember,
                payrollPeriods : itm.payrollPeriods,
                payrollRemarks : itm.payrollRemarks,
                periodCovered : itm.periodCovered,
                phicNumber : itm.phicNumber,
                positionId : itm.positionId,
                positionName : itm.positionName,
                profileId : itm.profileId,
                reasonForLeaving : itm.reasonForLeaving,
                reasonForLeavingId : itm.reasonForLeavingId,
                remarksClient : itm.remarksClient,
                remarksLrd : itm.remarksLrd,
                remarksMember : itm.remarksMember,
                remarksRmt : itm.remarksRmt,
                resignationDateMember : itm.resignationDateMember,
                returnedByARE : itm.returnedByARE,
                returnedByClient : itm.returnedByClient,
                returnedByFinance : itm.returnedByFinance,
                returnedByLRD : itm.returnedByLRD,
                returnedByRMT : itm.returnedByRMT,
                returnedByServices : itm.returnedByServices,
                reviewStatus : itm.reviewStatus,
                reviewStatusResults : itm.reviewStatusResults,
                rowIndex : itm.rowIndex,
                servicesAttachments : itm.servicesAttachments,
                servicesRemarks : itm.servicesRemarks,
                // sil : itm.sil,
                sssNumber : itm.sssNumber,
                submissionDateMember : itm.submissionDateMember,
                submitted : itm.submitted,
                submittedByLead : itm.submittedByLead,
                submittedByStaff : itm.submittedByStaff,
                suffixName : itm.suffixName,
                timeStamp : itm.timeStamp,
                tinNumber : itm.tinNumber,
                // totalBalance : itm.totalBalance,
                updatedByARE : itm.updatedByARE,
                updatedByClient : itm.updatedByClient,
                updatedByFinance : itm.updatedByFinance,
                updatedByLRD : itm.updatedByLRD,
                updatedByRMT : itm.updatedByRMT,
                updatedByServices : itm.updatedByServices,
                validateByRmt : itm.validateByRmt,
                batchPayroll : itm.batchPayroll,
                dateHired : itm.dateHired,
                contractDateStart : itm.contractDateStart,
                contractDateEnd : itm.contractDateEnd,
                isParent : itm.isParent,
                isChild : itm.isChild,
                parentId : itm.parentId,
                
                dateSubmittedToPayroll : itm.dateSubmittedToPayroll,
                isAre : itm.isAre,
                isPayroll : itm.isPayroll,
                financePayoutDate : itm.financePayoutDate,
                isUpdatedByARE : itm.isUpdatedByARE,
                lastModifiedBy : itm.lastModifiedBy,
                completionDate : itm.completionDate,
                
            })
        })

        let uniqpaycardsNeg = [...new Map(modifyDataNegative.map((item) => [JSON.stringify(item), item])).values(),];
        // let filterInclu = dataInc.filter(f => modifyDataNegative.some(item => f.employeeId === item.employeeId))
        let uniqinclusionNeg = [...new Map(this.state.AreInlcusion.map((item) => [JSON.stringify(item), item])).values(),];
        let empdeductionNeg = []
        modifyDataNegative.forEach(itm=>{
            itm.clearanceDeductions.forEach(ded =>{
                empdeductionNeg.push({
                    deductionId : ded.deductionId,
                    deduction : ded.deduction
                })
            })
        })
        let uniqdeductionNeg = [...new Map(empdeductionNeg.map((item) => [JSON.stringify(item), item])).values(),];
        //ATTACHED DEDUCTIONS & INCLUSION

        // console.log("dataInc")
        // console.log(dataInc)
        modifyDataNegative.sort((a, b) => a.employeeName > b.employeeName ? -1 : 1).forEach(itm =>{
            let _tmpData= []
            
            this.state.deductionMaintenance.sort((a, b) => parseFloat(a.priority) > parseFloat(b.priority) ? 1 : -1).filter(f => uniqdeductionNeg.some(item => item.deductionId === f.id)).forEach(ded=>{
                itm[ded.name]   = itm.clearanceDeductions.filter(x=>x.employeeNo===itm.employeeNo && x.deductionId===ded.id && x.chargesAmount!=="0.00").length>0 ?
                                    parseFloat(String(itm.clearanceDeductions.filter(x=>x.employeeNo===itm.employeeNo && x.deduction===ded.name && x.chargesAmount!=="0.00").reduce((total, currentValue) => total + parseFloat(String(currentValue.balance).replace(',','').replace(',','').replace(',','')),0))) : 0.00
            })
            if(itm.parentId!==""){
            //     console.log("child")
                _tmpData = this.state.AreInlcusion.filter( x => x.isFloating==="1")
            }
            else{
            //     console.log("parent")
                _tmpData = this.state.AreInlcusion.filter( x => x.isFloating==="0")
            }
            this.state.inclusionMaintenance.filter(f => uniqinclusionNeg.some(item => item.deductionId === f.id)).forEach(inc=>{
                itm[inc.name]   = _tmpData.filter(x=>x.employeeNo===itm.employeeNo && x.deductionId===inc.id).length>0 ?
                                        parseFloat(String(_tmpData.find(x=>x.employeeNo===itm.employeeNo && x.deductionId===inc.id).balanceAmount).replace(',','')) : 0.00
            })
            this.state.paycardMaintenance.filter(f => uniqpaycardsNeg.some(item => item.typeOfCashCardMember === f.name)).forEach(pc => {
                itm[pc.name] = itm.typeOfCashCardMember === pc.name ? parseFloat((parseFloat(itm.netPay).toFixed(2))) : 0
            })
        })

        // // // console.log("modifyData")
        // // // console.log(modifyData)

        // GRANDTOTAL

        let newDataNeg = modifyDataNegative
        this.state.unFinalizedArry.forEach(data=>{
            // // console.log(this.state.subsidiaryData.filter(x=> x.employeeId===data.employeeId))
            this.state.deductionMaintenance.filter(f => uniqdeductionNeg.some(item => item.deductionId === f.id)).forEach(mnt=>{
                data[mnt.name] = data.clearanceDeductions.filter(x=> x.employeeId===data.employeeId && x.deductionId===mnt.id).length>0 ?
                                    data.clearanceDeductions.find(x=> x.employeeId===data.employeeId && x.deductionId===mnt.id).balance : 0
            })
            this.state.inclusionMaintenance.filter(f => uniqinclusionNeg.some(item => f.id === item.deductionId)).forEach(mnt=>{
                data[mnt.name] = this.state.AreInlcusion.filter(x=> x.employeeId===data.employeeId && x.deductionId===mnt.id).length>0 ?
                                    this.state.AreInlcusion.find(x=> x.employeeId===data.employeeId && x.deductionId===mnt.id).amount : 0
            })
            this.state.paycardMaintenance.filter(f => uniqpaycardsNeg.some(item => item.typeOfCashCardMember === f.name)).forEach(mnt => {
                data[mnt.name] = mnt.typeOfCashCardMember === data.name ? modifyDataNegative.reduce((total, currentValue) => total + parseFloat(String(currentValue.netPay).replace(',','')),0).toFixed(2) : 0

            })
        })

        const res = modifyDataNegative.reduce((result, item) => {
            const keys = Object.keys(item);
            keys.forEach(key => {
                if (this.state.deductionMaintenance.filter(x=>x.name===key).length>0){
                    result[key] = result[key] ? result[key] + item[key] : item[key];
                }
                if (this.state.inclusionMaintenance.filter(x=>x.name===key).length>0){
                    result[key] = result[key] ? result[key] + item[key] : item[key];
                }
                if (this.state.paycardMaintenance.filter(x=>x.name===key).length>0){
                    result[key] = result[key] ? result[key] + item[key] : item[key];
                }
            });
            return result;
            },
            {
                "id" : "",
                "hc" : "",
                "batchNumber" : "",
                "employeeNo" : "",
                "reasonForLeaving" : "GRAND TOTAL : ",
                "clientName" : "",
                "employeeName" : "",
                "dateEnd" : "",
                "paycardNumber" : "",
                "cbu" : modifyDataNegative.reduce((total, currentValue) => total + parseFloat(String(currentValue.cbu).replace(',','')),0).toFixed(2),
                "dividend" : modifyDataNegative.reduce((total, currentValue) => total + parseFloat(String(currentValue.dividend).replace(',','')),0).toFixed(2),
                "lastSalary" : modifyDataNegative.reduce((total, currentValue) => total + parseFloat(String(currentValue.lastSalary).replace(',','')),0).toFixed(2),
                "adjustment" : modifyDataNegative.reduce((total, currentValue) => total + parseFloat(String(currentValue.adjustment).replace(',','')),0).toFixed(2),
                "otherPayroll" : modifyDataNegative.reduce((total, currentValue) => total + parseFloat(String(currentValue.otherPayroll).replace(',','')),0).toFixed(2),
                "thirteenthMonth" : modifyDataNegative.reduce((total, currentValue) => total + parseFloat(String(currentValue.thirteenthMonth).replace(',','')),0).toFixed(2),
                "sil" : modifyDataNegative.reduce((total, currentValue) => total + parseFloat(String(currentValue.sil).replace(',','')),0).toFixed(2),
                "separationPay" : modifyDataNegative.reduce((total, currentValue) => total + parseFloat(String(currentValue.separationPay).replace(',','')),0).toFixed(2),
                "settlementFee" : isNaN(modifyDataNegative.reduce((total, currentValue) => total + parseFloat(String(currentValue.settlementFee).replace(',','')),0)) ? "" : modifyDataNegative.reduce((total, currentValue) => total + parseFloat(String(currentValue.settlementFee).replace(',','')),0).toFixed(2),
                "inclusions" : modifyDataNegative.reduce((total, currentValue) => total + parseFloat(String(currentValue.inclusions).replace(',','')),0).toFixed(2),
                "grossPay" : modifyDataNegative.reduce((total, currentValue) => total + parseFloat(String(currentValue.grossPay).replace(',','')),0).toFixed(2),
                "totalInclusion" : modifyDataNegative.reduce((total, currentValue) => total + parseFloat(String(currentValue.totalInclusion).replace(',','')),0).toFixed(2),
                "totalBalance" : modifyDataNegative.reduce((total, currentValue) => total + parseFloat(String(currentValue.totalBalance).replace(',','')),0).toFixed(2),
                "netPay" : modifyDataNegative.reduce((total, currentValue) => total + parseFloat(String(currentValue.netPay).replace(',','')),0).toFixed(2),
                "noAtm" : modifyDataNegative.reduce((total, currentValue) => total + parseFloat(String(currentValue.noAtm).replace(',','')),0).toFixed(2),
            });
            modifyDataNegative.push(res);


        // // console.log("modifyDataNegative")
        // // console.log(modifyDataNegative)


        this.setState({
            // unFinalizedArry : modifyData,
            // dsabldBttn : modifyData.length>0?false:true,
            // srchPositve : modifyData.concat(grandTotalPos),
            srchNgtve : modifyDataNegative,
            isloading : false
        })
    }
    FilterBatch = async() =>{
        let filterbatch = this.state.payrollDataArry.filter (x => x.batchNumber === this.state.batchNumber)
        this.setState({ payrollDataArry : filterbatch})
    };
    handleChangeClient = (e) => {
        if(e.length == 0) {
            this.state.clientId     =   ""
            this.state.clientName   =   ""
            return
        }
        this.state.clientId     =   e[0].id
        this.state.clientName   =   e[0].name
        this.setState({
            isshow:false,

        })
        this.GetEmployees();
        // this.refBatch.current.clear()
    };
    onChangeBatchNumber = async(e) =>{

        let BatchList = [];
        for(let i = 0; i < e.length; i++){
            // // // // // // console.log("e")
            // // // // // // console.log(e)
            const obj = {
                "batchPayroll" : e[i]["batchPayroll"]
            }
            BatchList.push(obj)
            this.setState({
                batchList : BatchList,
                isshow      :   false,
                employeeId : "",
            })
        }
    };
    handleChangeEmployee = (e) => {

        if(e.length == 0) {
            this.state.employeeId   =   ""
            this.state.profileId    =   ""
            this.state.client       =   ""
            this.state.lastName     =   ""
            this.state.firstName    =   ""
            this.state.middleName   =   ""
            this.state.dateOfBirth  =   ""
            this.state.positionName =   ""
            this.state.contractDateStart  =   ""
            this.state.phicNo = ""
            this.state.sssNo = ""
            this.state.tinNo = ""
            this.state.pagibigNo = ""
        }
        else {

            this.state.employeeId   =   e[0].id
            this.state.profileId    =   e[0].profileId
            this.state.client       =   e[0].clientName
            this.state.lastName     =   e[0].lastName
            this.state.firstName    =   e[0].firstName
            this.state.middleName   =   e[0].middleName
            this.state.dateOfBirth  =   e[0].dateOfBirth
            this.state.positionName =   e[0].position
            this.state.locationName =   e[0].locationName
            this.state.contractDateStart  =   e[0].contractDateStart
            this.state.phicNo       =   e[0].phicNumber
            this.state.sssNo        =   e[0].sssNumber
            this.state.tinNo        =   e[0].tinNumber
            this.state.pagibigNo    =   e[0].hdmfNumber
        }
        this.getEmployeeClearance();
        // // // // console.log(e[0].employeeId)
        if(this.state.employeeId===""){
            this.setState({ employeeIdSrch : ""})
        }
        else {
            this.setState({ employeeIdSrch : e[0].employeeId})
        }
    };
    getEmployeeClearance = async() =>{
        let param = {
            "IpAddress"             :   "0.0.0.0",
            "UserId"                :   this.state.userinfo.userId,
            "ClientId"              :   this.state.userinfo.clientId,
            "ProfileId"             :   this.state.userinfo.profileId,
            "DisengagementModeId"   :   this.state.disengagementModeId,
            "ClearanceDate"         :   this.state.clearanceDate,
            "ResignationDate"       :   this.state.resignationDate,
            "SubmissionDate"        :   this.state.submissionDate,
            "LastWorkingDate"       :   this.state.workingDate,
            "ReasonForLeaving"      :   this.state.reason
        };
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Clearance/GetClearance",  param)
        .then(res => {
            let data = res.data.clearance;

            if(data.status==="0"){
                this.setState(  {
                    isloading       :   false,
                    alerttype       :   "Error!",
                    isshow          :   true,
                    color           :   "danger",
                    message         :   data.message,
                    fade            :   true
                })
            }
        })
        .catch(error=>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
    };
    handleChangedReason = (e) => {
        if (e.length === 0){
            this.setState({ reason : "" })
        } else {
            this.setState({ reason : e[0].name })
        }
    };
    handleClickExport = () =>{

    };
    handleClickUnfinalized = async() =>{
        // let arr = []
        this.setState({isloading:true})
        let date = (this.state.datenow) ? moment(this.state.datenow).format('MM/DD/YYYY') : ""
        let slctdData = this.state.unFinalizedArry.filter(f => this.state.selectedRowKeys.some(item => item === f.employeeNo))
        // // // // console.log('this.state.unFinalizedArry')
        // // // // console.log(this.state.unFinalizedArry)
        // // // // console.log("this.state.selectedRowKeys")
        // // // // console.log(this.state.selectedRowKeys)
        console.log("slctdData")
        console.log(slctdData)
        // if(slctdData.length === 0){
        //     this.setState({
        //         isloading   :   false,
        //         alerttype   :   "Error!",
        //         isshow      :   true,
        //         color       :   "danger",
        //         message     :   "Please select atleast 1 to process",
        //         fade        :   true
        //     })
        //     return
        // }
        let arrLst = []
        slctdData.filter(x=>x.employeeNo!=="" && x.completionDate ==="").forEach(itm => {

            let _clearanceStatus = ""

            if ( itm.isClearanceStatus === "0"|| itm.isClearanceStatus === "1"|| itm.isClearanceStatus === "2"){
                if (itm.reasonForLeaving === "DOLE CASES"){
                    _clearanceStatus = "3"
                }
                else {
                    _clearanceStatus = "4"
                }
            }
            else{
                _clearanceStatus = String(parseFloat(itm.isClearanceStatus)  + 1)
            }
            let _userId = this.state.userinfo.userId
            arrLst.push({
                "Id" : itm.id,
                "ClientId":itm.clientId,
                "ProfileId":itm.profileId,
                "EmployeeId":itm.employeeId,
                "PayPeriodId":itm.payPeriodId,
                "DateCreated":(itm.dateCreated) ? moment(itm.dateCreated).format('MM/DD/YYYY') : "",
                "ClearanceDateMember":(itm.clearanceDateMember) ? moment(itm.clearanceDateMember).format('MM/DD/YYYY') : "",
                "ResignationDateMember":(itm.resignationDateMember) ? moment(itm.resignationDateMember).format('MM/DD/YYYY') : "",
                "SubmissionDateMember":(itm.submissionDateMember) ? moment(itm.submissionDateMember).format('MM/DD/YYYY') : "",
                "LastWorkingDateMember":(itm.lastWorkingDateMember) ? moment(itm.lastWorkingDateMember).format('MM/DD/YYYY') : "",
                "DateSubmitted":(itm.dateSubmitted) ? moment(itm.dateSubmitted).format('MM/DD/YYYY') : "",
                "IsSSSNumberMember":itm.isSSSNumberMember,
                "IsTINNumberMember":itm.isTINNumberMember,
                "IsPHICNumberMember":itm.isPHICNumberMember,
                "IsHDMFNumberMember":itm.isHDMFNumberMember,
                "IsCashCardMember":itm.isCashCardMember,
                "IsValidIdMember":itm.isValidIdMember,
                "IsResignationLetterMember":itm.isResignationLetterMember,
                "IsTurnoverChecklistMember":itm.isTurnoverChecklistMember,
                "IsCOERequestFormMember":itm.isCOERequestFormMember,
                "IsTypeOfCashCardMember":itm.isTypeOfCashCardMember, // cash card mode of payment
                "PaycardTypeIdMember":itm.paycardTypeIdMember,
                "TypeOfCashCardMember":itm.typeOfCashCardMember,
                "CashCardNumberMember":itm.paycardNumber,
                "IsCheckMember":itm.isCheckMember,
                "IsMlhuillierMember":itm.isMlhuillierMember,
                "IsPalawanMember":itm.isPalawanMember,
                "RemarksMember":itm.remarksMember,
                "NotesMember":itm.notesMember,
                "ReasonForLeaving":itm.reasonForLeaving,
                "DateForwardedToAmt": itm.dateForwardedToAmt,
                "DateReceivedFromAmt": itm.dateReceivedFromAmt,
                "IsEligible":itm.isEligible,
                "IsNotEligible":itm.isNotEligible,
                "SssNumber": itm.sssNumber,
                "TinNumber": itm.tinNumber,
                "PhicNumber": itm.phicNumber,
                "HdmfNumber": itm.hdmfNumber,
                "IsClearedClient":itm.isClearedClient,
                "IsEligibleClient":itm.isEligibleClient,
                "IsNonEligibleClient":itm.isNonEligibleClient,
                "RemarksClient":itm.remarksClient,

                "IsClearedRmt":itm.isNonEligibleClient,
                "IsReturnedRmt":itm.isNonEligibleClient,
                "ValidateByRmt":itm.isNonEligibleClient,
                "IsForwardToARERmt":itm.isNonEligibleClient ,
                "IsRetrunedServicesRmt":itm.isNonEligibleClient,
                "IsCheckedByRmt":itm.isCheckedByRmt,
                "RemarksRmt":itm.remarksRmt,

                "RemarksLrd":itm.remarksLrd,
                "ServicesRemarks":itm.servicesRemarks ,

                "IsClearanceStatus": "7",
                "clearanceDetails" :itm.clearanceDetails,

                /* ADDITIONAL */
                "DateUpdatedByClient": date,
                "UpdatedByClient":_userId,

                "DateUpdatedByLRD":date,
                "UpdatedByLRD":_userId,

                "DateUpdatedByRMT":date,
                "UpdatedByRMT":_userId,

                "DateUpdatedByServices":itm.dateUpdatedByServices,
                "UpdatedByServices":_userId,

                "DateUpdatedByARE":itm.dateUpdatedByARE,
                "UpdatedByARE":itm.isUpdatedByARE,

                "DateUpdatedByFinance":date,
                "UpdatedByFinance":_userId,
                "DateSubmittedByStaff":date,
                "SubmittedByStaff": _userId,
                "DateSubmittedByLead":date,
                "SubmittedByLead":_userId,

                "DateReturnedByClient":itm.dateReturnedByClient,
                "ReturnedByClient":_userId,
                "DateReturnedByLRD":itm.dateReturnedByLRD,
                "ReturnedByLRD":_userId,
                "DateReturnedByRMT":itm.dateReturnedByRMT,
                "ReturnedByRMT":_userId,
                "DateReturnedByServices":itm.dateReturnedByServices,
                "ReturnedByServices":_userId,
                "DateReturnedByARE":itm.dateReturnedByARE,
                "ReturnedByARE":_userId,
                "DateReturnedByFinance":itm.dateReturnedByFinance,
                "ReturnedByFinance":_userId,

                "DateSettle"        : itm.dateSettle,
                "IsSettleWithDole"  :  itm.isSettleWithDole,

                "DateReceivedByAdmin" : itm.dateReceivedByAdmin,
                "IsCash" : itm.isCash,
                "BatchNumber" : itm.batchNumber,
                "servicesAttachments" : itm.servicesAttachments,
                "clearanceDeductions":itm.clearanceDeductions,


                // 21/07/2023
                "ParamountId" : itm.paramountId === "" ? "0" : itm.paramountId,
                "LastSalary" : String(itm.lastSalary),
                "Incentives" : String(itm.incentives),
                "Sil" : String(itm.sil),
                "ThirteenthMonth" : String(itm.thirteenthMonth),
                "Inclusions" : String(itm.inclusions),
                "GrossPay" : String(itm.grossPay),
                "NetPay" : String(itm.netPay),
                "totalBalance" : String(itm.totalBalance),
                "PeriodCovered" : itm.periodCovered,
                "IsModifiedByARE" : "0",
                "SeparationPay" : itm.separationPay,
                // "ReviewStatus" : "0",
                "DateReturnedByPayroll" : "",
                "AreRemarks" : itm.areRemarks,
                "PayrollRemarks" : itm.payrollRemarks,
                "FinanceRemarks" : itm.financeRemarks,
                // "IsClearedSubmit" : "0",
                "IsGenerated" : itm.isGenerated,
                "IsFinalized" : "0",
                // "ContractDateStart"  : typeof itm.contractDateStart === "undefined" ? "" : itm.contractDateStart,
                // "ContractDateEnd"  : typeof itm.contractDateEnd === "undefined" ? "" : itm.contractDateEnd,
                "DateResigned" : typeof itm.dateResigned === "undefined" ? "" : itm.dateResigned,
                "DateHired" : typeof itm.dateHired === "undefined" ? "" : itm.dateHired,
                'BatchPayroll' : itm.batchPayroll,
                "Adjustment" : typeof itm.adjustment === "undefined" ? "" : itm.adjustment,
                "OtherPayroll" : typeof itm.otherPayroll === "undefined" ? "" : itm.otherPayroll,
                "Cbu" : typeof itm.cbu === "undefined" ? "" : itm.cbu,
                "Dividend" : typeof itm.dividend === "undefined" ? "" : itm.dividend,
                "TotalInclusion" : String(itm.totalInclusion),
                "IsParent" : itm.isParent,
                "IsChild" : itm.isChild,
                "ParentId" : itm.parentId,
                "SettlementFee" : String(itm.settlementFee),

                "FinancePayoutDate" : itm.financePayoutDate,
                "dateSubmittedToPayroll" : itm.dateSubmittedToPayroll,
                
                "ReviewStatus" : "2",
                "IsClearedSubmit" : "1",
                "IsAre" : "1",
                "IsPayroll" : "1",
                "LastModifiedBy" : this.state.userinfo.fullName
                /* END */

            })
            this.setState({ profileId : itm.profileId })
        })
        let param = {
            "IpAddress"             :"0.0.0.0",
            "UserId"                :this.state.userinfo.userId,
            "ClientId"              :this.state.userinfo.clientId,
            "clearance"             :arrLst.filter(x=>x.netPay!=="")
        }
        console.log("unfinalized payroll param")
        console.log(param)

        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Clearance/EditClearance",  param)
        .then(res => {
            let data = res.data;
            this.setState({
                isloading   :   false,
                alerttype   :   res.data.status=="1" ? "Success!" : "!Error",
                isshow      :   true,
                color       :   res.data.status=="1" ? "success" : "danger",
                message     :   data.message,
                fade        :   true,
                srchNgtve   :   [],
                srchPositve :   []
            });
            this.GetDataClearance();
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
    };

    // dev express

    onCellPrepared =(e)=>{
        // // // // // console.log("e")
        // // // // // console.log(e)

        // if (!e.column.allowEditing) {
        //     e.cellElement.css('background-color', 'yellow', 'important');
        //     // e.cellElement.toggleClass('green');
        //     //e.cellElement[0].style.setProperty('background-color', '#DDDDDD', 'important');
        // }

        e.cellElement.style.cssText = "text-transform: lowercase;"
        if(e.rowType==="data"){
            e.cellElement.align = "center"
            if(e.column.dataField ==="CBU" ){
            e.cellElement.previousElementSibling.align = "center"
                e.cellElement.style.cssText = "text-align: left;width:300px"
            }
            else if(e.column.dataField ==="SIL" ){
                e.cellElement.style.cssText = "text-align: left;width:450px"
            }
            e.values.forEach((x,idx)=>{
                e.values[idx] = e.values[idx] === undefined ? 0 : e.values[idx]
            })
        }
        if(e.rowType==="header"){
            if(e.column.dataField ==="CBU" ){
                e.cellElement.style.cssText = "text-align: left;width:300px"
            }
            else if(e.column.dataField ==="SIL" ){
                e.cellElement.style.cssText = "text-align: left;width:450px"
            }
            e.cellElement.align = "center"
            e.cellElement.textContent = e.cellElement.textContent !==undefined ? e.cellElement.textContent.toUpperCase() : e.cellElement.textContent
        }
        if(e.rowType==="totalFooter"){
            // // // // // console.log("e.column.dataField")
            // // // // // console.log(e.column)
            if(e.column.dataField!=="batchNumber" &&
                e.column.dataField!=="clientName" &&
                e.column.dataField!=="employeeNo" &&
                e.column.dataField!=="employeeName" &&
                e.column.dataField!=="paycardNumber" &&
                e.column.dataField!=="dateEnd" &&
                e.column.dataField!=="hc" &&
                e.column.dataField!=="undefined"  &&
                e.column.dataField!==undefined
                /* e.column.dataField!=="seaRate" &&
                e.column.dataField!=="eColaRate" */){
                e.summaryItems =[{
                    column:e.column.dataField,
                    summaryType:"sum",
                    alignment:"right",
                    displayFormat: "{0}"
                }]
                e.totalItem.summaryCells[e.columnIndex] = [{
                    column:e.column.dataField,
                    summaryType:"sum",
                    alignment:"right",
                    displayFormat: "{0}"
                }]
                e.cellElement.innerText = parseFloat(this.state.srchPositve.reduce(( total, currentValue) => total = parseFloat(total) + parseFloat(String(currentValue[e.column.dataField]).replace(',','')),0)).toFixed(2);
                e.cellElement.textContent = parseFloat(this.state.srchPositve.reduce(( total, currentValue) => total = parseFloat(total) + parseFloat(String(currentValue[e.column.dataField]).replace(',','')),0)).toFixed(2);
            }
            //e.cellElement.style.backgroundColor = 'red'
            e.cellElement.style.alignContent = 'right'
            e.cellElement.style.cssText = "text-align: right"
            e.cellElement.align = "right"
        }
    };
    handleOptionChange =(e)=>{ // select items
        if(e.fullName === 'selectedRowKeys') {
            this.setState({
                selectedRowKeys: e.value
            })
        }
    };
    customizeColumns = (cols) => {
        // // // // // // // // // console.log("cols")
        // // // // // // // // // console.log(cols)
        let colHide = [
            "id","areRemarks","clearanceDateMember","clearanceDetails","clearanceDeductions","clearanceStatus","clearedSubmit","clientId","closingRemarks","createdBy","createdByName","createdDate","cycleDays","dateCreated",
            "dateOfBirth","dateReceivedByAdmin","dateReceivedFromAmt","dateReturnedByARE","dateReturnedByClient","dateReturnedByFinance","dateReturnedByLRD","dateReturnedByPayroll","dateReturnedByRMT","dateReturnedByServices",
            "dateSettle","dateStart","dateSubmitted","dateSubmittedByLead","dateSubmittedByStaff","dateUpdatedByARE","dateUpdatedByClient","dateUpdatedByFinance","dateUpdatedByLRD","dateUpdatedByRMT","dateUpdatedByServices",
            "employeeId","finalized","financeRemarks","firstName","generation","hdmfNumber","id","isCOERequestFormMember","isCash","isCashCardMember","isCheckMember","isCheckedByRmt","isClearanceStatus","isClearedClient","isClearedRmt",
            "isClearedSubmit","isDeleted","isEligible","isEligibleClient","isFinalized","isForwardToARERmt","isGenerated","isHDMFNumberMember","isModified","isModifiedByARE","isNonEligibleClient","isNotEligible","isPHICNumberMember",
            "isResignationLetterMember","isRetrunedServicesRmt","isReturnedRmt","isSSSNumberMember","isSettleWithDole","isTINNumberMember","isTurnoverChecklistMember","isTypeOfCashCardMember","isValidIdMember","lastName","locationId",
            "locationName","middleName","modifiedBy","modifiedByName","modifiedDate","notesMember","paramountId","payPeriodId","paycardTypeIdMember","payrollPeriods","payrollRemarks","periodCovered","phicNumber","positionId","positionName",
            "profileId",/* "reasonForLeaving", */"reasonForLeavingId","remarksClient","remarksLrd","remarksMember","remarksRmt","resignationDateMember","returnedByARE","returnedByClient",'returnedByFinance',"returnedByLRD","returnedByRMT",
            "returnedByServices","reviewStatus","reviewStatusResults","rowIndex","servicesAttachments","servicesRemarks","sssNumber","submissionDateMember","submitted","submittedByLead","submittedByStaff","suffixName","timeStamp",
            "tinNumber","updatedByARE","updatedByClient","updatedByFinance","updatedByLRD","updatedByRMT","updatedByServices","validateByRmt","clearanceDeductions","isUpdatedByClient","isUpdatedByLRD","isUpdatedByRMT",
            "isUpdatedByServices","isUpdatedByARE","isUpdatedByFinance","isReturnedByClient","isReturnedByLRD","isReturnedByRMT","isReturnedByServices","isReturnedByARE","isReturnedByFinance","isSubmittedByStaff","isSubmittedByLead",
            "dateForwardedToAmt","isPalawanMember","isMlhuillierMember","typeOfCashCardMember","incentives","batchPayroll", "contractDateStart","contractDateEnd","dateHired","completionDate", "dateResigned","isParent","isChild","parentId",
            ,"dateSubmittedToPayroll","isAre","isPayroll","financePayoutDate","lastModifiedBy", "dividend"
        ]
        let dedCol = []
        let incCol = []
        let pcCol = []
        let totInc = {}
        let thrtntmnth = {}
        let totIdx = 0
        let totNetpay = {}
        let totDed = {}
        let totGross ={}
        let noAtm = {}

        cols.forEach((fld,idx) => {
            // // // // console.log('fld')
            // // // // console.log(fld)
            if(colHide.includes(fld.dataField)){
                fld.visible = false
            } else {
                fld.visible = true
            }
            // // // // // // // // console.log("fld.caption")
            // // // // // // // // console.log(typeof fld.caption === "undefined")
            fld.caption = typeof fld.caption === "undefined" ? "" :  (fld.caption).toUpperCase()
            // if(fld.dataField==="sil"){

            // }
            // if(fld.dataField==="cbu"){

            // }
            if(fld.dataField==="thirteenthMonth"){
                // delete cols[idx];
                // totIdx = idx
                fld.caption = "13TH MONTH"
                thrtntmnth = fld
            }
            if(fld.dataField==="settlementFee"){
                // delete cols[idx];
                // totIdx = idx
                fld.caption = "FINANCIAL ASSISTANCE"
                thrtntmnth = fld
            }
            if(fld.dataField==="inclusions"){
                delete cols[idx];
                totIdx = idx
                fld.caption = "TOTAL GROSS"
                totGross = fld
            }
            if(fld.dataField==="totalInclusion"){
                delete cols[idx];
                totIdx = idx
                fld.caption = "TOTAL INCLUSION"
                totInc = fld
            }
            if(fld.dataField==="netPay"){
                delete cols[idx];
                totIdx = idx
                fld.caption = "NET PAY"
                totNetpay = fld
            }
            if(fld.dataField==="totalBalance"){
                delete cols[idx];
                totIdx = idx
                fld.caption = "TOTAL DEDUCTIONS"
                totDed = fld
            }
            if(fld.dataField==="noAtm"){
                delete cols[idx];
                totIdx = idx
                fld.caption = "NO ATM"
                noAtm = fld
            }

            if(this.state.deductionMaintenance.filter(x=>x.name === fld.dataField).length>0){
                    //fld.visible = false
                    delete cols[idx];
                    dedCol.push(fld.dataField)
                    //// // // // // // // // console.log(idx)
                    //cols.splice(idx,1)

            }
            else if(this.state.inclusionMaintenance.filter(x=>x.name === fld.dataField).length>0){
                // if(fld.dataField === this.state.inclusionMaintenance[0].InclusionName){
                //     // // // // // // // // console.log(idx)
                // }
                delete cols[idx];
                incCol.push(fld.dataField)
                //cols.splice(idx,1)
            }
            else if(this.state.paycardMaintenance.filter(x=>x.name === fld.dataField).length>0){
                // if(fld.dataField === this.state.inclusionMaintenance[0].InclusionName){
                //     // // // // // // // // console.log(idx)
                // }
                delete cols[idx];
                pcCol.push(fld.dataField)
                //cols.splice(idx,1)
            }

        });
        cols.push({
            caption : "INCLUSIONS",
            columns : incCol
        })
        cols.push(totInc)
        cols.push(totGross)
        cols.push({
            caption : "DEDUCTIONS",
            columns : dedCol
        })
        cols.push(totDed)
        cols.push(totNetpay)
        // cols.push({
        //     caption:"ACTION",
        //     cellTemplate : "cellContent"

        // })
        // cols.push(dedtotal)

        // cols.push(inctotal)
        cols.push({
            caption : "TAG AMOUNT OF PAYCARD",
            columns : pcCol
        })
        cols.push(noAtm)
        // cols.push({caption:"TOTAL INCLUSIONS"})
    };
    onCellPreparedNegative =(e)=>{

        e.cellElement.style.cssText = "text-transform: lowercase;"
        if(e.rowType==="data"){
            e.cellElement.align = "center"
            if(e.column.dataField ==="CBU" ){
                e.cellElement.style.cssText = "text-align: left;width:300px"
            }
            else if(e.column.dataField ==="SIL" ){
                e.cellElement.style.cssText = "text-align: left;width:450px"
            }
            e.values.forEach((x,idx)=>{
                e.values[idx] = e.values[idx] === undefined ? 0 : e.values[idx]
            })
        }
        if(e.rowType==="header"){
            if(e.column.dataField ==="CBU" ){
                e.cellElement.style.cssText = "text-align: left;width:300px"
            }
            else if(e.column.dataField ==="SIL" ){
                e.cellElement.style.cssText = "text-align: left;width:450px"
            }
            e.cellElement.align = "center"
            e.cellElement.textContent = e.cellElement.textContent !==undefined ? e.cellElement.textContent.toUpperCase() : e.cellElement.textContent
        }
        if(e.rowType==="totalFooter"){
            if(e.column.dataField!=="batchNumber" &&
                e.column.dataField!=="clientName" &&
                e.column.dataField!=="employeeNo" &&
                e.column.dataField!=="employeeName" &&
                e.column.dataField!=="paycardNumber" &&
                e.column.dataField!=="dateEnd" &&
                e.column.dataField!=="hc" &&
                e.column.dataField!=="" &&
                e.column.dataField!==undefined
                /* e.column.dataField!=="seaRate" &&
                e.column.dataField!=="eColaRate" */){
                e.summaryItems =[{
                    column:e.column.dataField,
                    summaryType:"sum",
                    alignment:"right",
                    displayFormat: "{0}"
                }]
                e.totalItem.summaryCells[e.columnIndex] = [{
                    column:e.column.dataField,
                    summaryType:"sum",
                    alignment:"right",
                    displayFormat: "{0}"
                }]
                e.cellElement.innerText = parseFloat(this.state.srchNgtve.reduce(( total, currentValue) => total = parseFloat(total) + parseFloat(String(currentValue[e.column.dataField]).replace(',','')),0)).toFixed(2);
                e.cellElement.textContent = parseFloat(this.state.srchNgtve.reduce(( total, currentValue) => total = parseFloat(total) + parseFloat(String(currentValue[e.column.dataField]).replace(',','')),0)).toFixed(2);
            }
            //e.cellElement.style.backgroundColor = 'red'
            e.cellElement.style.alignContent = 'right'
            e.cellElement.style.cssText = "text-align: right"
            e.cellElement.align = "right"
        }
    };
    handleOptionChangeNegative =(e)=>{ // select items
        if(e.fullName === 'selectedRowKeys') {
            this.setState({
                selectedRowKeys: e.value
            })
        }
    };
    customizeColumnsNegative = (cols) => {
        // // // // // // // // // console.log("cols")
        // // // // // // // // // console.log(cols)
        let colHide = [
            "id","areRemarks","clearanceDateMember","clearanceDetails","clearanceDeductions","clearanceStatus","clearedSubmit","clientId","closingRemarks","createdBy","createdByName","createdDate","cycleDays","dateCreated",
            "dateOfBirth","dateReceivedByAdmin","dateReceivedFromAmt","dateReturnedByARE","dateReturnedByClient","dateReturnedByFinance","dateReturnedByLRD","dateReturnedByPayroll","dateReturnedByRMT","dateReturnedByServices",
            "dateSettle","dateStart","dateSubmitted","dateSubmittedByLead","dateSubmittedByStaff","dateUpdatedByARE","dateUpdatedByClient","dateUpdatedByFinance","dateUpdatedByLRD","dateUpdatedByRMT","dateUpdatedByServices",
            "employeeId","finalized","financeRemarks","firstName","generation","hdmfNumber","id","isCOERequestFormMember","isCash","isCashCardMember","isCheckMember","isCheckedByRmt","isClearanceStatus","isClearedClient","isClearedRmt",
            "isClearedSubmit","isDeleted","isEligible","isEligibleClient","isFinalized","isForwardToARERmt","isGenerated","isHDMFNumberMember","isModified","isModifiedByARE","isNonEligibleClient","isNotEligible","isPHICNumberMember",
            "isResignationLetterMember","isRetrunedServicesRmt","isReturnedRmt","isSSSNumberMember","isSettleWithDole","isTINNumberMember","isTurnoverChecklistMember","isTypeOfCashCardMember","isValidIdMember","lastName","locationId",
            "locationName","middleName","modifiedBy","modifiedByName","modifiedDate","notesMember","paramountId","payPeriodId","paycardTypeIdMember","payrollPeriods","payrollRemarks","periodCovered","phicNumber","positionId","positionName",
            "profileId",/* "reasonForLeaving", */"reasonForLeavingId","remarksClient","remarksLrd","remarksMember","remarksRmt","resignationDateMember","returnedByARE","returnedByClient",'returnedByFinance',"returnedByLRD","returnedByRMT",
            "returnedByServices","reviewStatus","reviewStatusResults","rowIndex","servicesAttachments","servicesRemarks","sssNumber","submissionDateMember","submitted","submittedByLead","submittedByStaff","suffixName","timeStamp",
            "tinNumber","updatedByARE","updatedByClient","updatedByFinance","updatedByLRD","updatedByRMT","updatedByServices","validateByRmt","clearanceDeductions","isUpdatedByClient","isUpdatedByLRD","isUpdatedByRMT",
            "isUpdatedByServices","isUpdatedByARE","isUpdatedByFinance","isReturnedByClient","isReturnedByLRD","isReturnedByRMT","isReturnedByServices","isReturnedByARE","isReturnedByFinance","isSubmittedByStaff","isSubmittedByLead",
            "dateForwardedToAmt","isPalawanMember","isMlhuillierMember","typeOfCashCardMember","incentives","batchPayroll", "contractDateStart","contractDateEnd","dateHired","","completionDate", "dateResigned","isParent","isChild","parentId"
            ,"dateSubmittedToPayroll","isAre","isPayroll","financePayoutDate","lastModifiedBy", "dividend"
        ]
        let dedCol = []
        let incCol = []
        let pcCol = []
        let totInc = {}
        let thrtntmnth = {}
        let totIdx = 0
        let totNetpay = {}
        let totDed = {}
        let totGross = {}
        let noAtm = {}

        cols.forEach((fld,idx) => {
            // // // // // // console.log('fld')
            // // // // // // console.log(fld)
            if(colHide.includes(fld.dataField)){
                fld.visible = false
            } else {
                fld.visible = true
            }
            // // // // // // // // console.log("fld.caption")
            // // // // // // // // console.log(typeof fld.caption === "undefined")
            fld.caption = typeof fld.caption === "undefined" ? "" :  (fld.caption).toUpperCase()

            if(fld.dataField==="thirteenthMonth"){
                // delete cols[idx];
                // totIdx = idx
                fld.caption = "13TH MONTH"
                thrtntmnth = fld
            }
            if(fld.dataField==="settlementFee"){
                // delete cols[idx];
                // totIdx = idx
                fld.caption = "FINANCIAL ASSISTANCE"
                thrtntmnth = fld
            }
            if(fld.dataField==="inclusions"){
                delete cols[idx];
                totIdx = idx
                fld.caption = "TOTAL GROSS"
                totGross = fld
            }
            if(fld.dataField==="totalInclusion"){
                delete cols[idx];
                totIdx = idx
                fld.caption = "TOTAL INCLUSION"
                totInc = fld
            }
            if(fld.dataField==="netPay"){
                delete cols[idx];
                totIdx = idx
                fld.caption = "NET PAY"
                totNetpay = fld
            }
            if(fld.dataField==="totalBalance"){
                delete cols[idx];
                totIdx = idx
                fld.caption = "TOTAL DEDUCTIONS"
                totDed = fld
            }
            if(fld.dataField==="noAtm"){
                delete cols[idx];
                totIdx = idx
                fld.caption = "NO ATM"
                noAtm = fld
            }

            if(this.state.deductionMaintenance.filter(x=>x.name === fld.dataField).length>0){
                    //fld.visible = false
                    delete cols[idx];
                    dedCol.push(fld.dataField)
                    //// // // // // // // // console.log(idx)
                    //cols.splice(idx,1)

            }
            else if(this.state.inclusionMaintenance.filter(x=>x.name === fld.dataField).length>0){
                // if(fld.dataField === this.state.inclusionMaintenance[0].InclusionName){
                //     // // // // // // // // console.log(idx)
                // }
                delete cols[idx];
                incCol.push(fld.dataField)
                //cols.splice(idx,1)
            }
            else if(this.state.paycardMaintenance.filter(x=>x.name === fld.dataField).length>0){
                // if(fld.dataField === this.state.inclusionMaintenance[0].InclusionName){
                //     // // // // // // // // console.log(idx)
                // }
                delete cols[idx];
                pcCol.push(fld.dataField)
                //cols.splice(idx,1)
            }

        });
        cols.push({
            caption : "INCLUSIONS",
            columns : incCol
        })
        cols.push(totInc)
        cols.push(totGross)
        cols.push({
            caption : "DEDUCTIONS",
            columns : dedCol
        })
        cols.push(totDed)
        cols.push(totNetpay)
        cols.push({
            caption:"ACTION",
            cellTemplate : "cellContent"

        })
        // cols.push(dedtotal)

        // cols.push(inctotal)
        cols.push({
            caption : "TAG AMOUNT OF PAYCARD",
            columns : pcCol
        })
        cols.push(noAtm)
    };
    onExporting(e){
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet('ClearanceSummary');

        exportDataGrid({
            component: e.component,
            worksheet,
            location: 'before',
            topLeftCell: { row: 1, column: 1 },
            // customizeCell: function(options) {
            //     const excelCell = options;
            //     excelCell.font = { name: 'Arial', size: 12 };
            //     excelCell.alignment = { horizontal: 'left' };
            // }
        }).then((cellRange) => {
            }).then(() => {
                workbook.xlsx.writeBuffer().then((buffer) => {
                saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'ClearanceSummary.xlsx');
            });
        });
    };
    onExportingNegative(e){
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet('NegativeClaims');

        exportDataGrid({
          component: e.component,
          worksheet,
          topLeftCell: { row: 4, column: 1 },
        }).then((cellRange) => {
        }).then(() => {
          workbook.xlsx.writeBuffer().then((buffer) => {
            saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'NegativeClaims.xlsx');
          });
        });
    };
    calculateSelectedRow (options){
        // // // // console.log("options")
        // // // // console.log(options)
    };
    // onRowFocus =(e)=>{
    //     // // // // console.log("e")
    //     // // // // console.log(e)
    // }
    onFocusedRowChanged(e){
        e.rowElement[0].style.backgroundColor = "yellow";
    };
    // onSelectionChanged(e){
    //     const pageCount = e.component.pageCount();
    //     if (pageCount > 1) { // if not all data is loaded
    //         return;
    //     }

    //     const visibleRows = e.component.getVisibleRows();
    //     if (visibleRows.every(row => !row.isSelected)) {
    //         this.option('selectionFilter', []);
    //     }
    // }
    render() {
        var nf = new Intl.NumberFormat();
        let { navigate } = this.state;
        if(navigate){
            return <Redirect to="/clearance" push={true}/>
        };

        // let { SearchBar, ClearSearchButton } = Search;
        // const { allMode, checkBoxesMode } = this.state;
        return(
            <div>
                <Banner />
                <Container className="mt-5" fluid>
                    <Card>
                        <Card.Header>CLEARANCE &#62;&#62; CLEARANCE SUMMARY REPORTS</Card.Header>
                        <Card.Body>
                            <Form>
                                <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                                    <div className={this.state.color}></div>
                                    {this.state.message}
                                </Alert>
                                <div>
                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            BATCH NUMBER
                                        </Form.Label>
                                        <Col>
                                            <Typeahead
                                                ref={this.refBatch}
                                                labelKey='batchPayroll'
                                                id="basic-example"
                                                onChange={this.onChangeBatchNumber}
                                                options={this.state.filteredBatch}
                                                placeholder='Select Batch No.        '
                                                multiple
                                                clearButton
                                            />
                                        </Col>
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                        EMPLOYEE
                                        </Form.Label>
                                        <Col>
                                            <Typeahead
                                                ref={this.employee}
                                                labelKey='employeeName'
                                                id="basic-example"
                                                onChange={this.handleChangeEmployee}
                                                options={this.state.sortEmp}
                                                placeholder='SELECT EMPLOYEE'
                                                //defaultSelected={[this.state.employeeName]}
                                            />
                                        </Col>
                                    </Form.Group>
                                    <div style={{height : "5px"}}></div>
                                </div>
                                <ButtonToolbar className="mt-2 mb-3">
                                    <Button variant="primary" className="ml-auto noser-button-mr1 noser-button" onClick={ () =>  this.handleClickSearch() }>
                                        Search
                                    </Button>
                                </ButtonToolbar>
                                    {/* <Card.Header>PAYROLL</Card.Header> */}
                                    <Tabs className="mt-3" defaultActiveKey="default" transition={false} id="noanim-tab-example">
                                        <Tab eventKey="default" title="CLEARANCE SUMMARY">

                                            <div className="mt-2 mb-4" style={{ overflow: 'auto'}}>

                                                <DataGrid
                                                    ref={(ref) => { this.dataGrid = ref; }}
                                                    // onCellPrepared={this.onCellPrepared}
                                                    customizeColumns={this.customizeColumns}
                                                    dataSource={this.state.srchPositve}
                                                    keyExpr="employeeNo"
                                                    showBorders={true}
                                                    columnMinWidth={80}
                                                    columnAutoWidth={true}
                                                    allowColumnResizing={true}
                                                    showRowLines={true}
                                                    focusedRowEnabled={true}
                                                    onExporting={this.onExporting}
                                                    onOptionChanged={this.handleOptionChange}
                                                    noDataText = "No record found."
                                                    autoHeight={true}
                                                >
                                                    <Selection mode='multiple' selectAllMode='allPages' showCheckBoxesMode="always" />
                                                    <Paging defaultPageSize="10" />
                                                    <Export enabled={true}  />
                                                    <LoadPanel enabled={true} />
                                                    <Editing mode="cell" allowUpdating={false} />
                                                    <Sorting mode="none"/>
                                                </DataGrid>
                                            </div>
                                        </Tab>
                                        <Tab eventKey="negative" title="NEGATIVE CLAIMS">
                                            <div className="mt-2 mb-4" style={{ overflow: 'auto'}}>

                                                <DataGrid
                                                    ref={this.dataGridRef}
                                                    // onCellPrepared={this.onCellPreparedNegative}
                                                    customizeColumns={this.customizeColumnsNegative}
                                                    dataSource={this.state.srchNgtve}
                                                    keyExpr="employeeNo"
                                                    showBorders={true}
                                                    columnMinWidth={80}
                                                    columnAutoWidth={true}
                                                    allowColumnResizing={true}
                                                    showRowLines={true}
                                                    focusedRowEnabled={true}
                                                    noDataText = "No record found."
                                                    onOptionChanged={this.handleOptionChange}
                                                    onExporting={this.onExportingNegative}
                                                    autoHeight={true}
                                                    /* // noDataText = "No record found." */ >
                                                    <Paging defaultPageSize="10" />
                                                    <Export enabled={true} /* allowExportSelectedData={true} */ />
                                                    <Template name={"cellContent"} component={NButton} />
                                                    <Selection mode='multiple' selectAllMode='allPages' showCheckBoxesMode="always" />
                                                    <LoadPanel enabled={true} />

                                                    {/* <Scrolling columnRenderingMode="virtual" /> */}
                                                    <Editing mode="cell" allowUpdating={false} />
                                                    <Sorting mode="none"/>
                                                    {/* <Summary calculateCustomSummary={this.calculateSelectedRow}>
                                                        <TotalItem
                                                            column="netPay"
                                                            summaryType="sum"
                                                            showInColumn=""
                                                            displayFormat="{0}"
                                                            alignment="right"
                                                        />
                                                    </Summary> */}
                                                </DataGrid>
                                            </div>
                                        </Tab>
                                    </Tabs>
                                <hr style={{marginTop : "2%"}} />
                                <ButtonToolbar sm={12}>
                                    <Button /* disabled={this.state.dsabldBttn} */ onClick={ this.handleClickUnfinalized } className="ml-auto noser-button-mr1 noser-button" variant="info">
                                        unfinalize
                                    </Button>
                                </ButtonToolbar>
                            </Form>
                        </Card.Body>
                    </Card>
                </Container>
                <NoserLoading show={this.state.isloading} />
            </div>
        )
    };
}

export default ClearancePayrollSummary